import { get } from "lodash";
import { Observable } from "rxjs/Observable";
import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { PageService } from "../page/page.service";
import { environment } from "../../../environments/environment";
import { SessionManagementService } from '../../services/session-management.service';

@Injectable()
export class NdaChatService {

  private  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // 'responseType': 'text',
      // 'contentLanguage': 'en-US'
    })
  };

  constructor(
    private http: HttpClient,
    @Inject(PageService) private pageService,
    private sessionManager: SessionManagementService
  ) { }

  get username(): string {
    return get(this.sessionManager, "profile.login");
  }

  get pdn(): string {
    return get(this.sessionManager, "profile.dealership.pdn");
  }

  newPage(url: string): void {
    url = url.replace(/^\/+/g, "");
    if (this.pageService.newPage) {
      // DRP-6323 - This URL uses the Dash UI URL, does not need to change for Apigee Migration.
      this.pageService.newPage(`${environment.ndaServerURL}/${url}`, {
        section: [],
        sdes: []
      });
    }
  }

  authenticate(): Observable<{} | Response> {
    const url = `${environment.ndaServerURLS.userServices}/user/${this.username}/livechat/pdn/${this.pdn}`;
    return this.http.get(url, this.httpOptions);
  }

  loadScript(): void {
    let prodContent ='window.lpTag=window.lpTag||{},"undefined"==typeof window.lpTag._tagCount?(window.lpTag={site:\'11594738\'||"",section:lpTag.section||"",tagletSection:lpTag.tagletSection||null,autoStart:lpTag.autoStart!==!1,ovr:lpTag.ovr||{},_v:"1.8.0",_tagCount:1,protocol:"https:",events:{bind:function(t,e,i){lpTag.defer(function(){lpTag.events.bind(t,e,i)},0)},trigger:function(t,e,i){lpTag.defer(function(){lpTag.events.trigger(t,e,i)},1)}},defer:function(t,e){0==e?(this._defB=this._defB||[],this._defB.push(t)):1==e?(this._defT=this._defT||[],this._defT.push(t)):(this._defL=this._defL||[],this._defL.push(t))},load:function(t,e,i){var n=this;setTimeout(function(){n._load(t,e,i)},0)},_load:function(t,e,i){var n=t;t||(n=this.protocol+"//"+(this.ovr&&this.ovr.domain?this.ovr.domain:"lptag.liveperson.net")+"/tag/tag.js?site="+this.site);var a=document.createElement("script");a.setAttribute("charset",e?e:"UTF-8"),i&&a.setAttribute("id",i),a.setAttribute("src",n),document.getElementsByTagName("head").item(0).appendChild(a)},init:function(){this._timing=this._timing||{},this._timing.start=(new Date).getTime();var t=this;window.attachEvent?window.attachEvent("onload",function(){t._domReady("domReady")}):(window.addEventListener("DOMContentLoaded",function(){t._domReady("contReady")},!1),window.addEventListener("load",function(){t._domReady("domReady")},!1)),"undefined"==typeof window._lptStop&&this.load()},start:function(){this.autoStart=!0},_domReady:function(t){this.isDom||(this.isDom=!0,this.events.trigger("LPT","DOM_READY",{t:t})),this._timing[t]=(new Date).getTime()},vars:lpTag.vars||[],dbs:lpTag.dbs||[],ctn:lpTag.ctn||[],sdes:lpTag.sdes||[],hooks:lpTag.hooks||[],ev:lpTag.ev||[]},lpTag.init()):window.lpTag._tagCount+=1;';
    let content ='window.lpTag=window.lpTag||{},\'undefined\'==typeof window.lpTag._tagCount?(window.lpTag={wl:lpTag.wl||null,scp:lpTag.scp||null,site:\'74798210\'||\'\',section:lpTag.section||\'\',tagletSection:lpTag.tagletSection||null,autoStart:lpTag.autoStart!==!1,ovr:lpTag.ovr||{},_v:\'1.10.0\',_tagCount:1,protocol:\'https:\',events:{bind:function(t,e,i){lpTag.defer(function()\n' +
      '{lpTag.events.bind(t,e,i)}\n' +
      ',0)},trigger:function(t,e,i){lpTag.defer(function()\n' +
      '{lpTag.events.trigger(t,e,i)}\n' +
      ',1)}},defer:function(t,e){0===e?(this._defB=this._defB||[],this._defB.push(t)):1===e?(this._defT=this._defT||[],this._defT.push(t)):(this._defL=this._defL||[],this._defL.push(t))},load:function(t,e,i){var n=this;setTimeout(function()\n' +
      '{n._load(t,e,i)}\n' +
      ',0)},_load:function(t,e,i)\n' +
      '{var n=t;t||(n=this.protocol+\'//\'+(this.ovr&&this.ovr.domain?this.ovr.domain:\'lptag.liveperson.net\')+\'/tag/tag.js?site=\'+this.site);var o=document.createElement(\'script\');o.setAttribute(\'charset\',e?e:\'UTF-8\'),i&&o.setAttribute(\'id\',i),o.setAttribute(\'src\',n),document.getElementsByTagName(\'head\').item(0).appendChild(o)}\n' +
      ',init:function(){this._timing=this._timing||{},this._timing.start=(new Date).getTime();var t=this;window.attachEvent?window.attachEvent(\'onload\',function(){t._domReady(\'domReady\')}):(window.addEventListener(\'DOMContentLoaded\',function(){t._domReady(\'contReady\')},!1),window.addEventListener(\'load\',function(){t._domReady(\'domReady\')},!1)),\'undefined\'===typeof window._lptStop&&this.load()},start:function(){this.autoStart=!0},_domReady:function(t){this.isDom||(this.isDom=!0,this.events.trigger(\'LPT\',\'DOM_READY\',\n' +
      '{t:t}\n' +
      ')),this._timing[t]=(new Date).getTime()},vars:lpTag.vars||[],dbs:lpTag.dbs||[],ctn:lpTag.ctn||[],sdes:lpTag.sdes||[],hooks:lpTag.hooks||[],identities:lpTag.identities||[],ev:lpTag.ev||[]},lpTag.init()):window.lpTag._tagCount+=1;';
    let script = document.createElement("script");
    script.innerHTML = environment.production ? prodContent : content;
    document.getElementsByTagName("head")[0].appendChild(script);
  }
}
