import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import {Observable} from 'rxjs/Observable';
import { HttpErrorHandlerService, HandleError } from '../../../services/http-error-handler.service';

import { environment } from '../../../../environments/environment';
import { DynamicContact } from '../../models/dynamic-contact';

@Injectable()
export class ContactsService {

  contacts: any;
  pdn: string | number;

  constructor(
    private http: HttpClient,
    public httpErrorHandler: HttpErrorHandlerService
  ) { }

  public getContacts(pdn: string | number): Observable<object> {
    if (pdn === this.pdn) {
      return Observable.of(this.contacts);
    }

    return this.http.get(`${environment.ndaServerURLS.userServices}/user/myContact/pdn/${pdn}`)
      .map(contacts => {
        this.contacts = contacts;
        this.pdn = pdn;
        return contacts;
      });
  }
}
