/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./errors.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./errors.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../services/store.service";
var styles_ErrorsComponent = [i0.styles];
var RenderType_ErrorsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorsComponent, data: {} });
export { RenderType_ErrorsComponent as RenderType_ErrorsComponent };
export function View_ErrorsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "article", [["class", "container text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "h4 text-ally"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "allycon-error-fill text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "p", [["class", "lead"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.error.body; _ck(_v, 4, 0, currVal_1); }); }
export function View_ErrorsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-errors", [], null, null, null, View_ErrorsComponent_0, RenderType_ErrorsComponent)), i1.ɵdid(1, 49152, null, 0, i2.ErrorsComponent, [i3.ActivatedRoute, i4.StoreService, i1.ApplicationRef], null, null)], null, null); }
var ErrorsComponentNgFactory = i1.ɵccf("app-errors", i2.ErrorsComponent, View_ErrorsComponent_Host_0, {}, {}, []);
export { ErrorsComponentNgFactory as ErrorsComponentNgFactory };
