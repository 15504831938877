/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-error-forgot-password.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal-error-forgot-password.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../services/app-constants.service";
import * as i5 from "ngx-bootstrap/modal";
var styles_ModalErrorForgotPasswordComponent = [i0.styles];
var RenderType_ModalErrorForgotPasswordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalErrorForgotPasswordComponent, data: {} });
export { RenderType_ModalErrorForgotPasswordComponent as RenderType_ModalErrorForgotPasswordComponent };
export function View_ModalErrorForgotPasswordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "modal-content"], ["data-track-region", "Error Forgot Password Modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "close-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "btn close"], ["data-track-name", "Close"], ["data-track-trigger", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-lg-12 d-flex title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "col-lg-12 col-sm-12 col-xs-12 message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "col-lg-12 col-sm-12 col-xs-12 button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["aria-label", "try again"], ["class", "btn btn-primary font-weight-bold try-again"], ["data-track-elem", "button"], ["data-track-name", "Go Back"], ["data-track-trigger", "Forgot Password"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "col-lg-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, [["errorForgotPwdModalContainer", 1]], null, 0, "template", [["class", "template"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.CONSTANTS.title; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.CONSTANTS.message; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.CONSTANTS.button; _ck(_v, 13, 0, currVal_2); }); }
export function View_ModalErrorForgotPasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "modal-error-forgot-password", [], null, null, null, View_ModalErrorForgotPasswordComponent_0, RenderType_ModalErrorForgotPasswordComponent)), i1.ɵdid(1, 114688, null, 0, i2.ModalErrorForgotPasswordComponent, [i3.Router, i4.AppConstantsService, i5.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalErrorForgotPasswordComponentNgFactory = i1.ɵccf("modal-error-forgot-password", i2.ModalErrorForgotPasswordComponent, View_ModalErrorForgotPasswordComponent_Host_0, {}, {}, []);
export { ModalErrorForgotPasswordComponentNgFactory as ModalErrorForgotPasswordComponentNgFactory };
