import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ErrorsRoutingModule } from './errors-routing.module';
import { ErrorsHandler } from './handlers/errors-handler';
import { ErrorsComponent } from './components/errors/errors.component';
import {DealerAppsComponent} from "./components/dealer-apps/dealer-apps.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ErrorsRoutingModule
  ],
  declarations: [
    ErrorsComponent,
    DealerAppsComponent
  ],
  providers: [
    {
      provide:  ErrorHandler,
      useClass: ErrorsHandler
    }
  ]
})
export class ErrorsModule { }
