import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorsComponent } from './components/errors/errors.component';
import { StandardPageLayoutComponent } from '../components/standard-page-layout/standard-page-layout.component';
import {DealerAppsComponent} from "./components/dealer-apps/dealer-apps.component";

const routes: Routes = [
  {path: 'error/dealer-apps/:appId', component: DealerAppsComponent},
  {
    path: 'error',
    component: StandardPageLayoutComponent,
    data: { isNavEnabled: false },
    children: [
      { path: '', component: ErrorsComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErrorsRoutingModule { }
