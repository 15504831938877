import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter } from "@angular/core";
import { EventTargetInterruptSource, Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { AppConstantsService } from "../app-constants.service";
import CustomDocumentInterruptSource from "./CustomDocumentInterruptSource";
var IdleServiceKey;
(function (IdleServiceKey) {
    IdleServiceKey.KeepAliveTime = 'keepAliveTime';
    IdleServiceKey.IdleTime = 'idleTime';
    IdleServiceKey.IdleTimeOut = 'idleTimeOut';
})(IdleServiceKey || (IdleServiceKey = {}));
var AppIdleService = /** @class */ (function () {
    function AppIdleService(idle, keepalive, appConstants) {
        var _this = this;
        this.idle = idle;
        this.keepalive = keepalive;
        this.appConstants = appConstants;
        this.onInterrupt = this.idle.onInterrupt;
        this.onIdleEnd = this.idle.onIdleEnd;
        this.onTimeout = this.idle.onTimeout;
        this.onIdleStart = this.idle.onIdleStart;
        this.onTimeoutWarning = this.idle.onTimeoutWarning;
        this.onPing = this.keepalive.onPing;
        this.configureServices();
        this.idle.onIdleEnd.subscribe(function () { return _this.watchStart(); });
        this.setInterruptSource();
    }
    Object.defineProperty(AppIdleService.prototype, "documentInterrupts", {
        get: function () {
            return this.idle.getInterrupts().filter(function (interrupt) {
                return interrupt.source instanceof EventTargetInterruptSource;
            });
        },
        enumerable: true,
        configurable: true
    });
    AppIdleService.prototype.watchToggle = function (shouldStart) {
        shouldStart ? this.watchStart() : this.watchEnd();
    };
    AppIdleService.prototype.watchStart = function () {
        this.interruptsResume();
        this.idle.watch();
    };
    AppIdleService.prototype.watchEnd = function () {
        this.idle.stop();
    };
    AppIdleService.prototype.interruptsPause = function () {
        this.documentInterrupts.forEach(function (interrupt) { return interrupt.pause(); });
    };
    AppIdleService.prototype.interruptsResume = function () {
        this.documentInterrupts.forEach(function (interrupt) { return interrupt.resume(); });
    };
    AppIdleService.prototype.setInterruptSource = function (elementForInterrupt) {
        var interrupts = tslib_1.__spread(DEFAULT_INTERRUPTSOURCES);
        if (elementForInterrupt) {
            var interrupt = new CustomDocumentInterruptSource(elementForInterrupt);
            interrupts.push(interrupt);
        }
        this.watchEnd();
        this.idle.setInterrupts(interrupts);
        this.watchStart();
    };
    AppIdleService.prototype.configureServices = function () {
        this.keepalive.interval(this.getConfigValueByKey(IdleServiceKey.KeepAliveTime));
        this.idle.setIdle(this.getConfigValueByKey(IdleServiceKey.IdleTime));
        this.idle.setTimeout(this.getConfigValueByKey(IdleServiceKey.IdleTimeOut));
        this.idle.setKeepaliveEnabled(true);
    };
    AppIdleService.prototype.getConfigValueByKey = function (key) {
        var defaultVal = this.appConstants[key];
        var storageVal = +localStorage.getItem(key);
        if (storageVal && storageVal <= defaultVal) {
            return storageVal;
        }
        return defaultVal;
    };
    return AppIdleService;
}());
export { AppIdleService };
