import { Component, Injector, ApplicationRef } from '@angular/core';
import { get } from 'lodash';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { ActivatedRoute } from '@angular/router';
import { ErrorType } from '../../handlers/errors-handler';
import { StoreService } from '../../../services/index.js';

interface ErrorMessage {
  title: string;
  body: string;
}

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent {

  readonly CONSTANTS = {
    ...AppStrings['common'],
    errors: AppStrings['errorsPage']
  };

  get error(): ErrorMessage {
    let type: ErrorType = this.activatedRoute.snapshot.queryParams.type;
    return get(this.CONSTANTS.errors, type, this.CONSTANTS.errors[ErrorType.Default]);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private storeService: StoreService,
    appRef: ApplicationRef,
  ) {
    this.reportError();
    setTimeout(() => appRef.tick());
  }

  private reportError(): void {
    const error = this.storeService.read('HttpErrorResponse');
    if (error) {
      console.error(`${error['message']} \n`, error);
      this.storeService.delete('HttpErrorResponse');
    }
  }
}
