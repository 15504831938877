import * as tslib_1 from "tslib";
import { cloneDeep, sortBy, includes } from 'lodash';
import 'rxjs/add/operator/finally';
;
import { AppConstantsService } from '../../../services/app-constants.service';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { AppDrawerOutputState, UserDetailsService } from '../../services/user-details/user-details.service';
import { get, find } from 'lodash';
import { SmartAuctionDialog } from '../../../manage-users/components/smartauction-dialog/smartauction-dialog.component';
import { SessionManagementService } from '../../../services/session-management.service';
var NdaModalContentAddRemoveApplicationsComponent = /** @class */ (function () {
    function NdaModalContentAddRemoveApplicationsComponent(appConstantsService, drawerComponentCommunicatorService, userDetailsService, sessionManager) {
        this.appConstantsService = appConstantsService;
        this.drawerComponentCommunicatorService = drawerComponentCommunicatorService;
        this.userDetailsService = userDetailsService;
        this.sessionManager = sessionManager;
        this.checkTheBox = false;
        this.drawerState = false;
        this.shouldShowSmartAuctionRoles = false;
        this.shouldShowSmartCashRoles = false;
        this.shouldShowAllyRideReturnRoles = false;
        this.shouldShowAuctionAccessInfo = false;
        this.addManageUsersHidden = false;
        this.removeManageUsersHidden = false;
        this.isOnlyManageUsers = false;
        this.innerWidth = 0;
        this.smartAuctionAppIndex = 0;
        //Set first two initially to "null" to indicate they are not used.
        this.secondaryRoleValue = null;
        this.auctionAccessIDValue = null;
        this.CONSTANTS = tslib_1.__assign({}, AppStrings['applicationAndReports'], AppStrings['addRemoveApps']);
        this.OPTIONS = AppStrings['registration']['dealership']['smartAuctionSecondaryRoles'];
        this.hasPendingRequest = false;
    }
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "isFormChanged", {
        get: function () {
            this.drawerComponentCommunicatorService.isFormChanged = this.isApplicationSelected;
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "isApplicationSelected", {
        get: function () {
            return !!this.selectedApplications.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "isSingleApplicationSelected", {
        get: function () {
            return this.selectedApplications.length == 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "selectedApplications", {
        get: function () {
            return this.applicationToRequest.filter(function (item) { return item['isChecked']; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "isAllAppsSelected", {
        get: function () {
            return this.applicationToRequest.length === this.applicationToRequest.filter(function (item) { return item['isChecked']; }).length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "isAllyRole", {
        get: function () {
            return !!this.userDetailsService.allyRole;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "smartAuctionRoles", {
        get: function () {
            if (this.isAllyRole) {
                return this.appConstantsService.smartAuctionAllyUserRoles;
            }
            ;
            return this.appConstantsService.smartAuctionDealershipRoles;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "smartCashRoles", {
        get: function () {
            if (this.isAllyRole) {
                return this.appConstantsService.smartCashAllyUserRoles;
            }
            ;
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "smartCashAllyUserRegion", {
        get: function () {
            if (this.isAllyRole) {
                return this.appConstantsService.smartCashAllyUserRegion;
            }
            ;
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "allyRideReturnRoles", {
        get: function () {
            if (this.isAllyRole) {
                return null;
            }
            ;
            return this.appConstantsService.allyRideReturnDealershipRoles;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "isNoAppsAvailableToRequest", {
        get: function () {
            return (this.isAddAppsDrawer && this.applicationToRequest.length === 0)
                || (this.isAddAppsDrawer && this.isOnlyManageUsers && this.addManageUsersHidden);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "isNoAppsAvailableToRemove", {
        get: function () {
            return (this.isRemoveAppsDrawer && this.applicationToRequest.length === 0)
                || (this.isRemoveAppsDrawer && this.isOnlyManageUsers && this.removeManageUsersHidden);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "isAddAppsDrawer", {
        get: function () {
            return this.drawerComponentCommunicatorService.drawerComponentIdentifier === this.drawerComponentCommunicatorService.addAppsIdentifier;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "isRemoveAppsDrawer", {
        get: function () {
            return this.drawerComponentCommunicatorService.drawerComponentIdentifier === this.drawerComponentCommunicatorService.removeAppsIdentifier;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "isSubmitVisible", {
        get: function () {
            return !this.isNoAppsAvailableToRequest && !this.isNoAppsAvailableToRemove;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "isSecondaryAddApps", {
        get: function () {
            return this.isAddSecondaryApps;
        },
        enumerable: true,
        configurable: true
    });
    NdaModalContentAddRemoveApplicationsComponent.prototype.auctionAccessIdErrorMessage = function (submitted, invalid, minLength) {
        if (!!submitted && !!invalid && !minLength) {
            return this.CONSTANTS.invalidAuctionAccessIDError;
        }
        if (!!submitted && !!minLength) {
            return this.CONSTANTS.minlengthAuctionAccessIDError;
        }
        else {
            return "";
        }
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.ngOnInit = function () {
        this.ngForm.resetForm();
        this.innerWidth = window.innerWidth;
        this.shouldShowSmartAuctionRoles = false;
        this.shouldShowSmartCashRoles = false;
        this.shouldShowAllyRideReturnRoles = false;
        this.applicationRegions = get(this, 'data.applicationRegions', []);
        this.applicationToRequest = sortBy(cloneDeep(this.data.applicationList), 'name');
        this.pdn = this.data.pdn;
        this.isAddSecondaryApps = this.data.isAddSecondaryApps;
        this.profile = this.data.profile;
        this.isRequestorAnAdmin = this.data.isRequestorAnAdmin;
        // Add 'secondaryRole' and 'auctionAccessID' fields to the form locally if SmartAuction is an available app.
        for (var i = 0; i < this.applicationToRequest.length; i++) {
            if (this.applicationToRequest[i].name === 'SmartAuction') {
                this.smartAuctionAppIndex = i;
                this.applicationToRequest[i]['secondaryRole'] = null;
                this.applicationToRequest[i]['auctionAccessId'] = null;
            }
            ;
            // If "Manage Users" application is found in the applicationToRequest array
            if (this.applicationToRequest[i].name === this.appConstantsService.allyAdminApps[0]['name']) {
                if (!this.sessionManager.showAddManageUserApp) {
                    this.addManageUsersHidden = true;
                }
                if (!this.sessionManager.showRemoveManageUserApp) {
                    this.removeManageUsersHidden = true;
                }
                if (this.applicationToRequest.length === 1) {
                    this.isOnlyManageUsers = true;
                }
            }
            ;
        }
        ;
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.ngOnDestroy = function () {
        this.ngForm.resetForm();
    };
    // Boolean function to display or hide Manage Users from 'Add' and 'Remove' in 'APPLICATIONS & REPORTS'
    NdaModalContentAddRemoveApplicationsComponent.prototype.isSelectable = function (appObj) {
        if ((this.addManageUsersHidden && this.removeManageUsersHidden)
            || (this.addManageUsersHidden && !this.removeManageUsersHidden)
            || (!this.addManageUsersHidden && this.removeManageUsersHidden)) {
            return appObj.name !== this.appConstantsService.allyAdminApps[0]['name'];
        }
        else {
            return appObj;
        }
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.showConfirmationBanner = function () {
        this.userDetailsService.callOnloadService = true;
        if (this.isSecondaryAddApps) {
            this.userDetailsService.updateState(null);
        }
        else {
            this.userDetailsService.updateState(this.isSingleApplicationSelected ? AppDrawerOutputState.processSingleAddAppSuccess : AppDrawerOutputState.processMultipleAddAppSuccess);
        }
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.handlerSubmitForm = function () {
        var _this = this;
        var request;
        // If admin application has SmartAuction, add AuctionAccessID value to it.
        for (var i = 0; i < this.selectedApplications.length; i++) {
            if (this.selectedApplications[i].name === 'SmartAuction') {
                this.selectedApplications[i].auctionAccessId = this.auctionAccessIDValue;
            }
        }
        this.userDetailsService.selectedApps = this.selectedApplications;
        this.userDetailsService.isAllAppsSelected = this.isAllAppsSelected;
        if (this.isRemoveAppsDrawer) {
            this.userDetailsService.displayRemoveAppModal = true;
        }
        else if (this.isRequestorAnAdmin) {
            request = this.userDetailsService.addApplication(this.isSecondaryAddApps);
        }
        else {
            request = this.userDetailsService.addApplicationViaWorkflow(this.isSecondaryAddApps, this.profile);
        }
        if (request && !this.hasPendingRequest) {
            this.hasPendingRequest = true;
            request
                .finally(function () { return _this.hasPendingRequest = false; })
                .subscribe(function (response) { return _this.processServiceResponse(response); });
        }
    };
    /**
     * processServiceResponse - interpret service response to shows banners accordingly
     * @param response
     */
    NdaModalContentAddRemoveApplicationsComponent.prototype.processServiceResponse = function (response) {
        if (response && response['error'] && response['error']['exceptions']) {
            this.showErrorBanner();
        }
        else {
            this.showConfirmationBanner();
        }
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.showErrorBanner = function () {
        this.userDetailsService.updateState(AppDrawerOutputState.processError);
    };
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "isDealerRole", {
        get: function () {
            return this.userDetailsService.isDealerRole;
        },
        enumerable: true,
        configurable: true
    });
    NdaModalContentAddRemoveApplicationsComponent.prototype.toggleRoleSelect = function (application) {
        application.role = application.region = "";
        if (this.isSmartAuction(application.name)) {
            this.shouldShowSmartAuctionRoles = !this.shouldShowSmartAuctionRoles;
        }
        else if (this.isSmartCash(application.name) && this.isAllyRole) {
            this.shouldShowSmartCashRoles = !this.shouldShowSmartCashRoles;
        }
        if (this.isAllyRideReturn(application.name) && this.isDealerRole) {
            application.role = '';
            this.shouldShowAllyRideReturnRoles = !this.shouldShowAllyRideReturnRoles;
        }
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.isSmartAuction = function (applicationName) {
        return applicationName === this.appConstantsService.dealerAppNames['SA'];
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.isSmartCash = function (applicationName) {
        return applicationName === this.appConstantsService.dealerAppNames['SC'];
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.isAllyRideReturn = function (applicationName) {
        return applicationName === this.appConstantsService.dealerAppNames['SG'];
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.showRolesSection = function (app) {
        return app.isChecked && get(app, 'roles.length', 0) > 0;
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.showRegionSection = function (app) {
        var role = find(app.roles, function (role) { return role.roleName === app.role; });
        return app.isChecked && get(role, 'hasRegion') === 'Y';
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.showRolesSectionForSmartCash = function (applicationName) {
        if (this.isRemoveAppsDrawer) {
            return false;
        }
        return this.isSmartCash(applicationName) && this.isAllyRole && this.shouldShowSmartCashRoles;
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.showRolesSectionForAllyRideReturn = function (applicationName) {
        if (this.isRemoveAppsDrawer) {
            return false;
        }
        return this.isAllyRideReturn(applicationName) && this.isDealerRole && this.shouldShowAllyRideReturnRoles;
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.selectSmartAuctionRole = function (event) {
        return !!event.target.value;
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.selectSmartCashRole = function (event) {
        return !!(this.updateSCRole = event.target.value);
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.selectAllyRideReturnRole = function (event) {
        return !!event.target.value;
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.showRegionForSmartCash = function (role) {
        var roleWithoutRegion = ["EO", "EO Basic", "Support", ""];
        return !includes(roleWithoutRegion, role);
    };
    // Changes secondaryRole based on chosen option in select list.
    NdaModalContentAddRemoveApplicationsComponent.prototype.updateSecondaryRolename = function (event) {
        this.applicationToRequest[this.smartAuctionAppIndex]['secondaryRole'] = event.target.value;
        this.secondaryRoleValue = event.target.value;
    };
    // Changes auctionAccessID numeric value based on current input (only allows numbers).
    NdaModalContentAddRemoveApplicationsComponent.prototype.updateAuctionAccessID = function (event) {
        this.applicationToRequest[this.smartAuctionAppIndex]['auctionAccessId'] = event.target.value;
        this.auctionAccessIDValue = event.target.value;
    };
    // Show secondary roles if given app has roles AND has selected name 'Dealer'.
    NdaModalContentAddRemoveApplicationsComponent.prototype.showSecondaryRolesSection = function (app) {
        return this.showRolesSection(app) && app.role == 'Dealer';
    };
    // Show 100 M number (AuctionACCESS ID) input field if given app has roles AND has selected name 'Buyer' and/or 'Buyer and Seller'.
    NdaModalContentAddRemoveApplicationsComponent.prototype.showAuctionAccessIDField = function (app) {
        return this.showSecondaryRolesSection(app) && this.shouldShowAuctionAccessID();
    };
    // Helper function to determine if AuctionAccess ID field should be shown based on which option is selected.
    NdaModalContentAddRemoveApplicationsComponent.prototype.shouldShowAuctionAccessID = function () {
        if (this.secondaryRoleValue == 'Buyer' || this.secondaryRoleValue == 'Buyer and Seller') {
            return true;
        }
        else {
            // If field is not shown, purge values so they are not submitted.
            this.applicationToRequest[this.smartAuctionAppIndex]['auctionAccessId'] = null;
            this.auctionAccessIDValue = null;
            return false;
        }
    };
    NdaModalContentAddRemoveApplicationsComponent.prototype.determineTooltipMargin = function () {
        var element = document.getElementById("tooltipHolder");
        // If page size shrinks to mobile view, purge massive margins to make the view less silly.
        if (this.innerWidth < 768) {
            element.style.removeProperty("margin-top");
            element.style.removeProperty("margin-left");
        }
        ;
        // If page size grows past mobile view, re-add massive margins to make the view correct again.
        if (this.innerWidth >= 768 && this.innerWidth < 1200) {
            element.style.marginTop = '14.5625rem';
            element.style.marginLeft = '-9.25rem';
        }
        ;
        // If page grows to widescreen, add this margin size.
        if (this.innerWidth >= 1200) {
            element.style.marginTop = '14.5625rem';
            element.style.marginLeft = '-11.1875rem';
        }
    };
    // Dynamically grab window size as window size is changed.
    NdaModalContentAddRemoveApplicationsComponent.prototype.onResize = function (event) {
        this.innerWidth = window.innerWidth;
        if (this.shouldShowAuctionAccessID()) {
            this.determineTooltipMargin();
        }
        ;
    };
    // Show AuctionACCESS ID Info Tooltip
    NdaModalContentAddRemoveApplicationsComponent.prototype.showAuctionAccessInfo = function () {
        this.shouldShowAuctionAccessInfo = true;
    };
    // Receive from child component to hide AuctionACCESS ID Info Tooltip
    NdaModalContentAddRemoveApplicationsComponent.prototype.hideAuctionAccessInfo = function (val) {
        this.shouldShowAuctionAccessInfo = val;
    };
    Object.defineProperty(NdaModalContentAddRemoveApplicationsComponent.prototype, "smartAuctionSecondaryRoles", {
        // Note: SmartAuction secondary roles will only appear for dealers, hard-coded here via role name equalling 'Dealer'.
        get: function () {
            return this.appConstantsService.smartAuctionDealershipSecondaryRoles;
        },
        enumerable: true,
        configurable: true
    });
    // Problem-child Firefox requires this function to restrict input to only numbers and backspace + direction arrows.
    NdaModalContentAddRemoveApplicationsComponent.prototype.filterInput = function (event) {
        var numberEntered = false;
        // Input number entered or one of the 4 directtion up, down, left and right.
        if ((event.which >= 48 && event.which <= 57) || (event.which >= 37 && event.which <= 40)) {
            numberEntered = true;
        }
        else {
            // Input entered that is *not* of direction arrows, delete, or backspace.
            if (!((event.keyCode >= 37 && event.keyCode <= 40) || event.keyCode == 46 || event.which == 8)) {
                event.preventDefault();
            }
        }
    };
    return NdaModalContentAddRemoveApplicationsComponent;
}());
export { NdaModalContentAddRemoveApplicationsComponent };
