import { Routes } from '@angular/router';
import { AppGuard } from './guards/app.guard';
import { RegisterGuard } from './registration/guards/register.guard';
import { ManageUsersGuard } from './guards';
import { UserDetailsGuard } from './change-password-pre-login/guards/user-details.guard';
import { NdaLoginComponent } from './nda-login/nda-login.component';
import { NdaNoPageComponent } from './components/nda-no-page/nda-no-page.component';
import { StandardPageLayoutComponent } from './components/standard-page-layout/standard-page-layout.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ChangePasswordPreLoginComponent } from './change-password-pre-login/components/change-password-pre-login/change-password-pre-login.component';
import { ContactsListComponent } from './contacts/components/contacts-list/contacts-list.component';
var ɵ0 = function () { return import("./dashboard/dashboard.module.ngfactory").then(function (m) { return m.DashboardModuleNgFactory; }); }, ɵ1 = function () { return import("./manage-users/manage-users.module.ngfactory").then(function (m) { return m.ManageUsersModuleNgFactory; }); }, ɵ2 = function () { return import("./profile/profile.module.ngfactory").then(function (m) { return m.ProfileModuleNgFactory; }); }, ɵ3 = function () { return import("./forgot-password/forgot-password.module.ngfactory").then(function (m) { return m.ForgotPasswordModuleNgFactory; }); }, ɵ4 = function () { return import("./registration-ally/registration-ally.module.ngfactory").then(function (m) { return m.RegistrationAllyModuleNgFactory; }); }, ɵ5 = function () { return import("./registration/registration.module.ngfactory").then(function (m) { return m.RegistrationModuleNgFactory; }); }, ɵ6 = { process: 'registration' };
var appRoutes = [
    {
        path: '',
        component: StandardPageLayoutComponent,
        children: [
            { path: '', redirectTo: '/login', pathMatch: 'full' },
            { path: 'pdn-verification', redirectTo: '/register', pathMatch: 'full' },
            { path: 'login', component: NdaLoginComponent, canActivate: [AppGuard] },
            { path: 'dashboard', loadChildren: ɵ0, canActivate: [AppGuard] },
            { path: 'manage/users', loadChildren: ɵ1, canActivate: [AppGuard, ManageUsersGuard] },
            { path: 'manage/profile', loadChildren: ɵ2, canActivate: [AppGuard] },
            { path: 'contacts', component: ContactsListComponent, canActivate: [AppGuard] },
            { path: 'gethelp', loadChildren: ɵ3 },
            { path: 'change-password', component: ChangePasswordPreLoginComponent, canActivate: [UserDetailsGuard] },
            { path: '404', component: NdaNoPageComponent },
            { path: 'logout', component: LogoutComponent },
            { path: 'covid-19', loadChildren: './covid/covid.module#CovidModule', canActivate: [AppGuard] }
        ]
    },
    // custom page layouts
    { path: 'register/ally', loadChildren: ɵ4 },
    { path: 'register', loadChildren: ɵ5, canActivate: [RegisterGuard], data: ɵ6 },
    // catch-all route
    { path: '**', redirectTo: '/404' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
