/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nda-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../nda-nav/nda-nav.component.ngfactory";
import * as i3 from "../nda-nav/nda-nav.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/session-management.service";
import * as i6 from "@angular/common";
import * as i7 from "../../contacts/components/contacts-flyout/contacts-flyout.component.ngfactory";
import * as i8 from "../../contacts/components/contacts-flyout/contacts-flyout.component";
import * as i9 from "../../contacts/services/contacts/contacts.service";
import * as i10 from "./nda-header.component";
import * as i11 from "../../services/okta-auth/okta-auth.service";
var styles_NdaHeaderComponent = [i0.styles];
var RenderType_NdaHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NdaHeaderComponent, data: {} });
export { RenderType_NdaHeaderComponent as RenderType_NdaHeaderComponent };
function View_NdaHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nda-nav", [["class", "ml-auto"]], null, [[null, "showFlyout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showFlyout" === en)) {
        var pd_0 = (_co.displayFlyout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NdaNavComponent_0, i2.RenderType_NdaNavComponent)), i1.ɵdid(1, 114688, null, 0, i3.NdaNavComponent, [i4.Router, i5.SessionManagementService], { isFlyoutShowing: [0, "isFlyoutShowing"] }, { showFlyout: "showFlyout" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isFlyoutShowing; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NdaHeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { flyout: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "header", [["class", "nda-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "col-12 d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dashboardRefresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "Ally Logo. Return to Ally Dash homepage."], ["class", "logo"], ["src", "assets/images/Ally_Logo_White_png.png"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NdaHeaderComponent_1)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-contacts-flyout", [], [[2, "active", null]], [[null, "showFlyout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showFlyout" === en)) {
        var pd_0 = (_co.displayFlyout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ContactsFlyoutComponent_0, i7.RenderType_ContactsFlyoutComponent)), i1.ɵdid(11, 114688, [[1, 4]], 0, i8.ContactsFlyoutComponent, [i9.ContactsService, i5.SessionManagementService], null, { showFlyout: "showFlyout" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldDisplayNav; _ck(_v, 8, 0, currVal_0); _ck(_v, 11, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isFlyoutShowing; _ck(_v, 10, 0, currVal_1); }); }
export function View_NdaHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nda-header", [], null, null, null, View_NdaHeaderComponent_0, RenderType_NdaHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i10.NdaHeaderComponent, [i5.SessionManagementService, i11.OktaAuthService, i4.Router], null, null)], null, null); }
var NdaHeaderComponentNgFactory = i1.ɵccf("nda-header", i10.NdaHeaderComponent, View_NdaHeaderComponent_Host_0, { isNavEnabled: "isNavEnabled" }, {}, []);
export { NdaHeaderComponentNgFactory as NdaHeaderComponentNgFactory };
