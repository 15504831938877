import * as tslib_1 from "tslib";
import { environment } from '../../../environments/environment';
import { AppStrings } from '../../../assets/app-strings/app-strings';
import { template } from 'lodash';
var NdaFooterComponent = /** @class */ (function () {
    function NdaFooterComponent() {
        this.CONSTANTS = tslib_1.__assign({}, AppStrings['common'], AppStrings['footer']);
        this.urls = environment.footerUrls;
    }
    NdaFooterComponent.prototype.getCopyRightMessage = function () {
        return template(this.CONSTANTS['copyright'])({ currentYear: (new Date()).getFullYear() });
    };
    ;
    return NdaFooterComponent;
}());
export { NdaFooterComponent };
