import { AnalyticsService } from '../../services';
import { AppStrings } from '../../../assets/app-strings/app-strings';
var NdaNoPageComponent = /** @class */ (function () {
    function NdaNoPageComponent(analytics) {
        this.analytics = analytics;
        this.CONSTANTS = {
            errors: AppStrings['errorsPage']['pageNotAvailable']
        };
    }
    NdaNoPageComponent.prototype.ngOnInit = function () {
        this.analytics.trackPage({ title: 'ADOS:Page Not Found' });
    };
    Object.defineProperty(NdaNoPageComponent.prototype, "error", {
        get: function () {
            return this.CONSTANTS.errors;
        },
        enumerable: true,
        configurable: true
    });
    return NdaNoPageComponent;
}());
export { NdaNoPageComponent };
