import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs/Observable';
import { PreLoginUserDetailsService } from '../services/pre-login-user-details.service';

@Injectable()
export class UserDetailsGuard implements CanActivate {
  constructor (
    private router: Router,
    private preLoginUserDetailsService: PreLoginUserDetailsService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (this.preLoginUserDetailsService.hasProfile) {
        return true;
      }

      return false;
  }
}
