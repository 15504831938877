<footer class="nda-footer">
  <div class="container accessibility-link-group">
    <div class="nda-footer-wrapper d-md-flex align-items-md-center">
      <div class="copyText mr-3">&copy; {{ getCopyRightMessage() }}</div>
      <ul class="nav nav-pills nda-footer-nav flex-column flex-md-row">
        <li role="presentation"><a class="link-about"    href="{{ urls.about }}"    target="_blank">{{ CONSTANTS.labels.about }}</a></li>
        <li role="presentation"><a class="link-privacy"  href="{{ urls.privacy }}"  target="_blank">{{ CONSTANTS.labels.privacy }}</a></li>
        <li role="presentation"><a class="link-security" href="{{ urls.security }}" target="_blank">{{ CONSTANTS.labels.security }}</a></li>
        <li role="presentation"><a class="link-legal"    href="{{ urls.legal }}"    target="_blank">{{ CONSTANTS.labels.legal }}</a></li>
        <!-- <li role="presentation"><a class="link-feedback" href="{{ urls.feedback }}" target="_blank">{{ CONSTANTS.labels.feedback }}</a></li> -->
      </ul>
    </div>
  </div>
</footer>
