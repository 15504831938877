import * as tslib_1 from "tslib";
import { cloneDeep, filter, template, get } from 'lodash';
import { EditProfileService } from '../../../profile/services/edit-profile.service';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { DrawerOutputState, DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { AppConstantsService } from '../../../services/app-constants.service';
import { NdaModalLeaveWithoutSaveComponent } from '../nda-modal-leave-without-save/nda-modal-leave-without-save.component';
import { UserDetailsService } from '../../services/user-details/user-details.service';
import { SessionManagementService } from '../../../services/session-management.service';
export var UserStatus;
(function (UserStatus) {
    UserStatus.ACTIVE = 'ACTIVE';
    UserStatus.SUSPENDED = 'SUSPENDED';
    UserStatus.LOCKED_OUT = 'LOCKED_OUT';
    UserStatus.DEPROVISIONED = 'DEPROVISIONED';
})(UserStatus || (UserStatus = {}));
export var UserStatusAction;
(function (UserStatusAction) {
    UserStatusAction.UNLOCKED = 'UNLOCKED';
    UserStatusAction.UNSUSPENDED = 'UNSUSPENDED';
})(UserStatusAction || (UserStatusAction = {}));
var NdaModalContentUpdatePersonalInformationComponent = /** @class */ (function () {
    function NdaModalContentUpdatePersonalInformationComponent(editProfileService, appConstantsService, sessionManagementService, userDetailsService, drawerComponentCommunicatorService) {
        this.editProfileService = editProfileService;
        this.appConstantsService = appConstantsService;
        this.sessionManagementService = sessionManagementService;
        this.userDetailsService = userDetailsService;
        this.drawerComponentCommunicatorService = drawerComponentCommunicatorService;
        this.CONSTANTS = tslib_1.__assign({}, AppStrings['personalInformation'], AppStrings['common']);
        this.ACTIVE = UserStatus.ACTIVE;
        this.SUSPENDED = UserStatus.SUSPENDED;
        this.LOCKED_OUT = UserStatus.LOCKED_OUT;
        this.DEPROVISIONED = UserStatus.DEPROVISIONED;
        this.hasPendingRequest = false;
        this.drawerState = false;
        this.editPersonalSubmit = false;
        this.cellPhoneErrFlag = false;
        this.showReEnterEmail = false;
        this.fullNamePattern = "^[a-zA-Z][a-zA-Z ]*$";
        this.profile = {
            userId: null,
            userType: null,
            pdnWithApplications: null,
            firstName: null,
            lastName: null,
            cellPhone: null,
            emailId: null,
            primaryDealer: null,
            reEnterEmailId: '',
            userName: '',
            dealershipName: '',
            userStatus: '',
            dealerRole: null,
            allyRole: null,
            shouldActivateUser: false
        };
        this.flowMap = this.appConstantsService.edit_profile_flow;
    }
    NdaModalContentUpdatePersonalInformationComponent.prototype.ngOnInit = function () {
        this.drawerComponentCommunicatorService.drawerComponentOutputState = null;
        this.currentProfile = this.data.profile;
        this.userId = this.data.userId;
        this.profile = cloneDeep(this.currentProfile);
        this.profile.shouldActivateUser = false;
        if (this.isPhoneNumberWithPlus) {
            this.profile.cellPhone = this.profile.cellPhone.slice(2);
        }
        this.currentEmailId = this.currentProfile.emailId;
        return this.drawerState = true;
    };
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "isPhoneNumberWithPlus", {
        get: function () {
            return this.profile.cellPhone !== null && this.profile.cellPhone !== undefined && this.profile.cellPhone.startsWith('+');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "isUserApprovalPending", {
        get: function () {
            return !this.isAllyRole && !this.profile.primaryDealer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "canBeActivatedByAllyAdmin", {
        get: function () {
            return this.isSuspendedUser || this.isLockedOutUser || this.isDeactivatedUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "canBeActivatedByDealerAdmin", {
        get: function () {
            return this.isSuspendedUser || this.isLockedOutUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "isDealerAdminForPrimarPDN", {
        get: function () {
            return this.sessionManagementService.isDealershipUser && this.data.isRequestorAnAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "isActiveUser", {
        get: function () {
            return this.profile.userStatus === this.ACTIVE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "isSuspendedUser", {
        get: function () {
            return this.profile.userStatus === this.SUSPENDED;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "isLockedOutUser", {
        get: function () {
            return this.profile.userStatus === this.LOCKED_OUT;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "isDeactivatedUser", {
        get: function () {
            return this.profile.userStatus === this.DEPROVISIONED;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "isAllyRole", {
        get: function () {
            return !!this.userDetailsService.allyRole || this.profile.userType === 'B';
        },
        enumerable: true,
        configurable: true
    });
    NdaModalContentUpdatePersonalInformationComponent.prototype.updateFirstNameErrorMessage = function (required, pattern, minLength) {
        if (!!this.editPersonalSubmit && !!required) {
            return this.CONSTANTS.firstNameError;
        }
        if (!!this.editPersonalSubmit && !!pattern) {
            return this.CONSTANTS.firstNameSpecialCharErr;
        }
        if (!!this.editPersonalSubmit && !!minLength && !pattern) {
            return this.CONSTANTS.firstNameMin2LengthErr;
        }
        else {
            return "";
        }
    };
    NdaModalContentUpdatePersonalInformationComponent.prototype.updateLastNameErrorMessage = function (required, pattern, minLength) {
        if (!!this.editPersonalSubmit && !!required) {
            return this.CONSTANTS.lastNameError;
        }
        if (!!this.editPersonalSubmit && !!pattern) {
            return this.CONSTANTS.lastNameSpecialCharErr;
        }
        if (!!this.editPersonalSubmit && !!minLength && !pattern) {
            return this.CONSTANTS.lastNameMin2LengthErr;
        }
        else {
            return "";
        }
    };
    NdaModalContentUpdatePersonalInformationComponent.prototype.updateCellPhoneErrorMessage = function (pattern) {
        if (!!this.editPersonalSubmit && pattern) {
            return this.CONSTANTS.cellPhoneError;
        }
        else {
            return "";
        }
    };
    NdaModalContentUpdatePersonalInformationComponent.prototype.updateEmailErrorMessage = function (required, email) {
        if (!!this.editPersonalSubmit && !!required) {
            return this.CONSTANTS.emailEmptyError;
        }
        if (!!this.editPersonalSubmit && !required && !!email) {
            return this.CONSTANTS.emailInvalidError;
        }
        else {
            return "";
        }
    };
    NdaModalContentUpdatePersonalInformationComponent.prototype.reEnterEmailErrorMessage = function (required, emailValue, reEnterValue) {
        if (!!this.editPersonalSubmit && (!required && emailValue != reEnterValue)) {
            return this.CONSTANTS.reEnterEmailError;
        }
        if (!!this.editPersonalSubmit && !!required) {
            return this.CONSTANTS.reEnterEmailEmptyError;
        }
        else {
            return "";
        }
    };
    NdaModalContentUpdatePersonalInformationComponent.prototype.showConfirmationModal = function () {
        this.userDetailsService.callOnloadService = true;
        this.drawerComponentCommunicatorService.updateState(DrawerOutputState.processSuccess);
    };
    NdaModalContentUpdatePersonalInformationComponent.prototype.showWarningModal = function () {
        this.drawerComponentCommunicatorService.updateState(DrawerOutputState.processWarning);
    };
    NdaModalContentUpdatePersonalInformationComponent.prototype.showUnexpectedErrorModal = function () {
        this.drawerComponentCommunicatorService.updateState(DrawerOutputState.processError);
    };
    NdaModalContentUpdatePersonalInformationComponent.prototype.handleUpdatePersonalInfo = function () {
        var _this = this;
        this.editPersonalSubmit = true;
        if (this.isStatusChanged) {
            this.processUserStatus();
        }
        if (this.checkEmail) {
            if (this.hasPendingRequest) {
                return;
            }
            this.hasPendingRequest = true;
            this.profile.cellPhone = this.profile.cellPhone === '' ? null : this.profile.cellPhone;
            var oldEmail = this.currentEmailId != this.profile.emailId ? this.currentEmailId : null;
            this.userDetailsService.updateProfile(this.profile, oldEmail, this.isStatusChanged)
                .finally(function () { return _this.hasPendingRequest = false; })
                .subscribe(function (response) { return _this.processEditProfileSubmitReponse(response); });
        }
    };
    NdaModalContentUpdatePersonalInformationComponent.prototype.processUserStatus = function () {
        if (this.currentProfile.userStatus === UserStatus.LOCKED_OUT && this.profile.shouldActivateUser) {
            this.profile.userStatus = UserStatusAction.UNLOCKED;
        }
        else if (this.currentProfile.userStatus === UserStatus.SUSPENDED && this.profile.shouldActivateUser) {
            this.profile.userStatus = UserStatusAction.UNSUSPENDED;
        }
        else {
            this.profile.userStatus = UserStatus.ACTIVE;
        }
    };
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "checkEmail", {
        get: function () {
            var ngForm = this.ngForm;
            return !ngForm.invalid && (this.checkEmailReEnterEmailId || (!this.checkEmailReEnterEmailId && !ngForm.control.controls.reEnterEmailId));
        },
        enumerable: true,
        configurable: true
    });
    NdaModalContentUpdatePersonalInformationComponent.prototype.processEditProfileSubmitReponse = function (response) {
        if (get(response, 'error.exceptions')) {
            this.showUnexpectedErrorModal();
        }
        else {
            this.drawerState = false;
            var serviceResponse = response['resource'];
            this.currentProfile.firstName = serviceResponse.firstName;
            this.currentProfile.lastName = serviceResponse.lastName;
            this.currentProfile.cellPhone = serviceResponse.mobilePhone;
            this.currentProfile.emailId = serviceResponse.email;
            this.showConfirmationModal();
        }
    };
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "isMobileNoAddedOrChanged", {
        get: function () {
            return this.currentProfile.cellPhone != this.profile.cellPhone;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "checkEmailReEnterEmailId", {
        get: function () {
            return this.profile.emailId === this.profile.reEnterEmailId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "isFormChanged", {
        get: function () {
            this.drawerComponentCommunicatorService.isFormChanged = this.ngForm.dirty;
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "totalNumberErrors", {
        get: function () {
            var controls = this.ngForm.controls;
            return filter(controls, function (control) { return control['invalid']; }).length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "displayFormErrorsMessage", {
        get: function () {
            var isPlural = this.totalNumberErrors > 1, tmplName = isPlural ? 'formInvalidPlural' : 'formInvalidSingular';
            return template(this.CONSTANTS[tmplName])({ n: this.totalNumberErrors });
        },
        enumerable: true,
        configurable: true
    });
    NdaModalContentUpdatePersonalInformationComponent.prototype.restrictFullName = function (event) {
        var regex = /[a-zA-Z]/;
        var inputChar = String.fromCharCode(event.charCode);
        if ([0, 8].indexOf(event.charCode) !== -1)
            return;
        if (event.charCode != 32 && !regex.test(inputChar)) {
            event.preventDefault();
        }
    };
    NdaModalContentUpdatePersonalInformationComponent.prototype.restrictCellPhone = function (event) {
        var regex = /[0-9]/;
        var inputChar = String.fromCharCode(event.charCode);
        if ([0, 8].indexOf(event.charCode) !== -1)
            return;
        if (!regex.test(inputChar)) {
            event.preventDefault();
        }
    };
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "isStatusChanged", {
        get: function () {
            return this.profile.shouldActivateUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "isBranch", {
        get: function () {
            return this.sessionManagementService.isBranchUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "isDealer", {
        get: function () {
            return this.sessionManagementService.isDealershipUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentUpdatePersonalInformationComponent.prototype, "disabled", {
        get: function () {
            return this.profile.userType !== 'D';
        },
        enumerable: true,
        configurable: true
    });
    return NdaModalContentUpdatePersonalInformationComponent;
}());
export { NdaModalContentUpdatePersonalInformationComponent };
