import {
  Component,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  ComponentFactory,
  ComponentFactoryResolver,
  OnDestroy,
  ElementRef,
  NgZone,
  TemplateRef,
  ChangeDetectorRef, AfterViewInit, OnInit
} from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { assign, cloneDeep, get, compact } from 'lodash';
import { loginModel } from "../models/login.Model";
import { AppStrings } from '../../assets/app-strings/app-strings';
import { AuthenticationService } from '../services/authentication.service';
import { AppConstantsService } from '../services/app-constants.service';
import { StoreService } from '../services/store.service';
import { CookieService } from 'ngx-cookie';
import { UserModel } from '../models/user-model';
import { IGroup } from '../models/group.interface';
import { environment } from '../../environments/environment';
import { ModalOtpComponent, OtpContent } from '../otp/components/modal-otp/modal-otp.component';
import { IFlowMap } from '../models/flow-map.interface';
import { BookmarkingService } from '../services/bookmarking.service';
import { Subject, Observable } from 'rxjs';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/takeUntil';
import { SpinnerService } from '../loading-spinner/services/spinner/spinner.service';
import { PasswordWarnComponent } from '../components/password-warn/password-warn.component';
import { PasswordStatus, PasswordExpiryService } from '../services/password-expiry.service';
import { PreLoginUserDetailsService } from '../change-password-pre-login/services/pre-login-user-details.service';
import { AnalyticsService } from '../services';
import { ProfileUserType } from '../models/profile.interface';
import { NgForm, AbstractControl } from '@angular/forms';
import { SessionManagementService } from '../services/session-management.service';
import {ModalDirective} from "ngx-bootstrap/modal";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from '../services/user/user.service';
import {OktaAuthService} from "@nda/app/services/okta-auth/okta-auth.service";

const isExternal = location.origin.indexOf('int') < 0;

@Component({
  selector: 'nda-login',
  templateUrl: './nda-login.component.html',
  styleUrls: ['./nda-login.component.scss']
})
export class NdaLoginComponent implements OnInit, OnDestroy {

  AppStrings = AppStrings as { [key: string]: any };
  downstreamAppURL = environment.downstreamAppURL;
  saLoginLink:string = isExternal ? this.downstreamAppURL.SAWE.loginPageURL : this.downstreamAppURL.SAWI.loginPageURL;
  allyAcademyURL:string = environment.allyAcademyURL;
  public readonly COMMON_CONSTANTS = AppStrings['common'];
  public isSubmitClicked : boolean = false;
  public customErrorShow : boolean = false;
  public passOtp : boolean = false;
  uidPattern: string = "^[a-zA-Z0-9]*$";
  pwdPattern: string = "^[^&<>%=?]*$";
  public model:loginModel;
  public userGroups: IGroup[];
  userModel: {} | UserModel[];
  private type:string = "post";
  private path: string = "";
  private headers: string = "";
  public errorMessage = [];
  public isSendOtpScreen: boolean = true;
  @ViewChild('ndaModal', { static: false }) ndaModal: ModalDirective;
  @ViewChild('loginForm', { static: true }) loginForm: NgForm;
  @ViewChild('pwdWarnModel', { static: false }) pwdWarnModel:PasswordWarnComponent;
  @ViewChild('loginElement', { static: true }) loginElement: ElementRef;
  flowMap: IFlowMap;
  public isUserAuthenticated = false;
  private MINUMUM_NUMBER_OF_GROUPS = 3;
  private isGroupSearchComplete = false;
  public isExternalAuth = false;
  private username: string;
  private redirectPath: string;
  public downstreamAppIdentifier : string = null;
  public passwordExpiredStatus : string = 'PASSWORD EXPIRED';

  @ViewChild('otpModalContainer', { read: ViewContainerRef, static: false }) otpModalContainer: ViewContainerRef;
  private ndaModalComponentRef: ComponentRef<ModalOtpComponent>;

  private spinner$: Subject<boolean> = new Subject();
  isPendingApproval: boolean  = false;

  modalRef: BsModalRef;
  constructor(private modalService: BsModalService,
              private authenticationService: AuthenticationService,
              private storeService: StoreService,
              private router: Router,
              private appConstantsService: AppConstantsService,
              private sessionManager: SessionManagementService,
              private cookieService: CookieService,
              private oktaAuthService: OktaAuthService,
              private bookmarkingService: BookmarkingService,
              private activatedRoute: ActivatedRoute,
              private spinnerService: SpinnerService,
              private passwordService : PasswordExpiryService,
              private preLoginUserDetailsService: PreLoginUserDetailsService,
              private analytics: AnalyticsService,
              private resolver: ComponentFactoryResolver,
              private zone: NgZone,
              private cdRef: ChangeDetectorRef,
              private userService: UserService
  ) {

    this.model = {
      userId : '',
      pwd: ''
    };

    this.flowMap = this.appConstantsService.otp_login_flow;

    this.activatedRoute.queryParams.subscribe((isPendingApproval) => {


      if (this.activatedRoute.snapshot.queryParams['isPendingApproval'] === 'true') {
        this.isPendingApproval = true;
        this.cdRef.detectChanges();
      }
    });

    this.spinner$.subscribe((isLoading: boolean) => {
      this.spinnerService[isLoading ? 'show' : 'hide']();
    });
  }


  ngOnDestroy() {
    this.spinner$.unsubscribe();
  }

  ngOnInit() {
    setTimeout( () => {
      this.passwordService.downstreamAppIdentifier = null;
      let userModel = this.cookieService.get('ext_auth');
      let appIdentifier = this.cookieService.get('ext_app_name');
      if ( userModel === undefined || userModel === null ) {
        this.analytics.trackPage({ title: 'ADOS:Login' });
        return;
      }

      userModel = this.preParseAuthCookie(userModel);
      this.downstreamAppIdentifier = this.preParseAuthCookie(appIdentifier);

      this.userModel = JSON.parse(userModel);
      this.isExternalAuth = true;
      this.submitClicked();

      if (this.userModel['resource']) {
        const loginName = this.userModel['resource'].profile.login;
        this.username = loginName.substr(0, loginName.indexOf('@'));
      } else if (get(this.userModel, 'exceptions')) {
        this.userModel['error'] = this.userModel;
      }

      this.cookieService.remove('ext_auth');
      this.cookieService.remove('ext_app_name');

      this.processAuthenticationResponse();
    }, 0);

    // Wait for cookies to populate after the page loads, then clear them.
    setTimeout(() => {
      this.sessionManager.clearCookies();
    }, 3000)
  }

  private preParseAuthCookie(userModel: string) {
    userModel = userModel.replace('"', '');
    userModel = userModel.replace(/\\/g, '');
    userModel = userModel.replace(/.$/, '');

    return userModel;
  }

  public authenticateUser(): void {
    this.spinner$.next(true);
    this.username = this.model.userId;
    this.isUserAuthenticated = false;
    this.isExternalAuth = false;
    this.findUserDetails();
  }

  private showOtpModal(content: OtpContent): void {
    if (this.ndaModalComponentRef) {
      this.otpModalContainer.clear();
    }

    const factory: ComponentFactory<ModalOtpComponent> = this.resolver.resolveComponentFactory(ModalOtpComponent);
    this.ndaModalComponentRef = this.otpModalContainer.createComponent(factory);

    const instance: ModalOtpComponent = this.ndaModalComponentRef.instance;
    instance.onHidden.subscribe(() => this.ndaModalComponentRef.destroy());
    instance.isComplete.subscribe(() => {

      setTimeout(() => {
        this.redirectPath = this.bookmarkingService.getBookmarkedUrl();
        this.bookmarkingService.resetBookmarkedUrl();
        if(this.activatedRoute.snapshot.queryParams['isPendingApproval'] === 'true'){
          this.isPendingApproval = true;
        }
        if(this.spinnerService.isVisible) this.spinnerService.hide();
        if(!window.location.href.includes('/change-password')) {
          this.zone.run(() => {
            this.sessionManager.authorize().then((res)=>{
              this.oktaAuthService.ApigeeRedirect();
            }).catch(error => {
              if (!!this.sessionManager.profile) {
                this.sessionManager.deleteSessionOktaSesion().subscribe(()=>{
                  this.redirectToLogin({ isPendingApproval: true });
                });
              }
            })
          });
        }
      },3000);

    });

    instance.downstreamAppIdentifier = this.downstreamAppIdentifier;
    this.cdRef.detectChanges();
    instance.show(content,this.flowMap);

  }

  public checkQueryParams(){
    //check isPendingApproval flag
    if(this.activatedRoute.snapshot.queryParams['isPendingApproval'] === 'true'){
      this.isPendingApproval = true;
    }
  }

  public responseProcess() {
    let model = this.userModel['resource'];
    const modalData: OtpContent = {
      userModel: <UserModel>this.userModel,
      credentials: this.model
    }

    this.storeService.write(this.appConstantsService.isActive, this.appConstantsService.yes);
    this.storeService.write("pdn", model.primaryDealer);

    // Storing App identifier in service for Password Expire and Password warn downstream navigation
    if (this.isUserLoggedInFromDownStream) {
      this.passwordService.downstreamAppIdentifier = this.downstreamAppIdentifier;
    }

    // MFA_REQUIRED check - To show OTP Dialog
    if (model.status && model.status === this.appConstantsService.auth_Required) {
      this.isSendOtpScreen = true;
      this.spinner$.next(false);
      this.showOtpModal(modalData);


    } // EXPIRED PASSWORD check - to decide password expired flow
    else if (model.status && model.status === PasswordStatus.PasswordExpired) {
      const userProfile = cloneDeep(model.profile);
      this.spinner$.next(false);
      userProfile.userId = model.sid;
      this.preLoginUserDetailsService.profile = userProfile;

      this.zone.run(()=>{this.router.navigate([this.appConstantsService.pre_login_change_password_path]);})
    }
    else {
      this.sessionManager.update('sessionToken', model.sessionToken);
      this.sessionManager.update("profile", model.profile);

      // Session - Id used to maintain user session
      this.sessionManager.update('sessionId', model.sessionId);

      // Storing status and password changed date if present to show ABOUT TO EXPIRE modal in case of PASSWORD_WARN status
      this.passwordService.status = model['status'];
      this.passwordService.passwordExpiryDate = get(model, 'passwordExpiredDate');

      // Condition to divide downstream application flow and NDA flow
      if (this.isUserLoggedInFromDownStream) {
        this.spinner$.next(false);
        setTimeout(()=>{
          if(this.activatedRoute.snapshot.queryParams['isPendingApproval'] === 'true'){
            this.isPendingApproval = true;
          }
          if(this.spinnerService.isVisible) this.spinnerService.hide();
          this.cdRef.detectChanges();
          if (model.status && model.status != PasswordStatus.PasswordWarn) {
            let sessionToken = this.sessionManager.sessionToken;
            window.location.assign(environment.downstreamAppURL[this.downstreamAppIdentifier]['landingPageURL']+sessionToken);
          }
        },1250)
      }
      else {

        setTimeout(()=>{
          if(this.activatedRoute.snapshot.queryParams['isPendingApproval'] === 'true'){
            this.isPendingApproval = true;
          }
          if(this.spinnerService.isVisible) this.spinnerService.hide();
          this.cdRef.detectChanges();
        },1250)
        this.redirectPath = this.bookmarkingService.getBookmarkedUrl();
        this.bookmarkingService.resetBookmarkedUrl();
        this.sessionManager.authorize().then((res)=>{
          if(!this.sessionManager.profile.allyRole
            && this.sessionManager.profile.userType==='B'){
            // this.redirectPath ='/register/ally';
            this.router.navigate(['/register/ally']);

            return
          }
          this.oktaAuthService.ApigeeRedirect();
        }).catch(error => {
          const IS_NDA    = !this.cookieService.get('ext_app_name');
          const NEEDS_APPROVAL = !this.sessionManager.hasBirthright;

          if (IS_NDA && NEEDS_APPROVAL && !!this.sessionManager.profile) {
            this.sessionManager.deleteSessionOktaSesion().subscribe(()=>{
              this.redirectToLogin({ isPendingApproval: true });
            });
          }
        })
      }
    }
  }

  redirectToLogin(params = {}): void {
    this.zone.run(()=>{
      this.router.navigate(['/login'], { queryParams: params });
    });
  }

  public hideForgotUsernameModal(): void {
    this.modalRef.hide();
  }

  public showForgotUsernameModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('login-modal-sm');
    let node: any = (<HTMLScriptElement[]><any>document.getElementsByClassName("modal-title"))[0];
    node.focus({preventScrolling:false});
  }

  public submitClicked(): void {
    this.isSubmitClicked = true;
    this.customErrorShow = true;
    this.checkQueryParams();
    this.trackEndPoint({ errors: this.preAuthFormErrors });
  }

  public credentialsChanged() {
    this.isUserAuthenticated = false;
    this.isGroupSearchComplete = false;
  }

  public loginFormErrorMessage(){
    if (this.preAuthFormErrors.length != 0){
      return this.preAuthFormErrors[0];
    }
    else if (this.errorMessage.length != 0){
      return this.errorMessage[0];
    }else{return "";}
  }

  _restrictUserid(event: any): void {
    this.customErrorShow = false;
    const uidPatternForKeystroke = /^[a-zA-Z0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if ([0, 8].indexOf(event.charCode) !== -1) return;

    if (!uidPatternForKeystroke.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  get baseUrl(): string {
    const base = document.querySelector('base');
    return (base && base.getAttribute('href') || '').replace(/\/$/, '');
  }

  private findUserDetails(): void {
    this.errorMessage = [];

    this.authenticationService.getUserDetails(this.model.userId, this.model.pwd)
      .finally(() => {
        this.spinner$.next(false);
        if( this.userModel['error'] &&  this.userModel['error']['exceptions'] === undefined){
          window.location.href = `${this.baseUrl}/error`;
        }else{
          this.spinner$.next(false);
          this.processAuthenticationResponse();
        }

      })
      .subscribe(
        model => {this.userModel = model},
        err   => this.userModel = err,
      );
  }

  private processAuthenticationResponse() {
    this.processUserModelErrors();

    if (this.errorMessage.length === 0) {
      this.isUserAuthenticated = true;

      if (this.userModel['resource']) {
        this.responseProcess();
      }
      else {
        this.spinner$.next(false);
      }
    }
    else {
      this.spinner$.next(false);
      this.trackEndPoint({ errors: this.errorMessage });
    }
  }

  private transformErrorMessages(): void {
    if (this.isPasswordExpiredOrWarn(this.userModel['error']['exceptions'].message)) {
      this.errorMessage = [];
      this.errorMessage.push(AppStrings['authErrors']['ERR_000002']);
    }
  }

  private transformDownStreamErrorMessages(): void {
    if (this.isUserLoggedInFromDownStream && this.userModel['error'] && this.userModel['error']['exceptions'] && (this.isPasswordExpiredOrWarn(this.userModel['error']['exceptions'].message) || this.isInvalidPasswordOrUserId(this.userModel['error']['exceptions'].code))) {
      return window.location.assign(environment.downstreamAppURL[this.downstreamAppIdentifier]['invalidLoginURL']);
    }
  }

  public isPasswordExpiredOrWarn(message : string) : boolean {
    return message && (message.toUpperCase()  === this.passwordExpiredStatus.toUpperCase() || message.toUpperCase() === PasswordStatus.PasswordWarn);
  }

  public isInvalidPasswordOrUserId(code : string) : boolean {
    return code && code === `ERR_000002`;
  }

  private processUserModelErrors(): void {
    this.transformDownStreamErrorMessages();
    if (this.userModel['error']) {
      if (this.userModel['error']['exceptions'] && this.isExternalAuth === false) {
        this.errorMessage = [];
        let message = AppStrings['authErrors'][this.userModel['error']['exceptions'].code];
        message && this.errorMessage.push(message);
        this.transformErrorMessages();
      }
      else if (this.userModel['error']['exceptions'] && AppStrings['authErrors'][this.userModel['error']['exceptions'].code]) {
        this.errorMessage.push(AppStrings['authErrors'][this.userModel['error']['exceptions'].code]);
      }
    }
  }

  private trackEndPoint({ errors }: { [errors: string]: string[] }): void {
    if (errors.length > 0) {
      this.analytics.trackEndPoint({ errors });
    }
  }

  get isUserLoggedInFromDownStream() : boolean {
    return this.downstreamAppIdentifier && this.downstreamAppIdentifier !== this.appConstantsService.storefrontAppIdentifier;
  }

  get controls(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  get isErrorUidRequired1(): boolean {
    return get(this.controls, 'password.valid') && get(this.controls, 'user.errors.required') && this.isSubmitClicked && !this.isExternalAuth;
  }
  get isErrorUidRequired2(): boolean {
    return get(this.controls, 'user.errors.pattern') && !this.isExternalAuth;
  }

  get isErrorPasswordRequired(): boolean {
    return get(this.controls, 'user.valid') && get(this.controls, 'password.errors.required') && this.isSubmitClicked && !this.isExternalAuth;
  }
  get isPasswordMinimumLength(): boolean {
    return get(this.controls, 'password.errors.minlength') && this.isSubmitClicked && !this.isExternalAuth;
  }
  get isErrorBothRequired(): boolean {
    return get(this.controls, 'user.errors.required') && get(this.controls, 'password.errors.required') && this.isSubmitClicked && !this.isExternalAuth;
  }

  get isPasswordPattern(): boolean {
    return get(this.controls, 'password.errors.pattern') && !this.isExternalAuth;
  }

  get preAuthFormErrors(): string[] {
    if (this.loginForm.form.valid && !this.errorMessage.length && !this.isPendingApproval) {
      return [];
    }
    if(this.activatedRoute.snapshot.queryParams['isPendingApproval'] === 'true'){
      this.isPendingApproval = true;
    }
    if(this.spinnerService.isVisible){
      this.spinner$.next(false);
    }


    return compact([
      this.isErrorUidRequired1     ? get(AppStrings, 'errors.errUidRequired')  : '',
      this.isErrorUidRequired2     ? get(AppStrings, 'errors.errUidPattern')   : '',
      this.isErrorPasswordRequired ? get(AppStrings, 'errors.errPwdRequired')  : '',
      this.isPasswordMinimumLength ? get(AppStrings, 'errors.pwdMinlength')    : '',
      this.isErrorBothRequired     ? get(AppStrings, 'errors.errBothRequired') : '',
      this.isPendingApproval       ? get(AppStrings, 'login.errors.pendingApprovalError') : '',
      this.isPasswordPattern       ? get(AppStrings, 'errors.inValidPwdChars')    : ''
    ]);
  }
}
