<div bsModal #passwordWarn="bs-modal" class="modal fade" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="ally-primary-heading">{{CONSTANTS.passwordInfo}}</h3>
        <button type="button" class="close pull-right text-primary" aria-label="Close" (click)="hide()">
            <i class="allycon-glyph-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <p><span>{{ CONSTANTS.bodyContent1 }}{{ getDate | date:dateFormat }}{{ CONSTANTS.bodyContent2 }}</span></p>
        <form [attr.allytmfn]="'changePasswordButtons'">
            <div class="mt-4">
                <button type="button" class="btn btn-primary font-weight-bold" (click)="changePassword()">{{CONSTANTS.changepassword}}</button>
                <button type="button" class="btn btn-link accessibility-button-group" (click)="hide()">{{isDownStreamFlow ? CONSTANTS.smartAuctionContinue : CONSTANTS.continue}}</button>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
