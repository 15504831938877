/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dealer-apps.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dealer-apps.component";
import * as i3 from "@angular/router";
var styles_DealerAppsComponent = [i0.styles];
var RenderType_DealerAppsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DealerAppsComponent, data: {} });
export { RenderType_DealerAppsComponent as RenderType_DealerAppsComponent };
export function View_DealerAppsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "article", [["class", "container text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "h4 text-ally"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "allycon-error-fill text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "p", [["class", "lead"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.error.body; _ck(_v, 4, 0, currVal_1); }); }
export function View_DealerAppsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dealer-apps", [], null, null, null, View_DealerAppsComponent_0, RenderType_DealerAppsComponent)), i1.ɵdid(1, 114688, null, 0, i2.DealerAppsComponent, [i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DealerAppsComponentNgFactory = i1.ɵccf("app-dealer-apps", i2.DealerAppsComponent, View_DealerAppsComponent_Host_0, {}, {}, []);
export { DealerAppsComponentNgFactory as DealerAppsComponentNgFactory };
