import * as tslib_1 from "tslib";
import { EventTargetInterruptSource } from "@ng-idle/core";
/*
 * Custom interrupt source that uses events on the document element (html tag).
 *
 * This has been modeled after DocumentInterruptSource that hardcode `document.documentElement`
 * as the first argument. We may need to create a custom interrupt from the document of another frame
 * other than the document on window.
 *
 * Original source for DocumentInterruptSource: https://github.com/HackedByChinese/ng2-idle/blob/master/modules/core/src/documentinterruptsource.ts
 */
var CustomDocumentInterruptSource = /** @class */ (function (_super) {
    tslib_1.__extends(CustomDocumentInterruptSource, _super);
    function CustomDocumentInterruptSource(el, events, options) {
        if (events === void 0) { events = DEFAULT_EVENTS; }
        return _super.call(this, el, events, options) || this;
    }
    /*
     * Checks to see if the event should be filtered.
     * @param event - The original event object.
     * @return True if the event should be filtered (don't cause an interrupt); otherwise, false.
     */
    CustomDocumentInterruptSource.prototype.filterEvent = function (event) {
        // some browser bad input hacks
        if (event.type === 'mousemove'
            // fix for Chrome destop notifications
            && ((event.originalEvent && event.originalEvent.movementX === 0 &&
                event.originalEvent.movementY === 0)
                // fix for webkit fake mousemove
                || (event.movementX !== void 0 && !event.movementX || !event.movementY))) {
            return true;
        }
        return false;
    };
    return CustomDocumentInterruptSource;
}(EventTargetInterruptSource));
var DEFAULT_EVENTS = 'mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll';
export default CustomDocumentInterruptSource;
