import { SpinnerService } from '../../services/spinner/spinner.service';
var LoadingSpinnerComponent = /** @class */ (function () {
    function LoadingSpinnerComponent(spinnerService) {
        this.spinnerService = spinnerService;
    }
    Object.defineProperty(LoadingSpinnerComponent.prototype, "isVisible", {
        get: function () {
            return this.spinnerService.isVisible;
        },
        enumerable: true,
        configurable: true
    });
    return LoadingSpinnerComponent;
}());
export { LoadingSpinnerComponent };
