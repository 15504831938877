import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SpinnerService } from '../../../loading-spinner/services/spinner/spinner.service';
import { get } from 'lodash';
import { Observable } from 'rxjs/Observable';
import { MatchOtherValidator } from '../../../registration/validators/match-other';
import { UserService } from '../../../services/user/user.service';
import * as AppStrings from '../../../../assets/app-strings/appStrings.json';
import { ModalPasswordChangedComponent } from '../modal-password-changed/modal-password-changed.component';
import { AppConstantsService } from '../../../services/app-constants.service';
import { PreLoginUserDetailsService } from '../../services/pre-login-user-details.service';
import { PasswordExpiryService } from '../../../services';
import { environment } from '../../../../environments/environment';
var ChangePasswordPreLoginComponent = /** @class */ (function () {
    function ChangePasswordPreLoginComponent(fb, spinnerService, router, userService, appConstantsService, userDetailsService, passwordExpiryService) {
        this.fb = fb;
        this.spinnerService = spinnerService;
        this.router = router;
        this.userService = userService;
        this.appConstantsService = appConstantsService;
        this.userDetailsService = userDetailsService;
        this.passwordExpiryService = passwordExpiryService;
        this.pwdPattern = "^[^&<>%=?]*$";
        this.CONSTANTS = tslib_1.__assign({}, AppStrings['profile']['changePassword'], AppStrings['common']);
    }
    ChangePasswordPreLoginComponent.prototype.ngOnInit = function () {
        this.resetComponent();
    };
    ChangePasswordPreLoginComponent.prototype.resetComponent = function () {
        this.createForm();
        this.isSubmitClicked = false;
        this.showConfirmation = false;
        this.isNewPasswordMatchesWithOldPasswordHistory = false;
        this.isTemporaryOrExpiredPasswordInvalid = false;
    };
    Object.defineProperty(ChangePasswordPreLoginComponent.prototype, "showOldPasswordError", {
        get: function () {
            return this.passwordForm.controls.oldPassword.value.length === 0 && this.isSubmitClicked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangePasswordPreLoginComponent.prototype, "showEmptyNewPasswordError", {
        get: function () {
            return this.passwordForm.controls.newPassword.value.length === 0 && this.isSubmitClicked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangePasswordPreLoginComponent.prototype, "isPasswordHasRestrictedCharactersInOldPassword", {
        get: function () {
            return this.isSubmitClicked && get(this.passwordForm.controls.oldPassword, 'errors.pattern');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangePasswordPreLoginComponent.prototype, "isPasswordHasRestrictedCharactersInNewPassword", {
        get: function () {
            return this.isSubmitClicked && get(this.passwordForm.controls.newPassword, 'errors.pattern');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangePasswordPreLoginComponent.prototype, "isPasswordHasRestrictedCharactersInNewConfirmPassword", {
        get: function () {
            return this.isSubmitClicked && get(this.passwordForm.controls.passwordConfirmation, 'errors.pattern');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangePasswordPreLoginComponent.prototype, "showGenericNewPasswordError", {
        get: function () {
            return !this.passwordForm.controls.newPassword.valid
                && this.isSubmitClicked
                && this.passwordForm.controls.newPassword.value.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangePasswordPreLoginComponent.prototype, "showReEnterPasswordError", {
        get: function () {
            return !this.passwordForm.controls.passwordConfirmation.valid
                && this.isSubmitClicked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangePasswordPreLoginComponent.prototype, "oldPasswordErrorMessage", {
        get: function () {
            if (!!this.showOldPasswordError) {
                return this.CONSTANTS.errors.currentPassword;
            }
            if (!!this.showInvalidTemporaryOrExpiredPasswordError) {
                return this.CONSTANTS.errors.invalidCurrentPassword;
            }
            if (!!this.isPasswordHasRestrictedCharactersInOldPassword) {
                return this.CONSTANTS.errors.invalidPassword;
            }
            else {
                return "";
            }
        },
        enumerable: true,
        configurable: true
    });
    ChangePasswordPreLoginComponent.prototype.newPasswordErrorMessage = function (passMatches) {
        if (!!this.showEmptyNewPasswordError) {
            return this.CONSTANTS.errors.newPasswordRequired;
        }
        if (!!this.showGenericNewPasswordError) {
            return this.CONSTANTS.errors.newPasswordGeneric;
        }
        if (!!this.showNewPasswordMatchesWithOldPasswordHistoryError) {
            return this.CONSTANTS.errors.newPasswordMatchesPreviousPassword;
        }
        if (!!passMatches && !!this.isSubmitClicked) {
            return this.CONSTANTS.errors.passwordMatchesUsername;
        }
        if (!!this.isPasswordHasRestrictedCharactersInNewPassword) {
            return this.CONSTANTS.errors.invalidPassword;
        }
        else {
            return "";
        }
    };
    Object.defineProperty(ChangePasswordPreLoginComponent.prototype, "reEnterNewPasswordErrorMessage", {
        get: function () {
            if (!!this.showReEnterPasswordError) {
                return this.CONSTANTS.errors.reEnterPassword;
            }
            if (!!this.isPasswordHasRestrictedCharactersInNewConfirmPassword) {
                return this.CONSTANTS.errors.invalidPassword;
            }
            else {
                return "";
            }
        },
        enumerable: true,
        configurable: true
    });
    ChangePasswordPreLoginComponent.prototype.createForm = function () {
        this.passwordForm = this.fb.group({
            username: [this.userDetailsService.userName || ''],
            oldPassword: ['', Validators.required],
            newPassword: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(20),
                    this.validatePasswordComplexity,
                    this.validatePasswordNotMatchUsername
                ])],
            passwordConfirmation: ['', [
                    Validators.required,
                    this.validatePasswordComplexity,
                    MatchOtherValidator('newPassword')
                ]]
        });
    };
    ChangePasswordPreLoginComponent.prototype.validatePasswordComplexity = function (c) {
        var PASSWORD_COMPLEXITY = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
        return PASSWORD_COMPLEXITY.test(c.value) ? null : {
            validatePassword: {
                valid: false
            }
        };
    };
    ChangePasswordPreLoginComponent.prototype.validatePasswordNotMatchUsername = function (control) {
        var password = control.value;
        var usernameObject = control.root.get('username');
        var username = usernameObject ? usernameObject.value : '';
        return !(password.toLowerCase() === username.toLowerCase())
            ? null
            : { passwordMatchesUsername: true };
    };
    ChangePasswordPreLoginComponent.prototype.onSubmit = function () {
        var _this = this;
        this.isSubmitClicked = true;
        this.isNewPasswordMatchesWithOldPasswordHistory = false;
        this.isTemporaryOrExpiredPasswordInvalid = false;
        if (this.passwordForm.valid) {
            var changePasswordModel = {
                oldPassword: this.passwordForm.controls.oldPassword.value,
                newPassword: this.passwordForm.controls.newPassword.value
            };
            this.spinnerService.show();
            var sessionDetails = this.userDetailsService.sessionDetails;
            this.userService.changePassword(changePasswordModel, sessionDetails['userId'])
                .finally(function () { return _this.spinnerService.hide(); })
                .subscribe(function () { return _this.passwordChanged.show(); }, function (err) { return _this.handleError(err); });
        }
    };
    ChangePasswordPreLoginComponent.prototype.cancelForm = function () {
        var downstreamAppIdentifier = this.passwordExpiryService.downstreamAppIdentifier;
        if (downstreamAppIdentifier) {
            this.navigateToExternalUrl(downstreamAppIdentifier);
        }
        else {
            this.router.navigate(['/login']);
        }
    };
    ChangePasswordPreLoginComponent.prototype.navigateToExternalUrl = function (downstreamAppIdentifier) {
        window.location.assign(environment.downstreamAppURL[downstreamAppIdentifier]['loginPageURL']);
    };
    Object.defineProperty(ChangePasswordPreLoginComponent.prototype, "showNewPasswordMatchesWithOldPasswordHistoryError", {
        get: function () {
            return this.isSubmitClicked && this.isNewPasswordMatchesWithOldPasswordHistory;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangePasswordPreLoginComponent.prototype, "showInvalidTemporaryOrExpiredPasswordError", {
        get: function () {
            return this.isSubmitClicked && this.isTemporaryOrExpiredPasswordInvalid;
        },
        enumerable: true,
        configurable: true
    });
    ChangePasswordPreLoginComponent.prototype.handleError = function (err) {
        var errorResponse = get(err, 'error.exceptions');
        if (errorResponse && errorResponse['code'] === 'ERR_000027') {
            this.isNewPasswordMatchesWithOldPasswordHistory = true;
        }
        else if (errorResponse && errorResponse['code'] === 'ERR_000028') {
            this.isTemporaryOrExpiredPasswordInvalid = true;
        }
        else {
            return Observable.throw(err);
        }
    };
    return ChangePasswordPreLoginComponent;
}());
export { ChangePasswordPreLoginComponent };
