export const environment = {
  production: true ,
  env:'UAT',
  refreshTimer:9,
  ndaServerHost: `${location.origin}`,
  ndaServerURLS: {
    workflowServices: `https://uat.securedealer.ally.com/acs/v1/dash/workflowservices`,
    workflowRegistration: `https://uat.securedealer.ally.com/acs/v1/dash/workflowservices/registration`,
    userServices: `https://uat.securedealer.ally.com/acs/v1/dash/userservices`,
    dealerServices: `https://uat.securedealer.ally.com/acs/v1/dash/dealerservices`,
    sessionServices: `https://uat.securedealer.ally.com/acs/v1/dash/sessionservices`,
    documentServices: `https://uat.securedealer.ally.com/acs/v1/dash/documentservices`,
    registrationSessionServices: `https://uat.securedealer.ally.com/acs/v1/dash/sessionservices/registration`,
  },
  ndaServerURL:  `${location.origin}/services/rest`,
  apigeeAuthorize: {
    baseUrl: 'https://uat.securedealer.ally.com/acs/v1/dash/oauth2/authorize', //Apigee Authorize Endpoint
    redirectUri:  {'external' :'https://uat.securedealer.ally.com/ui/', 'internal':'https://uat.securedealer.int.ally.com/ui'},
    appName: 'DASH-UAT',//Apigee api product App Name (not used or validated in backend)
    clientSecret: 'Oc2TdF7ufHoPZzgyMaQpKgKeCRoajigs' //Apigee App key
  },
  apigeeToken: {
    baseUrl: 'https://uat.securedealer.ally.com/acs/v1/dash/oauth2/token'
  },
  oktaAPIBaseUrl: 'https://uat.dealeriam.ally.com',
  oktaOAuth: {
    clientConfigUrls: {
      url:          'https://uat.dealeriam.ally.com',
      issuer:       'https://uat.dealeriam.ally.com/oauth2/${issuerId}',
      authorizeUrl: 'https://uat.dealeriam.ally.com/oauth2/${issuerId}/v1/authorize',
      redirectUri:  'https://uat.securedealer.int.ally.com/ui',
    },
    clientId: '0oa1kw1mxidDjPnwM2p7',
    issuerId: {
      internal: 'aus10qdf2mzTPNo2Z2p7',
      external: 'aus1lkopj6SZsYpbO2p7'
    },
  },

  leadsUrl: 'https://uat.dealeriam.ally.com/home/ndaoktauatcap_leads_1/0oanict9oi6v6M1ja2p6/alnniew6oCHJb11V62p6',
  allyAcademyURL: 'https://www.ally.com/dealer/training/',
  appLogOutURL: {
    SC:'https://uat-securesmartcash.int.ally.com/SmartCash_Web/us/en/smartcash/smartcash/SmartCashLogoffProcess',
    SA:'https://sauat2.smartauctionlogin.com/sa-web/logout?locale=en_US&cssdirectory=sa'
  },
  userLogoutURL: {
    ally: 'https://aticsi.sharepoint.com',
  },
  downstreamAppURL: {
    SAW: {
      landingPageURL : 'https://uat.dealeriam.ally.com/home/uat-securedealerally_smartauctiondirectexternalsaml20_1/0oad6tib2fSz8lGHi2p7/alnd6tsvqkXQd5vf32p7?sessionToken=',
      loginPageURL : 'https://bdg1j.int.ally.com/dealer/smartauction/',
      invalidLoginURL: 'https://sauat2.smartauctionlogin.com/closed/resources/US/en/invalidlogin.html'
    },
    SAWI: {
      landingPageURL : 'https://uat.dealeriam.ally.com/home/uat-securedealerally_smartauctiondirectinternalsaml20_1/0oad6tmk0ovCiS4iT2p7/alnd6u1xdn1R94jVD2p7?sessionToken=',
      loginPageURL : 'https://sauat-smartauctionlogin.int.ally.com/closed/resources/US/en/web_login.html',
      invalidLoginURL: 'https://sauat-smartauctionlogin.int.ally.com/closed/resources/US/en/invalidlogin.html'
    },
    SAWE: {
      landingPageURL : 'https://uat.dealeriam.ally.com/home/uat-securedealerally_smartauctiondirectexternalsaml20_1/0oad6tib2fSz8lGHi2p7/alnd6tsvqkXQd5vf32p7?sessionToken=',
      loginPageURL : 'https://sauat2.smartauctionlogin.com/closed/resources/US/en/web_login.html',
      invalidLoginURL: 'https://sauat2.smartauctionlogin.com/closed/resources/US/en/invalidlogin.html'
    },
    SFW: {
      loginPageURL: 'https://www.ally.com/dealer/'
    }
  },
  smartAuction: {
    auctionAccess: 'https://auctionaccess.com/',
    auctionAccessEmail: 'mailto:customerservice@auctionaccess.com'
  },
  adobeLaunchSdk: '//assets.adobedtm.com/cd392cd56789/59262f6625f1/launch-542865d26782-development.min.js',
  footerUrls: {
    about:    'http://www.ally.com/about/ally-story/index.html',
    privacy:  'https://www.ally.com/dealer/privacy/',
    security: 'https://www.ally.com/dealer/security/',
    legal:    'https://www.ally.com/dealer/legal.html',
    feedback: 'https://survey.ally.com/wix/4/p1022078637.aspx?l=9&Location=US',
  },
  launchDarkly: {
    clientSideId: '65ba7952d6f15f1064ff6484' // UAT ID
  }
};