/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nda-warning-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./nda-warning-modal.component";
var styles_NdaWarningModalComponent = [i0.styles];
var RenderType_NdaWarningModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NdaWarningModalComponent, data: {} });
export { RenderType_NdaWarningModalComponent as RenderType_NdaWarningModalComponent };
export function View_NdaWarningModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "article", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-primary font-weight-bold"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-link accessibility-button-group"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Go Back"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalQuestion; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.modalDetailMessage; _ck(_v, 4, 0, currVal_1); }); }
export function View_NdaWarningModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nda-warning-modal", [], null, null, null, View_NdaWarningModalComponent_0, RenderType_NdaWarningModalComponent)), i1.ɵdid(1, 49152, null, 0, i2.NdaWarningModalComponent, [], null, null)], null, null); }
var NdaWarningModalComponentNgFactory = i1.ɵccf("app-nda-warning-modal", i2.NdaWarningModalComponent, View_NdaWarningModalComponent_Host_0, {}, {}, []);
export { NdaWarningModalComponentNgFactory as NdaWarningModalComponentNgFactory };
