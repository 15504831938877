import * as tslib_1 from "tslib";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/do';
import { ApiGatewayHeader } from '../definitions/api-gateway-header.namespace';
import { OktaAuthService } from "../../services/okta-auth/okta-auth.service";
var ApiGatewayInterceptor = /** @class */ (function () {
    function ApiGatewayInterceptor(oktaAuthService) {
        this.oktaAuthService = oktaAuthService;
    }
    ApiGatewayInterceptor.clearToken = function () {
        ApiGatewayInterceptor.Token = undefined;
    };
    ApiGatewayInterceptor.prototype.setGatewayHeader = function (gatewayHeader) {
        sessionStorage.setItem('gateway-header', gatewayHeader);
        ApiGatewayInterceptor.Token = gatewayHeader;
    };
    Object.defineProperty(ApiGatewayInterceptor.prototype, "gatewayHeader", {
        get: function () {
            if (ApiGatewayInterceptor.Token === undefined) {
                ApiGatewayInterceptor.Token = sessionStorage.getItem('gateway-header');
            }
            return ApiGatewayInterceptor.Token;
        },
        enumerable: true,
        configurable: true
    });
    ApiGatewayInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        var isToken = request.url.includes('token');
        var isOtp = request.url.includes('send-otp') || request.url.includes('verify-otp');
        var isAuthn = request.url.includes('authn') && !isOtp;
        var isAuthorizeUser = request.url.includes('authorize-user');
        var isPublic = request.url.includes('public');
        if (!isToken && !isAuthn) {
            var isAuthorize = !!this.oktaAuthService.getApigeeJwt() && !isAuthorizeUser && !isPublic && !isOtp;
            request = request.clone({
                withCredentials: true,
                setHeaders: tslib_1.__assign({}, (!!this.gatewayHeader && { 'gateway-header': this.gatewayHeader }), (isAuthorize && { 'Authorization': this.oktaAuthService.apigeeAccessToken }))
            });
        }
        return next.handle(request)
            .do(function (event) { return _this.handleResponse(event); });
    };
    ApiGatewayInterceptor.prototype.handleResponse = function (event) {
        if (event instanceof HttpResponse) {
            var header = event.headers.get(ApiGatewayHeader.token);
            if (header) {
                this.setGatewayHeader(header);
            }
        }
    };
    return ApiGatewayInterceptor;
}());
export { ApiGatewayInterceptor };
