/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-detail-flyout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./contact-detail-flyout.component";
var styles_ContactDetailFlyoutComponent = [i0.styles];
var RenderType_ContactDetailFlyoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactDetailFlyoutComponent, data: {} });
export { RenderType_ContactDetailFlyoutComponent as RenderType_ContactDetailFlyoutComponent };
function View_ContactDetailFlyoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "accessibility-link-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "text-primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "contactName"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "text-primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("tel:" + _co.contact.ph); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.contact.ph; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.contact.contactName; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.CONSTANTS[_co.contact.contactRoleID]; _ck(_v, 8, 0, currVal_3); var currVal_4 = ("mailto:" + _co.contact.email); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.contact.email; _ck(_v, 11, 0, currVal_5); }); }
export function View_ContactDetailFlyoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactDetailFlyoutComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contact; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ContactDetailFlyoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-detail-flyout", [], null, null, null, View_ContactDetailFlyoutComponent_0, RenderType_ContactDetailFlyoutComponent)), i1.ɵdid(1, 49152, null, 0, i3.ContactDetailFlyoutComponent, [], null, null)], null, null); }
var ContactDetailFlyoutComponentNgFactory = i1.ɵccf("app-contact-detail-flyout", i3.ContactDetailFlyoutComponent, View_ContactDetailFlyoutComponent_Host_0, { contact: "contact" }, {}, []);
export { ContactDetailFlyoutComponentNgFactory as ContactDetailFlyoutComponentNgFactory };
