import * as tslib_1 from "tslib";
import { Subject } from 'rxjs/Subject';
import { AppStrings } from '../../../assets/app-strings/app-strings';
var NdaWarningModalComponent = /** @class */ (function () {
    function NdaWarningModalComponent() {
        this.didConfirm$ = new Subject();
        this.didDecline$ = new Subject();
        this.constants = tslib_1.__assign({}, AppStrings['warningModal']);
    }
    NdaWarningModalComponent.prototype.confirm = function () {
        this.didConfirm$.next(true);
        this.didConfirm$.complete();
    };
    NdaWarningModalComponent.prototype.decline = function () {
        this.didDecline$.next(false);
        this.didDecline$.complete();
    };
    Object.defineProperty(NdaWarningModalComponent.prototype, "modalQuestion", {
        get: function () {
            return this.constants.question;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaWarningModalComponent.prototype, "modalDetailMessage", {
        get: function () {
            return this.constants.detailMessage;
        },
        enumerable: true,
        configurable: true
    });
    return NdaWarningModalComponent;
}());
export { NdaWarningModalComponent };
