/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./loading-spinner/components/loading-spinner/loading-spinner.component.ngfactory";
import * as i4 from "./loading-spinner/components/loading-spinner/loading-spinner.component";
import * as i5 from "./loading-spinner/services/spinner/spinner.service";
import * as i6 from "ngx-bootstrap/popover";
import * as i7 from "./app.component";
import * as i8 from "./services/session-management.service";
import * as i9 from "./services/authentication.service";
import * as i10 from "./services/app-idle/app-idle.service";
import * as i11 from "./services/okta-auth/okta-auth.service";
import * as i12 from "ngx-bootstrap/modal";
import * as i13 from "./services/store.service";
import * as i14 from "./services/bookmarking.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "nda-loading-spinner", [], null, null, null, i3.View_LoadingSpinnerComponent_0, i3.RenderType_LoadingSpinnerComponent)), i1.ɵdid(3, 49152, null, 0, i4.LoadingSpinnerComponent, [i5.SpinnerService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "form-group ie-checkbox"], ["id", "24CF4C7D395"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "control-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "custom-control custom-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "input", [["class", "custom-control-input"], ["id", "24F545731EED"], ["type", "checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "label", [["class", "custom-control-label"], ["for", "24F545731EED"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵprd(5120, null, i6.PopoverConfig, i7.PopoverConfigExtended, []), i1.ɵdid(2, 1163264, null, 0, i7.AppComponent, [i8.SessionManagementService, i9.AuthenticationService, i2.ActivatedRoute, i10.AppIdleService, i11.OktaAuthService, i12.BsModalService, i2.Router, i13.StoreService, i14.BookmarkingService, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
