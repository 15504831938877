import { Observable } from "rxjs";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { NdaChatService } from "../../services/chat/nda-chat.service";
import { Router, Event, NavigationEnd } from "@angular/router";

import "rxjs/add/operator/catch";
import { SessionManagementService } from "../../services/session-management.service";

@Component({
  selector: "app-nda-chat",
  templateUrl: "./nda-chat.component.html",
  styleUrls: ["./nda-chat.component.scss"],
  host: {'class': 'btn btn-link'}
})
export class NdaChatComponent implements OnInit {
  @ViewChild('container', { static: true  }) container: ElementRef;

  private userAuthToken: string;

  get isBranchUser(): boolean {
    return this.sessionManager.isBranchUser;
  }

  constructor(
    private chatService: NdaChatService,
    private router: Router,
    private sessionManager: SessionManagementService
  ) {  }

  ngOnInit() {
    this.authenticate()
      .catch(() => Observable.of(''))
      .subscribe(() => this.initPlatform());
  }

  initPlatform(): void {
    this.chatService.loadScript();
    this.newPage(this.router.url);

    if (!this.isBranchUser) {
      this.subscribeToRoute();
      this.setupDelegateFunctions();
    }
  }

  setupDelegateFunctions() {
    window["lpGetAuthenticationToken"] = delegate => delegate(this.userAuthToken.trim());
  }

  authenticate(): Observable<string> {
    return this.chatService.authenticate()
      .map((jwt: string) => this.userAuthToken = jwt || '');
  }

  subscribeToRoute() {
    this.router.events.subscribe((route: Event) => {
      if (route instanceof NavigationEnd) {
        this.newPage(route.url);
      }
    });
  }

  private newPage(url: string) {
    this.chatService.newPage(url);
  }
}
