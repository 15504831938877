import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { StoreService } from '../../services';
import { last } from 'lodash';
import { NavigatorDetails } from './navigator-details';
import 'rxjs/add/operator/map';
export var ErrorType;
(function (ErrorType) {
    ErrorType.Default = 'default';
    ErrorType.Offline = 'offline';
})(ErrorType || (ErrorType = {}));
var ErrorsHandler = /** @class */ (function () {
    function ErrorsHandler(storeService) {
        this.storeService = storeService;
    }
    Object.defineProperty(ErrorsHandler.prototype, "baseUrl", {
        get: function () {
            var base = document.querySelector('base');
            return ((base && base.getAttribute('href')) || '').replace(/\/$/, '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ErrorsHandler.prototype, "isOffline", {
        get: function () {
            return NavigatorDetails.isOffline;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ErrorsHandler.prototype, "clientBrowser", {
        get: function () {
            return NavigatorDetails.clientBrowser;
        },
        enumerable: true,
        configurable: true
    });
    ErrorsHandler.prototype.handleError = function (error) {
        if (this.isOffline) {
            var params = new HttpParams().set('type', ErrorType.Offline);
            this.handleRenderError(params);
        }
        else if (error instanceof HttpErrorResponse) {
            var requestSegments = error.url.split('?'), requestUrl = requestSegments[0], requestParms = requestSegments[1] && requestSegments[1].split('&');
            var params_1 = new HttpParams()
                .set('browser', this.clientBrowser)
                .set('referrerUrl', last(location.href.split(location.origin)))
                .set('requestUrl', requestUrl);
            if (requestParms) {
                requestParms.forEach(function (param) {
                    var _a = tslib_1.__read(param.split('='), 2), key = _a[0], val = _a[1];
                    params_1 = params_1.set(key, val);
                });
            }
            this.storeService.write('HttpErrorResponse', error);
            this.handleRenderError(params_1);
        }
        else {
            console.error(error);
        }
    };
    ErrorsHandler.prototype.handleRenderError = function (params) {
        window.location.href = this.baseUrl + "/error/?" + params.toString();
    };
    return ErrorsHandler;
}());
export { ErrorsHandler };
