import { OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { AddAppsService } from '../../services/add-apps.service';
var StatusOption;
(function (StatusOption) {
    StatusOption.approved = 'APPROVED';
    StatusOption.pending = 'Pending';
})(StatusOption || (StatusOption = {}));
var NdaRemoveApplicationReportsComponent = /** @class */ (function () {
    function NdaRemoveApplicationReportsComponent(drawerCommunicatorService, addAppsService) {
        this.drawerCommunicatorService = drawerCommunicatorService;
        this.addAppsService = addAppsService;
        this.CONSTANTS = AppStrings['applicationAndReports'];
    }
    NdaRemoveApplicationReportsComponent.prototype.ngOnInit = function () {
        this.applicationToRequest = cloneDeep(this.data['applicationList'].filter(function (item) { return item['status'] == StatusOption.approved; }));
        this.isPendingApp = this.data['applicationList'].filter(function (item) { return item['status'] == StatusOption.pending; });
    };
    Object.defineProperty(NdaRemoveApplicationReportsComponent.prototype, "hasAssignedApplications", {
        get: function () {
            return !!this.applicationToRequest.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaRemoveApplicationReportsComponent.prototype, "isApplicationSelected", {
        get: function () {
            return !!this.selectedApplications.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaRemoveApplicationReportsComponent.prototype, "selectedApplications", {
        get: function () {
            return this.applicationToRequest.filter(function (item) { return item['isChecked']; });
        },
        enumerable: true,
        configurable: true
    });
    NdaRemoveApplicationReportsComponent.prototype.toggleRoleSelect = function () {
        this.drawerCommunicatorService.isFormChanged = this.isApplicationSelected;
    };
    NdaRemoveApplicationReportsComponent.prototype.handlerSubmitForm = function () {
        this.addAppsService.showRemoveAppConfirm = true;
        this.addAppsService.removeAppsRequest = this.selectedApplications;
        this.addAppsService.isDeleteAllApps = ((this.applicationToRequest.length == this.selectedApplications.length) && !this.isPendingApp.length) ? true : false;
    };
    NdaRemoveApplicationReportsComponent.prototype.removeAppsErrorMessage = function (submitted) {
        if (!!submitted && !this.isApplicationSelected) {
            return this.CONSTANTS.selectARole;
        }
        else {
            return "";
        }
    };
    NdaRemoveApplicationReportsComponent.prototype.formErrorMessage = function (submitted) {
        if (!!submitted && !this.isApplicationSelected) {
            return this.CONSTANTS.selectARole;
        }
        else {
            return "";
        }
    };
    return NdaRemoveApplicationReportsComponent;
}());
export { NdaRemoveApplicationReportsComponent };
