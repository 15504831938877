import { NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as OktaAuth from '@okta/okta-auth-js';
import { Observable } from 'rxjs/Observable';
import { assign, template } from 'lodash';
import { environment } from '@nda/environments/environment';
import { generatePKCECodes } from './pkce-utils';
import 'rxjs/add/operator/mergeMap';
import { Router } from "@angular/router";
import { StoreService } from "@nda/app/services";
export var OidcClientConfigValue;
(function (OidcClientConfigValue) {
    OidcClientConfigValue.idToken = "id_token";
    OidcClientConfigValue.accessToken = "token";
})(OidcClientConfigValue || (OidcClientConfigValue = {}));
var OktaAuthService = /** @class */ (function () {
    function OktaAuthService(http, zone, storeService, router) {
        var _this = this;
        this.http = http;
        this.zone = zone;
        this.storeService = storeService;
        this.router = router;
        /*  This function generates the PKCE codes and then calls the Apigee Authorize Redirect */
        this.ApigeeRedirect = function () {
            generatePKCECodes().then(function (res) {
                var redirectUrl = _this.createApigeeAuthorizeUrl(res);
                window.location.replace(redirectUrl);
            });
        };
        var isExternal = location.origin.indexOf('int') < 0;
        var issuerId = environment.oktaOAuth.issuerId[isExternal ? 'external' : 'internal'];
        var configUrls = environment.oktaOAuth.clientConfigUrls;
        var config = assign({}, configUrls, {
            redirectUri: location.origin + "/ui",
            clientId: environment.oktaOAuth.clientId,
            issuer: template(configUrls.issuer)({ issuerId: issuerId }),
            authorizeUrl: template(configUrls.authorizeUrl)({ issuerId: issuerId })
        });
        this.authClient = new OktaAuth(config);
    }
    OktaAuthService.prototype.generateFingerprint = function () {
        var _this = this;
        return Observable.create(function (observer) {
            _this.authClient.fingerprint()
                .then(function (fingerprint) {
                observer.next(fingerprint);
                observer.complete();
            })
                .catch(function (err) {
                observer.error(err);
                observer.complete();
            });
        });
    };
    OktaAuthService.prototype.requestToken = function (config) {
        var _this = this;
        var DEFAULTS = { responseType: OidcClientConfigValue.accessToken };
        config = assign(DEFAULTS, config);
        return this.authClient.token.getWithoutPrompt(config)
            .then(function (token) { return _this.token = token; });
    };
    OktaAuthService.prototype.requestSession = function () {
        return this.authClient.session.get();
    };
    OktaAuthService.prototype.requestTokenDetails = function (token) {
        var url = environment.ndaServerURLS.userServices + "/authorize-user";
        var headers = new HttpHeaders()
            .set('Authorization', "Bearer " + token);
        return this.http.get(url, { headers: headers });
    };
    /*  This function is used to concatanate the url/endpoint for the Apigee Authorize Redirect */
    OktaAuthService.prototype.createApigeeAuthorizeUrl = function (code_challenge) {
        var isExternal = location.origin.indexOf('int') < 0;
        var extIntDomainurl = environment.apigeeAuthorize.redirectUri[isExternal ? 'external' : 'internal'];
        return (environment.apigeeAuthorize.baseUrl +
            '?client_id=' +
            environment.apigeeAuthorize.clientSecret +
            '&redirect_uri=' +
            extIntDomainurl +
            '&response_type=code' +
            '&scope=alertTriggers' +
            '&state=5SnnWYAcHjiJ' +
            '&code_challenge=' +
            code_challenge +
            '&code_challenge_method=S256' +
            '&app_name=' +
            environment.apigeeAuthorize.appName);
    };
    ;
    /*  This function calls the dealer/oauth2/token api call to get a JWT bearer token from Apigee  */
    OktaAuthService.prototype.getApigeeToken = function () {
        var _this = this;
        var url = environment.apigeeToken.baseUrl;
        var tokenReq = this.processAccessToken();
        var headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };
        return new Promise(function (resolve, reject) {
            _this.http.post(url, tokenReq, headers)
                .subscribe(function (data) {
                if (typeof (data) === 'object')
                    localStorage.setItem('proxyLayerObject', JSON.stringify(data));
                resolve(data);
            }, function (error) {
                reject(error);
            });
        });
    };
    ;
    /*  This function generates the body for the Apigee Token call  */
    OktaAuthService.prototype.processAccessToken = function () {
        var codeVerifier = sessionStorage.getItem('verifier');
        var apigeeJwtAccessToken = JSON.parse(localStorage.getItem('proxyLayerObject'));
        var grantType = "authorization_code";
        var refreshParam = '';
        var isExternal = location.origin.indexOf('int') < 0;
        var extIntDomainurl = environment.apigeeAuthorize.redirectUri[isExternal ? 'external' : 'internal'];
        if (!!apigeeJwtAccessToken && typeof (apigeeJwtAccessToken) === 'object') {
            grantType = 'refresh_token';
            refreshParam = '&refresh_token=' + apigeeJwtAccessToken['refresh_token'];
        }
        return 'grant_type=' + grantType
            + '&client_id=' + environment.apigeeAuthorize.clientSecret
            + '&code=' + sessionStorage.getItem('authCode') + '&code_verifier='
            + codeVerifier + '&redirect_uri='
            + extIntDomainurl
            + refreshParam;
    };
    ;
    OktaAuthService.prototype.refreshToken = function () {
        var _this = this;
        return this.authClient.token.refresh(this.token)
            .then(function (token) { return _this.token = token; });
    };
    OktaAuthService.prototype.clearToken = function () {
        this.token = null;
    };
    OktaAuthService.prototype.setApigeeJwt = function (jwtResponse) {
        localStorage.setItem('proxyLayerObject', JSON.stringify(jwtResponse));
    };
    OktaAuthService.prototype.getApigeeJwt = function () {
        return localStorage.getItem('proxyLayerObject');
    };
    OktaAuthService.prototype.clearApigeeJwt = function () {
        localStorage.removeItem('proxyLayerObject');
    };
    Object.defineProperty(OktaAuthService.prototype, "apigeeAccessToken", {
        // Returns the current Apigee access token ready to be passed in headers.
        get: function () {
            return "Bearer " + JSON.parse(localStorage.getItem('proxyLayerObject'))['access_token'];
        },
        enumerable: true,
        configurable: true
    });
    OktaAuthService.prototype.signout = function () {
        var _this = this;
        return Observable.create(function (observer) {
            _this.authClient.signOut()
                .then(function () {
                observer.next(true);
                observer.complete();
            })
                .catch(function () {
                observer.next(false);
                observer.complete();
            });
        });
    };
    OktaAuthService.prototype.authorize = function (config) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.requestToken(config)
                .then(function (token) { return _this.requestTokenDetails(token.accessToken); })
                .then(function (request) { return request.subscribe(function (data) { return resolve(data); }, function () { return reject(); }); })
                .catch(function (err) { return reject(err); });
        });
    };
    return OktaAuthService;
}());
export { OktaAuthService };
