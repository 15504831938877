import * as tslib_1 from "tslib";
import { ApplicationRef } from '@angular/core';
import { get } from 'lodash';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { ActivatedRoute } from '@angular/router';
import { ErrorType } from '../../handlers/errors-handler';
import { StoreService } from '../../../services/index.js';
var ErrorsComponent = /** @class */ (function () {
    function ErrorsComponent(activatedRoute, storeService, appRef) {
        this.activatedRoute = activatedRoute;
        this.storeService = storeService;
        this.CONSTANTS = tslib_1.__assign({}, AppStrings['common'], { errors: AppStrings['errorsPage'] });
        this.reportError();
        setTimeout(function () { return appRef.tick(); });
    }
    Object.defineProperty(ErrorsComponent.prototype, "error", {
        get: function () {
            var type = this.activatedRoute.snapshot.queryParams.type;
            return get(this.CONSTANTS.errors, type, this.CONSTANTS.errors[ErrorType.Default]);
        },
        enumerable: true,
        configurable: true
    });
    ErrorsComponent.prototype.reportError = function () {
        var error = this.storeService.read('HttpErrorResponse');
        if (error) {
            console.error(error['message'] + " \n", error);
            this.storeService.delete('HttpErrorResponse');
        }
    };
    return ErrorsComponent;
}());
export { ErrorsComponent };
