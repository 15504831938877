import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { UserDetailsService } from '../../services/user-details/user-details.service';
import { AppConstantsService } from '../../../services/app-constants.service';
import { Router } from '@angular/router';
import { DrawerOutputState, DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { get } from 'lodash';
import { SessionManagementService } from '../../../services/session-management.service';
var NdaModalContentRemoveUserComponent = /** @class */ (function () {
    function NdaModalContentRemoveUserComponent(userService, appConstantsService, router, sessionManagementService, drawerComponentCommunicatorService) {
        this.userService = userService;
        this.appConstantsService = appConstantsService;
        this.router = router;
        this.sessionManagementService = sessionManagementService;
        this.drawerComponentCommunicatorService = drawerComponentCommunicatorService;
        this.CONSTANTS = tslib_1.__assign({}, AppStrings['removeUser'], AppStrings['common']);
    }
    NdaModalContentRemoveUserComponent.prototype.ngOnInit = function () {
    };
    NdaModalContentRemoveUserComponent.prototype.removeUser = function () {
        var _this = this;
        this.userService.removeUser().subscribe(function (response) {
            if (get(response, 'error.exceptions')) {
                _this.showErrorBanner();
            }
            else {
                if (_this.isBranch && _this.isAllyRole) {
                    _this.userService.callOnloadService = true;
                    _this.drawerComponentCommunicatorService.updateState(null);
                }
                else if (_this.isBranch) {
                    _this.router.navigateByUrl('/manage/users/search-users');
                }
                else {
                    _this.router.navigateByUrl('/manage/users/pending-requests');
                }
            }
        });
    };
    NdaModalContentRemoveUserComponent.prototype.showErrorBanner = function () {
        this.drawerComponentCommunicatorService.updateState(DrawerOutputState.processError);
    };
    Object.defineProperty(NdaModalContentRemoveUserComponent.prototype, "isAllyRole", {
        get: function () {
            return !!this.userService.allyRole;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentRemoveUserComponent.prototype, "isBranch", {
        get: function () {
            return this.sessionManagementService.isBranchUser;
        },
        enumerable: true,
        configurable: true
    });
    return NdaModalContentRemoveUserComponent;
}());
export { NdaModalContentRemoveUserComponent };
