import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { HttpErrorHandlerService, HandleError } from '../../services/http-error-handler.service';

import {Observable} from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';
import { IAdminUser } from '../../profile/models/admin-user.interface';


@Injectable()
export class AdminListService {

  private handleError;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  constructor(
    private http: HttpClient,
    public httpErrorHandler: HttpErrorHandlerService

  ) {
     this.handleError = httpErrorHandler.createHandleError('editProfileSubmit');
   }

  public getAdminList(pdn: number) : Observable<{} | IAdminUser[]> {

    let adminListUrl = `${environment.ndaServerURLS.workflowServices}/workflow/dealer/${pdn}/approver-users`;

    return this.http.get<IAdminUser[]>(adminListUrl,this.httpOptions)
      .pipe(
        catchError(this.handleError('adminListService', []))
      );

  }

}
