import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { flatMap, first, get } from 'lodash';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/do';
import { SpinnerService } from '../../../loading-spinner/services/spinner/spinner.service';
import { DealershipService } from '../../../registration/services/dealership/dealership.service';
import { ContactsService } from '../../services/contacts/contacts.service';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { ContactDataKeys } from '../../models/contacts-object';
import { SessionManagementService } from '../../../services/session-management.service';
import { AnalyticsService } from '../../../services';
var ContactsListComponent = /** @class */ (function () {
    function ContactsListComponent(contactsService, spinnerService, dealershipService, sessionManager, analytics) {
        this.contactsService = contactsService;
        this.spinnerService = spinnerService;
        this.dealershipService = dealershipService;
        this.sessionManager = sessionManager;
        this.analytics = analytics;
        this.CONSTANTS = tslib_1.__assign({}, AppStrings['common'], AppStrings['searchUsers'], AppStrings['myContacts']['contactDetail'], AppStrings['myContacts']['contactsList'], AppStrings['myContacts']['salesTeams'], { 'classifications': AppStrings['myContacts']['classifications'], 'roles': AppStrings['myContacts']['roles'] });
        this.filterKeys = ['QUICKLIST__SALES', 'QUICKLIST__UNDRW', '__'];
        this.accountExecKey = 'SALES_AUTO_FIN_PRI_ACC_EXE';
        this.underwriterRoles = ['PRIME', 'N_PRIME', 'COMM_SERV', 'ACQ_MGR'];
        this.salesLineKeys = ['AUTO_FIN', 'INS', 'REMRKT', 'WH_DL'];
        this.salesTeamKeys = ['PRI_ACC_EXE', 'PRI_TEAM', 'SUPP_TEAM', 'MGMT_TEAM'];
        this.salesLineTitlesFilter = {
            'Insurance': 'F&I and Dealer Insurance'
        };
        this.lineVisible = {};
        this.salesTeamNames = 'Auto Finance';
        this.hasLoaded = false;
        this.salesTeams = {};
        this.underwriters = {};
        this.generalContactsHash = {};
        this.generalVisible = {};
        this.isSubmitClicked = false;
        this.isNoDealershipFound = false;
    }
    Object.defineProperty(ContactsListComponent.prototype, "pageTitle", {
        get: function () {
            return this.CONSTANTS[this.isBranchUser ? 'searchContacts' : 'contact'];
        },
        enumerable: true,
        configurable: true
    });
    ContactsListComponent.prototype.contactsListSearchErrorMessage = function (search) {
        if (!!this.isSubmitClicked && !!this.isPdnEmpty) {
            return this.CONSTANTS.errors.pdnRequired;
        }
        if (!!this.isSubmitClicked && (!!search || !!this.isNoDealershipFound) && !this.isPdnEmpty) {
            return this.CONSTANTS.errors.pdnNotFound;
        }
        else {
            return "";
        }
    };
    ContactsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.salesLineKeys.forEach(function (key) { return _this.lineVisible[key] = false; });
        if (!this.sessionManager.profile.dealership) {
            this.emptyDynamicContacts();
            this.emptyGeneralContacts();
            return;
        }
        else {
            this.getContacts(this.sessionManager.profile.dealership.pdn);
        }
        setTimeout(function () { _this.analytics.trackPage({ title: 'ADOS:Contacts' }); });
    };
    ContactsListComponent.prototype.emptyDynamicContacts = function () {
        this.accountExec = null;
        this.underwriters = {};
        this.salesTeams = {};
    };
    ContactsListComponent.prototype.emptyGeneralContacts = function () {
        this.generalContacts = [];
        this.generalContactsHash = {};
    };
    ContactsListComponent.prototype.parseContacts = function (contacts) {
        var _this = this;
        this.contacts = contacts[ContactDataKeys.mainContactsObject];
        if (Object.keys(this.contacts[ContactDataKeys.dynamicContacts]).length !== 0) {
            this.accountExec = this.contacts[ContactDataKeys.dynamicContacts][this.accountExecKey][0];
            var contactsArray = Object.keys(this.contacts[ContactDataKeys.dynamicContacts])
                .map(function (key) { return [key, _this.contacts[ContactDataKeys.dynamicContacts][key]]; })
                .filter(function (tuple) { return !(_this.filterKeys.some(function (value) { return value === tuple[0]; })); })
                .map(function (tuple) { return tuple[1]; });
            var flatContacts_1 = flatMap(contactsArray);
            this.underwriterRoles.forEach(function (role) {
                _this.underwriters[role] = flatContacts_1.filter(function (contact) { return contact.role === role; })[0];
            });
            this.salesLineKeys.forEach(function (key) {
                var team = {
                    name: key
                };
                var teamMembers = flatContacts_1.filter(function (contact) { return contact.busLine === key; });
                teamMembers.forEach(function (member) {
                    if (typeof team[member.group] === 'undefined') {
                        team[member.group] = [];
                    }
                    team[member.group].push(member);
                });
                _this.salesTeams[key] = team;
            });
        }
        else {
            this.emptyDynamicContacts();
        }
        var generalContactsData = this.contacts[ContactDataKeys.generalContacts];
        if (generalContactsData && generalContactsData.length !== 0) {
            this.generalContacts = generalContactsData.generalContactList
                .sort(function (contact1, contact2) { return contact1.title.localeCompare(contact2.title); });
            this.generalContacts.forEach(function (contact) {
                _this.generalContactsHash[contact.title] = contact;
            });
        }
        else {
            this.emptyGeneralContacts();
        }
    };
    ContactsListComponent.prototype.toggleLine = function (line) {
        this.lineVisible[this.visibleTeam] = false;
        if (this.visibleTeam !== line) {
            this.lineVisible[line] = true;
            this.visibleTeam = line;
        }
        else {
            this.visibleTeam = '';
        }
    };
    ContactsListComponent.prototype.toggleGC = function (gc) {
        this.generalVisible[this.visibleGC] = false;
        if (this.visibleGC !== gc) {
            this.generalVisible[gc] = true;
            this.visibleGC = gc;
        }
        else {
            this.visibleGC = '';
        }
    };
    ContactsListComponent.prototype.lineCaretType = function (line) {
        return this.lineVisible[line] ? 'allycon-glyph-caret-down' : 'allycon-glyph-caret-right';
    };
    ContactsListComponent.prototype.generalCaretType = function (title) {
        return this.generalVisible[title] ? 'allycon-glyph-caret-down' : 'allycon-glyph-caret-right';
    };
    Object.defineProperty(ContactsListComponent.prototype, "isPdnEmpty", {
        get: function () {
            return !this.query || this.query.length < 1;
        },
        enumerable: true,
        configurable: true
    });
    ContactsListComponent.prototype.searchByPDN = function (pdn, valid) {
        var _this = this;
        this.isSubmitClicked = true;
        if (!valid) {
            return;
        }
        this.spinnerService.show();
        this.dealershipService.findByPdn(pdn)
            .do(function () { return _this.isSubmitClicked = false; })
            .finally(function () { return _this.spinnerService.hide(); })
            .subscribe(function (dealerships) {
            if (dealerships.length) {
                _this.dealership = first(dealerships);
                _this.getContacts(pdn);
            }
            _this.isNoDealershipFound = !dealerships.length;
        });
    };
    ContactsListComponent.prototype.getContacts = function (pdn) {
        var _this = this;
        this.spinnerService.show();
        this.contactsService.getContacts(pdn)
            .do(function () { return _this.hasLoaded = true; })
            .finally(function () { return _this.spinnerService.hide(); })
            .subscribe(function (contacts) { return _this.parseContacts(contacts); });
    };
    Object.defineProperty(ContactsListComponent.prototype, "salesLinesEmpty", {
        get: function () {
            return Object.keys(this.salesTeams).length !== 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactsListComponent.prototype, "underwritersEmpty", {
        get: function () {
            return Object.keys(this.underwriters).length !== 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactsListComponent.prototype, "primaryTeamEmpty", {
        get: function () {
            return this.underwriters[this.underwriterRoles[0]]
                || this.underwriters[this.underwriterRoles[1]]
                || this.underwriters[this.underwriterRoles[2]];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactsListComponent.prototype, "filteredSalesLineKeys", {
        get: function () {
            var _this = this;
            return this.salesLineKeys.filter(function (key) { return Object.keys(_this.salesTeams[key]).length > 1; });
        },
        enumerable: true,
        configurable: true
    });
    ContactsListComponent.prototype.salesLineEmpty = function (line) {
        return Object.keys(this.salesTeams[line]).length < 2;
    };
    ContactsListComponent.prototype.getMappedData = function (key, data) {
        var mappedData = this.getData(key, data);
        return get(AppStrings, "myContacts.mappings." + mappedData, mappedData);
    };
    ContactsListComponent.prototype.getData = function (key, data) {
        return this.generalContactsHash[key][data];
    };
    Object.defineProperty(ContactsListComponent.prototype, "isBranchUser", {
        get: function () {
            return this.sessionManager.isBranchUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactsListComponent.prototype, "isDealershipUser", {
        get: function () {
            return this.sessionManager.isDealershipUser;
        },
        enumerable: true,
        configurable: true
    });
    ContactsListComponent.prototype.tel = function (phoneNumber) {
        return "tel:" + phoneNumber;
    };
    ContactsListComponent.prototype.mailto = function (email) {
        return "mailto:" + email;
    };
    return ContactsListComponent;
}());
export { ContactsListComponent };
