var SpinnerService = /** @class */ (function () {
    function SpinnerService() {
        this._isVisible = false;
    }
    Object.defineProperty(SpinnerService.prototype, "isVisible", {
        get: function () {
            return this._isVisible;
        },
        set: function (isVisible) {
            this._isVisible = isVisible;
        },
        enumerable: true,
        configurable: true
    });
    SpinnerService.prototype.show = function () {
        this.isVisible = true;
    };
    SpinnerService.prototype.hide = function () {
        this.isVisible = false;
    };
    return SpinnerService;
}());
export { SpinnerService };
