import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs/Subject';
import { AppStrings } from '../../../assets/app-strings/app-strings';

@Component({
  selector: 'app-nda-warning-modal',
  templateUrl: './nda-warning-modal.component.html',
  styleUrls: ['./nda-warning-modal.component.scss']
})
export class NdaWarningModalComponent {

  public didConfirm$: Subject<boolean> = new Subject();
  public didDecline$: Subject<boolean> = new Subject();

  private modalRef: BsModalRef;

  readonly constants = {
    ...AppStrings['warningModal']
  }

  public confirm() {
    this.didConfirm$.next(true);
    this.didConfirm$.complete();
  }

  public decline() {
    this.didDecline$.next(false);
    this.didDecline$.complete();
  }

  constructor() { }

  get modalQuestion() {
    return this.constants.question;
  }

  get modalDetailMessage() {
    return this.constants.detailMessage;
  }

}
