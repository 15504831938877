import { get, cloneDeep, isUndefined } from 'lodash';
var PreLoginUserDetailsService = /** @class */ (function () {
    function PreLoginUserDetailsService() {
    }
    Object.defineProperty(PreLoginUserDetailsService.prototype, "profile", {
        get: function () {
            return cloneDeep(this.userProfile);
        },
        set: function (profile) {
            this.userProfile = profile;
            this.userProfile.userName = profile.login;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreLoginUserDetailsService.prototype, "hasProfile", {
        get: function () {
            return !isUndefined(this.userProfile);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreLoginUserDetailsService.prototype, "sessionDetails", {
        get: function () {
            return {
                userName: get(this.profile, 'userName', ''),
                userType: get(this.profile, 'userType', ''),
                userId: get(this.profile, 'userId', '')
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreLoginUserDetailsService.prototype, "userName", {
        get: function () {
            return get(this.profile, 'userName', '');
        },
        enumerable: true,
        configurable: true
    });
    return PreLoginUserDetailsService;
}());
export { PreLoginUserDetailsService };
