import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
var PasswordComplexityComponent = /** @class */ (function () {
    function PasswordComplexityComponent() {
        this.CONSTANTS = tslib_1.__assign({}, AppStrings['registration']['passwordComplexity']);
        this.password = '';
    }
    PasswordComplexityComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(PasswordComplexityComponent.prototype, "isValidNumberOfCharacters", {
        get: function () {
            return this.password.length >= 8 && this.password.length <= 20;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PasswordComplexityComponent.prototype, "hasUppercaseCharacters", {
        get: function () {
            return /(?=.*[A-Z])/.test(this.password);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PasswordComplexityComponent.prototype, "hasLowercaseCharacters", {
        get: function () {
            return /(?=.*[a-z])/.test(this.password);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PasswordComplexityComponent.prototype, "hasNumber", {
        get: function () {
            return /(?=.*\d)/.test(this.password);
        },
        enumerable: true,
        configurable: true
    });
    return PasswordComplexityComponent;
}());
export { PasswordComplexityComponent };
