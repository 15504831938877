/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./loading-spinner.component";
import * as i3 from "../../services/spinner/spinner.service";
var styles_LoadingSpinnerComponent = [i0.styles];
var RenderType_LoadingSpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingSpinnerComponent, data: {} });
export { RenderType_LoadingSpinnerComponent as RenderType_LoadingSpinnerComponent };
export function View_LoadingSpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-backdrop fade in"]], [[2, "show", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "We're loading your information."], ["src", "assets/images/Spinner_White.svg"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVisible; _ck(_v, 0, 0, currVal_0); }); }
export function View_LoadingSpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nda-loading-spinner", [], null, null, null, View_LoadingSpinnerComponent_0, RenderType_LoadingSpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i2.LoadingSpinnerComponent, [i3.SpinnerService], null, null)], null, null); }
var LoadingSpinnerComponentNgFactory = i1.ɵccf("nda-loading-spinner", i2.LoadingSpinnerComponent, View_LoadingSpinnerComponent_Host_0, {}, {}, []);
export { LoadingSpinnerComponentNgFactory as LoadingSpinnerComponentNgFactory };
