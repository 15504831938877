var NavigatorDetails = /** @class */ (function () {
    function NavigatorDetails() {
    }
    Object.defineProperty(NavigatorDetails, "isOnline", {
        get: function () {
            return navigator.onLine;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigatorDetails, "isOffline", {
        get: function () {
            return !NavigatorDetails.isOnline;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigatorDetails, "agent", {
        get: function () {
            return navigator.userAgent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigatorDetails, "versionIE", {
        get: function () {
            var agent = NavigatorDetails.agent;
            var msie = agent.indexOf('MSIE ');
            if (msie > 0) {
                // IE 10 or older => return version number
                return parseInt(agent.substring(msie + 5, agent.indexOf('.', msie)), 10);
            }
            var trident = agent.indexOf('Trident/');
            if (trident > 0) {
                // IE 11 => return version number
                var rv = agent.indexOf('rv:');
                return parseInt(agent.substring(rv + 3, agent.indexOf('.', rv)), 10);
            }
            var edge = agent.indexOf('Edge/');
            if (edge > 0) {
                // Edge (IE 12+) => return version number
                return parseInt(agent.substring(edge + 5, agent.indexOf('.', edge)), 10);
            }
            // other browser
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigatorDetails, "clientBrowser", {
        get: function () {
            var ieVersion = NavigatorDetails.versionIE;
            if (ieVersion && ieVersion < 12)
                return "ie-" + ieVersion;
            else if (ieVersion && ieVersion >= 12)
                return "edge-" + ieVersion;
            else if (this.agent.indexOf('Opera') != -1)
                return 'opera';
            else if (this.agent.indexOf('Chrome') != -1)
                return 'chrome';
            else if (this.agent.indexOf('Safari') != -1)
                return 'safari';
            else if (this.agent.indexOf('Firefox') != -1)
                return 'firefox';
            else
                return navigator.appName;
        },
        enumerable: true,
        configurable: true
    });
    return NavigatorDetails;
}());
export { NavigatorDetails };
