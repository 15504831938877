import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpHeaderResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import {ApiGatewayHeader} from '../definitions/api-gateway-header.namespace';
import {Injectable} from "@angular/core";
import { OktaAuthService } from "../../services/okta-auth/okta-auth.service";

@Injectable()
export class ApiGatewayInterceptor implements HttpInterceptor {

  private static Token: string;

  constructor(
    private oktaAuthService: OktaAuthService
  ) {}

  static clearToken(): void {
    ApiGatewayInterceptor.Token = undefined;
  }

  setGatewayHeader(gatewayHeader: string): void {
    sessionStorage.setItem('gateway-header', gatewayHeader);
    ApiGatewayInterceptor.Token = gatewayHeader;
  }

  get gatewayHeader(): string {
    if (ApiGatewayInterceptor.Token === undefined) {
      ApiGatewayInterceptor.Token = sessionStorage.getItem('gateway-header');
    }
    return ApiGatewayInterceptor.Token;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isToken = request.url.includes('token');
    const isOtp = request.url.includes('send-otp') || request.url.includes('verify-otp');
    const isAuthn = request.url.includes('authn') && !isOtp;

    const isAuthorizeUser = request.url.includes('authorize-user');
    const isPublic = request.url.includes('public');
    if (!isToken && !isAuthn) {
        let isAuthorize = !!this.oktaAuthService.getApigeeJwt() && !isAuthorizeUser && !isPublic && !isOtp;
        request = request.clone({
          withCredentials: true,
          setHeaders: {
            ...(!!this.gatewayHeader && {'gateway-header': this.gatewayHeader}),
            ...(isAuthorize && {'Authorization':this.oktaAuthService.apigeeAccessToken})

          }
      });
    }

    return next.handle(request)
      .do((event: HttpEvent<any>) => this.handleResponse(event));
  }

  protected handleResponse(event: HttpEvent<any>): void {
    if (event instanceof HttpResponse) {
      const header = event.headers.get(ApiGatewayHeader.token);
      if (header) {
        this.setGatewayHeader(header);
      }
    }
  }
}
