import { enableProdMode, ApplicationRef } from '@angular/core';
import { environment } from './environments/environment';
import { HttpClient } from '@angular/common/http';
import { SessionManagementService } from './app/services/session-management.service';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
var script = document.createElement('script');
script.setAttribute('async', 'async');
script.setAttribute('src', environment.adobeLaunchSdk);
document.head.appendChild(script);
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .then(function (AppModule) {
    var applicationRef = AppModule.injector.get(ApplicationRef);
    var appComponent = applicationRef.components[0];
    var NdaApplicationContextFacade = /** @class */ (function () {
        function NdaApplicationContextFacade() {
        }
        Object.defineProperty(NdaApplicationContextFacade.prototype, "hasSession", {
            get: function () {
                var sessionManager = appComponent.injector.get(SessionManagementService);
                return sessionManager.hasSession;
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(NdaApplicationContextFacade.prototype, "http", {
            get: function () {
                var httpClient = appComponent.injector.get(HttpClient);
                return httpClient;
            },
            enumerable: true,
            configurable: true
        });
        return NdaApplicationContextFacade;
    }());
    window['NDA_Application_Context'] = new NdaApplicationContextFacade();
})
    .catch(function (err) { return console.log(err); });
