import { Injectable } from '@angular/core';

export type DrawerOutputState = keyof {
  Error,
  Success,
  Warning,
}
export namespace DrawerOutputState {
  export const processError: DrawerOutputState = 'Error';
  export const processWarning: DrawerOutputState = 'Warning';
  export const processSuccess: DrawerOutputState = 'Success';
}

@Injectable()
export class DrawerComponentCommunicatorService {

    // Common drawer component 
    public drawerState: boolean = false;
    public drawerComponentLoaded: boolean = false;
    public drawerComponentDestroyed: boolean = false;
    public callOnloadService: boolean = false;
    public callOnloadServiceOnClose: boolean = false;
    public readonly editPersonalInfoIdentifier: string = "editPersonalInfo";
    public readonly removeUserIdentifier: string = "removeUser";
    public readonly addAppsIdentifier: string = "addApps";
    public readonly removeAppsIdentifier: string = "removeApps";
    public readonly dealerAddAppsIdentifier: string = "dealerAdminAddApps";
    public readonly dealerAddAssocAppsIdentifier: string = "dealerAddAssocApps";    
    public readonly removeAppsReportIdentifier: string = "removeAppsReport";    
    public readonly createPasswordIdentifier: string = "createPassword";    
    public drawerComponentOutputState: string;
    public drawerComponentIdentifier: string;
    public isFormChanged: boolean = false;
    public displayLeaveChangesModal: boolean = false;

  constructor() { }

  public updateState(state: DrawerOutputState): void {
    this.drawerState = this.drawerComponentDestroyed = this.drawerComponentLoaded = false;
    this.drawerComponentOutputState = state;
  }

}
