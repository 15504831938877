import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import {Observable} from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';

import { IChangePassword } from '../../models/change-password.interface';
import { SessionManagementService } from '../session-management.service';
import { StoreService } from '../store.service';


@Injectable()
export class UserService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    private sessionManager: SessionManagementService
    ) { }

  public changePassword( changePasswordModel: IChangePassword, userId: string ) {
    const url = `${environment.ndaServerURLS.userServices}${this.sessionManager.hasSession ? '' : '/public'}/user/${userId}/password`;
    return this.http.put(url, changePasswordModel, this.httpOptions);
  }
  // Get table information from allyuser-properties endpoint
  public getAllyUserRolesProperties() {
    let action = `allyuser-properties?roleName=Ally User`;
    const url = `${environment.ndaServerURLS.workflowServices}/workflow/${action}`;
    return this.http.get<any[]>(url, this.httpOptions);
  }

  public getHelpDeskRolesProperties() {
    let action = `allyuser-properties?roleName=Help Desk`;
    const url = `${environment.ndaServerURLS.workflowServices}/workflow/${action}`;
    return this.http.get<any[]>(url, this.httpOptions);
  }

  public getAllyAdminRolesProperties() {
    let action = `allyuser-properties?roleName=Ally Admin`;
    const url = `${environment.ndaServerURLS.workflowServices}/workflow/${action}`;
    return this.http.get<any[]>(url, this.httpOptions);
  }
}
