import { Observable } from "rxjs";
import { OnInit, ElementRef } from "@angular/core";
import { NdaChatService } from "../../services/chat/nda-chat.service";
import { Router, NavigationEnd } from "@angular/router";
import "rxjs/add/operator/catch";
import { SessionManagementService } from "../../services/session-management.service";
var NdaChatComponent = /** @class */ (function () {
    function NdaChatComponent(chatService, router, sessionManager) {
        this.chatService = chatService;
        this.router = router;
        this.sessionManager = sessionManager;
    }
    Object.defineProperty(NdaChatComponent.prototype, "isBranchUser", {
        get: function () {
            return this.sessionManager.isBranchUser;
        },
        enumerable: true,
        configurable: true
    });
    NdaChatComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authenticate()
            .catch(function () { return Observable.of(''); })
            .subscribe(function () { return _this.initPlatform(); });
    };
    NdaChatComponent.prototype.initPlatform = function () {
        this.chatService.loadScript();
        this.newPage(this.router.url);
        if (!this.isBranchUser) {
            this.subscribeToRoute();
            this.setupDelegateFunctions();
        }
    };
    NdaChatComponent.prototype.setupDelegateFunctions = function () {
        var _this = this;
        window["lpGetAuthenticationToken"] = function (delegate) { return delegate(_this.userAuthToken.trim()); };
    };
    NdaChatComponent.prototype.authenticate = function () {
        var _this = this;
        return this.chatService.authenticate()
            .map(function (jwt) { return _this.userAuthToken = jwt || ''; });
    };
    NdaChatComponent.prototype.subscribeToRoute = function () {
        var _this = this;
        this.router.events.subscribe(function (route) {
            if (route instanceof NavigationEnd) {
                _this.newPage(route.url);
            }
        });
    };
    NdaChatComponent.prototype.newPage = function (url) {
        this.chatService.newPage(url);
    };
    return NdaChatComponent;
}());
export { NdaChatComponent };
