import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import {Injectable} from "@angular/core";
import { OktaAuthService } from "../services/okta-auth/okta-auth.service";
import {throwError , of} from "rxjs/index";
import {catchError, concatMap, delay, retryWhen, switchMap} from "rxjs/internal/operators";
import {environment} from "@nda/environments/environment";

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {
  private retries: number = 0;
  private environment = environment;
  constructor(
    private oktaAuthService: OktaAuthService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse)=> {
        const isWorkFLow = error.url.includes('?filter=all');
        const isAuth = error.url.includes(environment.apigeeToken.baseUrl) ;

        if(isAuth && error.status !== 200 && this.retries === 0){
          ++this.retries;
          localStorage.removeItem('proxyLayerObject');
          this.oktaAuthService.ApigeeRedirect()
        }

        if (isWorkFLow && error.status === 401) {
          localStorage.removeItem('proxyLayerObject');
          this.oktaAuthService.getApigeeToken().then((res) => {
            let authObj = JSON.parse(localStorage.getItem('proxyLayerObject'));
            const requestWithNewToken = req.clone({
                setHeaders:{
                  // Authorization: `Bearer ${res['access_token'].split('').reverse().join('')}`
                  Authorization: `Bearer ${authObj['access_token']}`
                }
              });
              return next.handle(requestWithNewToken);
          },(error)=>{
              return throwError(error)
          })

        }
        return throwError(error)
      })
    );
  }
}
