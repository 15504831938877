var _this = this;
import * as tslib_1 from "tslib";
/*  This function generates the PKCE code challenge  */
export var challenge_from_verifier = function (v) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    var hashed, base64encoded;
    return tslib_1.__generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, sha256(v)];
            case 1:
                hashed = _a.sent();
                base64encoded = base64urlencode(hashed);
                return [2 /*return*/, base64encoded];
        }
    });
}); };
/*  This function generates the PKCE code verifier  */
export var generateCodeVerifier = function () {
    var array = new Uint32Array(56 / 2);
    var crypto = window.crypto;
    crypto.getRandomValues(array);
    return Array.from(array, dec2hex).join('');
};
/*  This function generates both the PKCE code challenge */
export var generatePKCECodes = function () {
    var verifier = generateCodeVerifier();
    sessionStorage.setItem('verifier', verifier);
    var challenge = challenge_from_verifier(verifier);
    return challenge;
};
//############ Helper functions for PKCE code generation #################
export var base64urlencode = function (a) {
    var str = "";
    var bytes = new Uint8Array(a);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
    }
    return btoa(str)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
};
export var generateRandomString = function () {
    var array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec2hex).join('');
};
export var dec2hex = function (dec) {
    return ("0" + dec.toString(16)).substring(-2);
};
export var sha256 = function (plain) {
    var encoder = new TextEncoder();
    var data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
};
