import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ContactsRoutingModule } from './contacts-routing.module';

import { ContactsService } from './services/contacts/contacts.service';

import { ContactsListComponent } from './components/contacts-list/contacts-list.component';
import { ContactDetailComponent } from './components/contact-detail/contact-detail.component';
import { ContactsFlyoutComponent } from './components/contacts-flyout/contacts-flyout.component';
import { ContactDetailFlyoutComponent } from './components/contact-detail-flyout/contact-detail-flyout.component';
import { DealershipService } from '../registration/services/dealership/dealership.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ContactsRoutingModule
  ],
  providers: [
    ContactsService,
    DealershipService
  ],
  declarations: [
    ContactsListComponent,
    ContactDetailComponent,
    ContactsFlyoutComponent,
    ContactDetailFlyoutComponent
  ],
  entryComponents: [
    ContactsFlyoutComponent,
  ],
  exports: [
    ContactsFlyoutComponent,
  ]
})
export class ContactsModule { }
