/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-session-timeout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal-session-timeout.component";
var styles_ModalSessionTimeoutComponent = [i0.styles];
var RenderType_ModalSessionTimeoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalSessionTimeoutComponent, data: {} });
export { RenderType_ModalSessionTimeoutComponent as RenderType_ModalSessionTimeoutComponent };
export function View_ModalSessionTimeoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["bsModal", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "ally-primary-heading pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "modal-body pt-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "text-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-primary font-weight-bold"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshSession() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-link accessibility-button-group"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.CONSTANTS.header; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.CONSTANTS.continueLabel; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.CONSTANTS.signoutLabel; _ck(_v, 11, 0, currVal_3); }); }
export function View_ModalSessionTimeoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "session-timeout", [], null, null, null, View_ModalSessionTimeoutComponent_0, RenderType_ModalSessionTimeoutComponent)), i1.ɵdid(1, 49152, null, 0, i2.ModalSessionTimeoutComponent, [], null, null)], null, null); }
var ModalSessionTimeoutComponentNgFactory = i1.ɵccf("session-timeout", i2.ModalSessionTimeoutComponent, View_ModalSessionTimeoutComponent_Host_0, {}, {}, []);
export { ModalSessionTimeoutComponentNgFactory as ModalSessionTimeoutComponentNgFactory };
