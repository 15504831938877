<div bsModal>
  <div class="modal-header">
    <h3 class="ally-primary-heading pull-left"> {{ CONSTANTS.header }} </h3>
  </div>

  <div class="modal-body pt-0">
    <p class="text-bold">{{ message }}</p>

    <div class="mt-4">
      <button (click)="refreshSession()" class="btn btn-primary font-weight-bold">{{ CONSTANTS.continueLabel }}</button>
      <button (click)="logOut()" class="btn btn-link accessibility-button-group">{{ CONSTANTS.signoutLabel }}</button>
    </div>
  </div>
</div>
