import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { IState } from '../../../services/us-states/definitions';
import { IDealership } from '../../../registration/models/dealership.interface';
import { UsStatesService } from '../../../services';
import { DealershipService } from '../../../registration/services/dealership/dealership.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/finally';
import * as _ from 'lodash';
import { NdaModalChooseAppAndReportsComponent } from '../nda-modal-choose-app-and-reports/nda-modal-choose-app-and-reports.component';
import { AddAppsService } from '../../services/add-apps.service';
import { DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { IUserDetails } from '../../../manage-users/models/user-details.interface';
import { IUserGroupsList } from '../../../manage-users/models/user-groups-list.interface';
import { ProfileAllyRole } from '../../../models/profile.interface';
import { SessionManagementService } from '../../../services/session-management.service';

export type SearchCriteriaViewSegment = keyof {
  pdn,
  dealershipName
};

export namespace SearchCriteriaViewSegment {
  export const pdn: SearchCriteriaViewSegment = "pdn";
  export const dealershipName: SearchCriteriaViewSegment = "dealershipName";
}

@Component({
  selector: 'nda-add-dealership',
  templateUrl: './nda-add-dealership.component.html',
  styleUrls: ['./nda-add-dealership.component.scss']
})
export class NdaAddDealershipComponent implements OnInit {

  @ViewChild('ngForm', { static: true })
  public ngForm;

  @Input('data')
  data: any;

  @ViewChild('addDealershipFormSubmit', { static: false })
  public addDealershipFormSubmit;

  @ViewChild('chooseAppsAndReports', { static: false  }) chooseAppsAndReports : NdaModalChooseAppAndReportsComponent;

  @Output() pdnListVisible = new EventEmitter<string>();

  private hasPendingRequest: boolean = false;

  public searchByPdn: SearchCriteriaViewSegment = SearchCriteriaViewSegment.pdn;
  public searchByDealershipName: SearchCriteriaViewSegment = SearchCriteriaViewSegment.dealershipName;

  public dealerships: IDealership[];
  public statesList: IState[];
  public dealershipForm: FormGroup;
  public searchBy: FormControl;
  public pdn: FormControl;
  public dealershipName: FormControl;
  public selectedState: FormControl;
  public dealershipPdn: FormControl;
  public didAttemptContinue: boolean;
  public didPromptDealershipSelection: boolean;
  public drawerState: boolean = false;
  public addDealershipForm: FormGroup;
  public currentPage: number;
  public itemsPerPage: number = 5;
  public maxSize = 5;
  public isNoSelection: boolean;
  public selectedDealershipInfo : Array<string>;
  public isAddDealerByAdmin : boolean;
  public profileObj: IUserDetails;
  public profile : IUserDetails;
  public associatedDealers: string[];
  public isSearchByExistingPdnError : boolean;
  public isRequestorAnAdmin : boolean;
  public selfGroups : IUserGroupsList[];
  public spinner = '<img class="nda-spinner" src="assets/images/Spinner_White.svg" />';
  public totalPages: number = 1 ;

  private readonly patternPdn = '^[0-9]*$';
  private readonly patternDealershipName = "^[a-zA-Z0-9 ']*$";

  public readonly CONSTANTS = {
    ...AppStrings['common'],
    ...AppStrings['searchUsers'],
    ...AppStrings['registration'],
    ...AppStrings['registration']['dealership']['step2'],
    ...AppStrings['associatedDealership']
  };

  constructor(
    private statesService: UsStatesService,
    private sessionService : SessionManagementService,
    private ref: ChangeDetectorRef,
    private dealershipService: DealershipService,
    private addAppsService : AddAppsService,
    private drawerComponentCommunicatorService : DrawerComponentCommunicatorService,
  ) {

   }

  ngOnInit() {
    this.handleStartOver();
    this.isAddDealerByAdmin = this.data.isAddDealerByAdmin;
    this.profileObj = this.data['profileObj'];
    this.associatedDealers = this.data['associatedDealers'];
    this.profile = this.data.profile;
    this.selfGroups = this.data.selfGroups;
    this.statesList = this.statesService.getStates();
  }

  public get isFormChanged(): boolean {
    this.drawerComponentCommunicatorService.isFormChanged = this.hasDealerships;
    return true;
  }

  public handleStartOver(): void {
    this.dealerships = [];
    this.isNoSelection = false;
    this.didAttemptContinue = false;
    this.didPromptDealershipSelection = false;
    this.isSearchByExistingPdnError = false;
    this.currentPage = 1;

    this.buildForm();
    this.ngForm.submitted = false;
    this.ref.detectChanges();
  }

  public handleStateStartOver(): void{
    this.dealerships = [];
    this.isNoSelection = false;
    this.currentPage = 1;
    this.isSearchByExistingPdnError = false;
    this.ngForm.submitted = false;
    this.didAttemptContinue = false;
  }

  public openDrawer(): boolean {
    this.handleStartOver();
    this.searchBy.setValue(SearchCriteriaViewSegment.pdn);
    return this.drawerState = true;
  }

  public showWarningModal() {
    this.pdnListVisible.emit('Warning');
  }

  public closeDrawer(): boolean {
    if ( this.hasDealerships ) {
      this.showWarningModal();
      this.drawerState = true;
    } else {
      this.ngForm.reset();
      this.drawerState = false;
    }
    return this.drawerState;
  }

  public get dealershipsTotal(): number {
    return this.dealerships.length;
  }

  public handlePageChange(event: any): void {
      document.getElementsByClassName('pagination-page page-item active')[0].setAttribute('aria-current','page');
      let node: any = (<HTMLScriptElement[]><any>document.getElementsByClassName("result-progress"))[0];
      node.focus({preventScrolling:false});

  }

  public get addDealerByPdnErrorMessage(){
    if (!!this.didAttemptContinue && !this.hasDealerships && !this.isPdnInvalid){
      return this.CONSTANTS.errorMessages.pdn.notFound;
    }
    if(!!this.isPdnInvalid && !!this.pdn.errors.required){
      return this.CONSTANTS.errorMessages.pdn.required;
    }
    if (!!this.isPdnInvalid && (!!this.pdn.errors.pattern || !!this.pdn.errors.minlength)){
      return this.CONSTANTS.errorMessages.pdn.minlength;
    }else{return "";}
  }

  public get addDealerByNameErrorMessage(){
    if (!!this.didAttemptContinue && !this.hasDealerships && !this.isDealershipNameInvalid){
      return this.CONSTANTS.errorMessages.dealershipName.notFound;
    }
    if(!!this.isDealershipNameInvalid && !!this.dealershipName.errors.required){
      return this.CONSTANTS.errorMessages.dealershipName.required;
    }
    if (!!this.isDealershipNameInvalid && !!this.dealershipName.errors.pattern){
      return this.CONSTANTS.errorMessages.dealershipName.pattern;
    }
    if (!!this.isDealershipNameInvalid && !!this.dealershipName.errors.minlength){
      return this.CONSTANTS.errorMessages.dealershipName.minlength;
    }else{return "";}
  }

  public get selectStateErrorMessage(){
    if (!!this.isSelectedStateInvalid && !!this.selectedState.errors.required){
      return this.CONSTANTS.errorMessages.selectedState.required;
    }else{return "";}
  }

  public get hasDealerships(): boolean {
    return this.dealershipsTotal > 0;
  }

  public get hasSingleDealership(): boolean {
    return this.dealershipsTotal == 1;
  }

  public get isSearchByPdn(): boolean {
    return this.searchBy.value === SearchCriteriaViewSegment.pdn;
  }

  public get isSearchByDealershipName(): boolean {
    return this.searchBy.value === SearchCriteriaViewSegment.dealershipName;
  }

  public get isStepValid(): boolean {
    if (this.isSearchByPdn) {
      return this.pdn.valid;
    }
    else if (this.isSearchByDealershipName) {
      return this.dealershipName.valid && this.selectedState.valid;
    }
    return false;
  }

  public buildForm(): void {
    this.didAttemptContinue = false;
    this.searchBy      = this.searchBy || new FormControl(this.searchByPdn);
    this.selectedState = new FormControl('', Validators.required);
    this.dealershipPdn = new FormControl('', Validators.required);

    this.pdn = new FormControl('', Validators.compose([
      Validators.required,
      Validators.pattern(this.patternPdn),
      Validators.minLength(5)
    ]));

    this.dealershipName = new FormControl('', Validators.compose([
      Validators.required,
      Validators.pattern(this.patternDealershipName),
      Validators.minLength(3),
      Validators.maxLength(50)
    ]));

    this.addDealershipForm = new FormGroup({
      searchBy:       this.searchBy,
      dealershipName: this.dealershipName,
      pdn:            this.pdn,
      selectedState:  this.selectedState,
      dealershipPdn:  this.dealershipPdn,
    });
  }

  public handleSearch(): void {
    this.isSearchByExistingPdnError = false;
    if (this.isStepValid && !this.didAttemptContinue) {
      if (this.hasPendingRequest) {
        return;
      }
      else if(this.isSearchByPdn && _.includes(this.associatedDealers,this.pdn.value)) {
        this.isSearchByExistingPdnError = true;
        return;
      }

      this.hasPendingRequest = true;

      this.searchDealerships()
      .finally(() => this.hasPendingRequest = false)
      .subscribe(dealerships => {
        this.dealerships = dealerships;
        this.didAttemptContinue = true;

        if (this.hasSingleDealership) {
          if (_.includes(this.associatedDealers,_.first<IDealership>(this.dealerships).pdnNumber)) {
            this.isSearchByExistingPdnError = true;
            return;
          }
          this.dealershipPdn.setValue(_.first<IDealership>(this.dealerships).pdnNumber);
          this.getDealersInfo(dealerships[0]);
        }
      });
    }
    else if (this.isStepValid && this.didAttemptContinue) {
      this.didPromptDealershipSelection = true;
      this.isNoSelection = false;
      this.addAppsService.pdn = this.dealershipPdn.value;
      this.isDealershipSelected ? this.showChooseAppsAndReportsModal() : (this.isNoSelection = true);
    }
  }

  public resetIsSearchByExistingPdn() {
    this.dealerships = [];
    this.isNoSelection = false;
    this.currentPage = 1;
    this.ngForm.submitted = false;
    this.didAttemptContinue = false;
    this.isSearchByExistingPdnError = false;
  }

  public isPdnChosen(pdn : string) : string {
    if (this.isSearchByDealershipName && _.includes(this.associatedDealers,pdn)) {
      return "disabled";
    } else {
      return null;
    }
  }

  public get isDealershipSelected(): boolean {
    return this.dealershipPdn.value && (this.dealershipPdn.value != (null || ''));
  }

  public get resultsDisplayText(): string {
    const isPlural = this.dealerships.length > 1 || !this.dealerships.length,
          tmplName = isPlural ? 'resultPlural' : 'resultSingular';
    let tmpl = _.template(this.CONSTANTS[tmplName])({ n: this.dealerships.length });

    if (this.isSearchByPdn) {
      tmpl += ' ';
      tmpl += _.template(this.CONSTANTS['resultForPdn'])({ pdn: this.pdn.value });
    }
    return tmpl;
  }

  public showChooseAppsAndReportsModal(): void {
    if ( this.dealershipsTotal ) {
      this.addAppsService.profileObj = this.profileObj || this.profile;
      this.addAppsService.isChooseAppsAndReports = true;
      this.addAppsService.isRequestorAnAdmin = this.isAllyAdmin || this.checkIsLoggedInUserAdminForThisPdn(this.addAppsService.pdn);
      this.addAppsService.selectedDealersInfo = this.selectedDealershipInfo;
    }
  }

  private get isAllyAdmin(): boolean {
    return _.get(this.sessionService, 'profile.allyRole') === ProfileAllyRole.admin;
  }

  /**
   * checkIsLoggedInUserAdminForThisPdn - function to check is logged in user admin for the given PDN
   *
   * @param pdn
   */
  public checkIsLoggedInUserAdminForThisPdn(pdn: string): boolean {
    if (!this.selfGroups) {
      return false;
    }

    let adminPdns = this.selfGroups.filter(group => _.includes(group.name, pdn));
    return adminPdns && adminPdns.length > 0;
  }

  public getDealersInfo ( selectedDealerInfo ): string[] {
    this.addAppsService.dealershipName = selectedDealerInfo.dealershipName;
    return this.selectedDealershipInfo = selectedDealerInfo;
  }

  public searchDealerships(): Observable<IDealership[]> {
    let query: Observable<any>;
    let [pdn, dealershipName, selectedState] = [this.pdn.value, this.dealershipName.value, this.selectedState.value];
    if (this.isSearchByPdn) {
      query = this.dealershipService.findByPdn(pdn);
    }
    else if (this.isSearchByDealershipName) {
      query = this.dealershipService.findByDealershipName(dealershipName, selectedState);
    }
    return query;
  }

  public get isPdnInvalid(): boolean {
    return this.ngForm.submitted && this.pdn.invalid;
  }

  public get isDealershipNameInvalid(): boolean {
    return this.ngForm.submitted && this.dealershipName.invalid;
  }

  public get isSelectedStateInvalid(): boolean {
    return this.ngForm.submitted && this.selectedState.invalid;
  }

  public get isDealershipPdnInvalid(): boolean {
    return this.didPromptDealershipSelection && this.dealershipPdn.invalid;
  }

  public handleSubmit(): void {
    this.addDealershipFormSubmit.nativeElement.click();
  }

  private findDealershipName(pdn: string) {
    return this.dealerships.find(dealership => dealership.pdnNumber === pdn).dealershipName;
  }

  public get paginatedDealerships(): IDealership[] {
    const begin = (this.currentPage - 1) * this.itemsPerPage;
    const end   = begin + this.itemsPerPage;
    return this.dealerships.slice(begin, end);
  }

  public closeModalDrawer() {
    this.ngForm.reset();
    this.drawerState = false;
  }

  updateTotalPages($event: number) {
    this.totalPages = $event;
  }

  get showPagination() {
    if (this.dealershipsTotal > this.maxSize){
      return true;
    }
    else {
      this.totalPages = 1;
      return false;
    }
  }
}
