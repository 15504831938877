/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./standard-page-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../password-warn/password-warn.component.ngfactory";
import * as i3 from "../password-warn/password-warn.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/app-constants.service";
import * as i6 from "../../services/password-expiry.service";
import * as i7 from "../../services/session-management.service";
import * as i8 from "../nda-header/nda-header.component.ngfactory";
import * as i9 from "../nda-header/nda-header.component";
import * as i10 from "../../services/okta-auth/okta-auth.service";
import * as i11 from "@angular/common";
import * as i12 from "../../components-common/nda-footer/nda-footer.component.ngfactory";
import * as i13 from "../../components-common/nda-footer/nda-footer.component";
import * as i14 from "./standard-page-layout.component";
var styles_StandardPageLayoutComponent = [i0.styles];
var RenderType_StandardPageLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StandardPageLayoutComponent, data: {} });
export { RenderType_StandardPageLayoutComponent as RenderType_StandardPageLayoutComponent };
function View_StandardPageLayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "password-warn", [], null, null, null, i2.View_PasswordWarnComponent_0, i2.RenderType_PasswordWarnComponent)), i1.ɵdid(1, 114688, [[1, 4], ["pwdWarnModel", 4]], 0, i3.PasswordWarnComponent, [i4.Router, i5.AppConstantsService, i6.PasswordExpiryService, i7.SessionManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_StandardPageLayoutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { pwdWarnModel: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "nda-header", [], null, null, null, i8.View_NdaHeaderComponent_0, i8.RenderType_NdaHeaderComponent)), i1.ɵdid(2, 49152, null, 0, i9.NdaHeaderComponent, [i7.SessionManagementService, i10.OktaAuthService, i4.Router], { isNavEnabled: [0, "isNavEnabled"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "router-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StandardPageLayoutComponent_1)), i1.ɵdid(7, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "nda-footer", [], null, null, null, i12.View_NdaFooterComponent_0, i12.RenderType_NdaFooterComponent)), i1.ɵdid(9, 49152, null, 0, i13.NdaFooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isNavEnabled; _ck(_v, 2, 0, currVal_0); _ck(_v, 5, 0); var currVal_1 = _co.displayPasswordWarn; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_StandardPageLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-standard-page-layout", [], null, null, null, View_StandardPageLayoutComponent_0, RenderType_StandardPageLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i14.StandardPageLayoutComponent, [i6.PasswordExpiryService, i4.ActivatedRoute], null, null)], null, null); }
var StandardPageLayoutComponentNgFactory = i1.ɵccf("app-standard-page-layout", i14.StandardPageLayoutComponent, View_StandardPageLayoutComponent_Host_0, {}, {}, []);
export { StandardPageLayoutComponentNgFactory as StandardPageLayoutComponentNgFactory };
