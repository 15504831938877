import { PasswordWarnComponent } from '../password-warn/password-warn.component';
import { PasswordStatus, PasswordExpiryService } from '../../services/password-expiry.service';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { get } from 'lodash';
var StandardPageLayoutComponent = /** @class */ (function () {
    function StandardPageLayoutComponent(passwordService, activatedRoute) {
        this.passwordService = passwordService;
        this.activatedRoute = activatedRoute;
    }
    Object.defineProperty(StandardPageLayoutComponent.prototype, "isNavEnabled", {
        get: function () {
            return get(this.activatedRoute, 'snapshot.data.isNavEnabled');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StandardPageLayoutComponent.prototype, "displayPasswordWarn", {
        get: function () {
            if (this.passwordService.status === PasswordStatus.PasswordWarn) {
                this.pwdWarnModel.show(this.passwordService.passwordExpiryDate);
                this.passwordService.status = this.passwordService.passwordExpiryDate = null;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    return StandardPageLayoutComponent;
}());
export { StandardPageLayoutComponent };
