import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandlerService, HandleError } from './../../../services/http-error-handler.service';
import { UserModel } from '../../../models/user-model';
import { environment } from '../../../../environments/environment';
import { SessionManagementService } from '../../../services/session-management.service';

@Injectable()
export class OtpService {

  private handleError: HandleError;
  public requestPayLoad: object;

  private httpOptions = {
    withCredentials: true,
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private http: HttpClient,
    public httpErrorHandler: HttpErrorHandlerService,
    private sessionManager: SessionManagementService
  ) {

  }

  public sendOtp(stateToken, userId, factorId): Observable<{} | UserModel[]> {
    let url;

    if (stateToken && !this.sessionManager.hasSession) {
      this.requestPayLoad = {
        resource: { stateToken }
      };

      url = `${environment.ndaServerURLS.userServices}/authn/factors/${factorId}/send-otp`;

      this.handleError = this.httpErrorHandler.createHandleError('sendOtp');
    }
    else {
      this.requestPayLoad = {
        resource: { userId, factorId }
      };

      url = `${environment.ndaServerURLS.userServices}/user/factors/send-secure-otp`;
      this.handleError = this.httpErrorHandler.createHandleError('getHelpPwd');
    }
    return this.http.post<UserModel[]>(url, this.requestPayLoad, this.httpOptions);
  }

  public submitOtp(stateToken, userId, userName, factorId, passCode): Observable<{} | UserModel[]> {
    let url, catchErrorMethod;

    if (stateToken) {
      this.requestPayLoad = {
        resource: { userId, userName, stateToken, passCode }
      };

      url = `${environment.ndaServerURLS.userServices}/authn/factors/${factorId}/verify-otp`;

      this.handleError = this.httpErrorHandler.createHandleError('submitOtp');
      catchErrorMethod = 'submitOtp';
    }
    else {
      this.requestPayLoad = {
        resource: { userId, factorId, passCode }
      };

      url = `${environment.ndaServerURLS.userServices}/user/factors/verify-secure-otp`;

      this.handleError = this.httpErrorHandler.createHandleError('getHelpSubmitOtp');
      catchErrorMethod = 'getHelpPwd';
    }

    return this.http.post<UserModel[]>(url, this.requestPayLoad, this.httpOptions)
      .pipe(catchError(this.handleError(catchErrorMethod, [])));
  }

}
