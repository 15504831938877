/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./password-warn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "@angular/forms";
import * as i6 from "./password-warn.component";
import * as i7 from "@angular/router";
import * as i8 from "../../services/app-constants.service";
import * as i9 from "../../services/password-expiry.service";
import * as i10 from "../../services/session-management.service";
var styles_PasswordWarnComponent = [i0.styles];
var RenderType_PasswordWarnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PasswordWarnComponent, data: {} });
export { RenderType_PasswordWarnComponent as RenderType_PasswordWarnComponent };
export function View_PasswordWarnComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵqud(402653184, 1, { passwordWarn: 0 }), (_l()(), i1.ɵeld(2, 16777216, null, null, 24, "div", [["aria-hidden", "true"], ["aria-labelledby", "mySmallModalLabel"], ["bsModal", ""], ["class", "modal fade"], ["role", "dialog"], ["tabindex", "-1"]], null, [[null, "mousedown"], [null, "mouseup"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClickStarted($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseup" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onClickStop($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.esc" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onEsc($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 212992, [[1, 4], ["passwordWarn", 4]], 0, i3.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i4.ComponentLoaderFactory], { config: [0, "config"] }, null), i1.ɵpod(4, { backdrop: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 21, "div", [["class", "modal-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 20, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [["class", "ally-primary-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close pull-right text-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "allycon-glyph-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 14, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", "", "", ""])), i1.ɵppd(16, 2), (_l()(), i1.ɵeld(17, 0, null, null, 9, "form", [["novalidate", ""]], [[1, "allytmfn", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 19).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 16384, null, 0, i5.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(19, 4210688, null, 0, i5.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.NgForm]), i1.ɵdid(21, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i1.ɵeld(22, 0, null, null, 4, "div", [["class", "mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "button", [["class", "btn btn-primary font-weight-bold"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePassword() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(24, null, ["", ""])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [["class", "btn btn-link accessibility-button-group"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(26, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, "static"); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.CONSTANTS.passwordInfo; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.CONSTANTS.bodyContent1; var currVal_3 = i1.ɵunv(_v, 15, 1, _ck(_v, 16, 0, i1.ɵnov(_v, 0), _co.getDate, _co.dateFormat)); var currVal_4 = _co.CONSTANTS.bodyContent2; _ck(_v, 15, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = "changePasswordButtons"; var currVal_6 = i1.ɵnov(_v, 21).ngClassUntouched; var currVal_7 = i1.ɵnov(_v, 21).ngClassTouched; var currVal_8 = i1.ɵnov(_v, 21).ngClassPristine; var currVal_9 = i1.ɵnov(_v, 21).ngClassDirty; var currVal_10 = i1.ɵnov(_v, 21).ngClassValid; var currVal_11 = i1.ɵnov(_v, 21).ngClassInvalid; var currVal_12 = i1.ɵnov(_v, 21).ngClassPending; _ck(_v, 17, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = _co.CONSTANTS.changepassword; _ck(_v, 24, 0, currVal_13); var currVal_14 = (_co.isDownStreamFlow ? _co.CONSTANTS.smartAuctionContinue : _co.CONSTANTS.continue); _ck(_v, 26, 0, currVal_14); }); }
export function View_PasswordWarnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "password-warn", [], null, null, null, View_PasswordWarnComponent_0, RenderType_PasswordWarnComponent)), i1.ɵdid(1, 114688, null, 0, i6.PasswordWarnComponent, [i7.Router, i8.AppConstantsService, i9.PasswordExpiryService, i10.SessionManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PasswordWarnComponentNgFactory = i1.ɵccf("password-warn", i6.PasswordWarnComponent, View_PasswordWarnComponent_Host_0, {}, {}, []);
export { PasswordWarnComponentNgFactory as PasswordWarnComponentNgFactory };
