import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import {Observable} from 'rxjs/Observable';

import { AppStrings } from '../../assets/app-strings/app-strings';

/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError =
  <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T> | Array<{}>;

@Injectable()
export class HttpErrorHandlerService {

  public errorMessage={'exceptions':[]};
  constructor() { }

  /** Create curried handleError function that already knows the service name */
  public createHandleError = (serviceName = '') => <T>
    (operation = 'operation', result = {} as T) => this.handleError(serviceName, operation, result);

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param serviceName = name of the data service that attempted the operation
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T> (serviceName = '', operation = 'operation', result = {} as T) {

    return (error: HttpErrorResponse)  => {

      this.errorMessage = {'exceptions':[]};

      let err = error.error;

      if (!err || err.exceptions === undefined || err.exceptions.message === undefined) {
        this.errorMessage['exceptions'].push(AppStrings['authErrors']['UNKNOWN_ERR']);
      }
      else if (AppStrings['authErrors'][err.exceptions.code]) {
          this.errorMessage['exceptions'].push(AppStrings['authErrors'][err.exceptions.code]);
      }
      else {
          this.errorMessage['exceptions'].push(err.exceptions.message);
      }

      return Observable.throw(error);
    };

  }


}
