import { Component } from '@angular/core';
import { SpinnerService } from '../../services/spinner/spinner.service';

@Component({
  selector: 'nda-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {

  get isVisible(): boolean {
    return this.spinnerService.isVisible;
  }

  constructor(
    public spinnerService: SpinnerService
  ) { }
}
