import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
var ContactDetailComponent = /** @class */ (function () {
    function ContactDetailComponent() {
        this.CONSTANTS = tslib_1.__assign({}, AppStrings['myContacts']['contactDetail']);
    }
    ContactDetailComponent.prototype.ngOnInit = function () { };
    ContactDetailComponent.prototype.isDynamicContact = function (contact) {
        return contact.contactName !== undefined;
    };
    Object.defineProperty(ContactDetailComponent.prototype, "columns", {
        get: function () {
            return this.layout === 'horizontal' ? 'col-4' : 'col-12';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactDetailComponent.prototype, "row", {
        get: function () {
            return this.layout === 'horizontal' ? 'row' : '';
        },
        enumerable: true,
        configurable: true
    });
    return ContactDetailComponent;
}());
export { ContactDetailComponent };
