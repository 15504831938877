import { Router } from '@angular/router';
import { ContactsFlyoutComponent } from '../../contacts/components/contacts-flyout/contacts-flyout.component';
import { SessionManagementService } from '../../services/session-management.service';
import { OktaAuthService } from '../../services/okta-auth/okta-auth.service';
var NdaHeaderComponent = /** @class */ (function () {
    function NdaHeaderComponent(sessionManager, oktaAuthService, router) {
        this.sessionManager = sessionManager;
        this.oktaAuthService = oktaAuthService;
        this.router = router;
        this.isFlyoutShowing = false;
    }
    Object.defineProperty(NdaHeaderComponent.prototype, "isLoggedIn", {
        get: function () {
            return this.sessionManager.hasSession;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaHeaderComponent.prototype, "shouldDisplayNav", {
        get: function () {
            return this.isNavEnabled != false && this.isLoggedIn;
        },
        enumerable: true,
        configurable: true
    });
    NdaHeaderComponent.prototype.dashboardRefresh = function () {
        var _this = this;
        if (this.isLoggedIn) {
            // this.sessionManager.refreshSession().subscribe();
            this.oktaAuthService.getApigeeToken().then(function () {
                _this.router.navigate(['/dashboard']);
            }, function () { _this.router.navigate(['/dashboard']); });
        }
    };
    NdaHeaderComponent.prototype.displayFlyout = function (isVisible) {
        this.isFlyoutShowing = isVisible;
        this.flyout.show(isVisible);
    };
    return NdaHeaderComponent;
}());
export { NdaHeaderComponent };
