import { Injectable } from '@angular/core';

import { get, cloneDeep, isUndefined } from 'lodash';

import { IProfile } from '../../models/profile.interface';
import { ISessionDetails } from '../../models/session-details.interface';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';

@Injectable()
export class PreLoginUserDetailsService {

  private userProfile: IProfile;

  constructor() { }

  set profile(profile: IProfile) {
    this.userProfile = profile;
    this.userProfile.userName = profile.login;
  }

  get profile(): IProfile {
    return cloneDeep(this.userProfile);
  }

  get hasProfile(): boolean {
    return !isUndefined(this.userProfile);
  }

  get sessionDetails(): ISessionDetails {
    return {
      userName: get(this.profile, 'userName', ''),
      userType: get(this.profile, 'userType', ''),
      userId:   get(this.profile, 'userId', '')
    };
  }

  get userName(): string {
    return get(this.profile, 'userName', '');
  }
}
