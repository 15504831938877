import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { pick, partialRight, map } from 'lodash';
import { HttpErrorHandlerService } from '../../../services/http-error-handler.service';
import { DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { SpinnerService } from '../../../loading-spinner/services/spinner/spinner.service';
import { ProfileAllyRole } from '../../../models/profile.interface';
import { AddAppsService } from '../../../profile/services/add-apps.service';
import { ProfileUserType } from '../../../models/profile.interface';
import 'rxjs/add/operator/finally';
import { SessionManagementService } from '../../../services/session-management.service';
export var ProfileDealershipRole;
(function (ProfileDealershipRole) {
    ProfileDealershipRole.user = 'Dealership User';
    ProfileDealershipRole.admin = 'Dealership Admin';
})(ProfileDealershipRole || (ProfileDealershipRole = {}));
export var AppDrawerOutputState;
(function (AppDrawerOutputState) {
    AppDrawerOutputState.processError = 'AppError';
    AppDrawerOutputState.processSingleAddAppSuccess = 'SingleAddAppSuccess';
    AppDrawerOutputState.processMultipleAddAppSuccess = 'MultipleAddAppSuccess';
})(AppDrawerOutputState || (AppDrawerOutputState = {}));
export var StorePdnKey;
(function (StorePdnKey) {
    StorePdnKey.pdn = "pdn";
    StorePdnKey.primary = "primary";
    StorePdnKey.secondary = "secondary";
})(StorePdnKey || (StorePdnKey = {}));
var UserDetailsService = /** @class */ (function () {
    function UserDetailsService(http, httpErrorHandler, sessionManager, drawerComponentCommunicatorService, spinnerService, addAppsService) {
        this.http = http;
        this.httpErrorHandler = httpErrorHandler;
        this.sessionManager = sessionManager;
        this.drawerComponentCommunicatorService = drawerComponentCommunicatorService;
        this.spinnerService = spinnerService;
        this.addAppsService = addAppsService;
        this.displayRemoveAppModal = false;
        this.callOnloadService = false;
        this.callOnloadServiceWithPasswordSuccess = false;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        this.handleError = httpErrorHandler.createHandleError('getUserProfile');
    }
    UserDetailsService.prototype.resolve = function (route, state) {
        var _this = this;
        this.spinnerService.show();
        var includeParamValue = 'factors';
        if (this.userType != ProfileUserType.branch) {
            includeParamValue = includeParamValue + ",PDN_WITH_APPS&requestedBy=" + this.sessionManager.userRole;
        }
        var getUserDataUrl = environment.ndaServerURLS.userServices + "/user/" + this.slicedUserName + "?include=" + includeParamValue;
        return this.http.get(getUserDataUrl, this.httpOptions)
            .finally(function () { return _this.spinnerService.hide(); })
            .pipe(catchError(this.handleError('getUserProfile', [])));
    };
    /**
     * GetAllApplications - Used to fetch all applications correspnds to a specific user
     */
    UserDetailsService.prototype.getAllApplications = function () {
        var getUserAppsUrl = environment.ndaServerURLS.userServices + "/user/" + this.userId + "/all-apps?userName=" + this.slicedUserName;
        return this.http.get(getUserAppsUrl, this.httpOptions)
            .pipe(catchError(this.handleError('getUserApplications', [])));
    };
    Object.defineProperty(UserDetailsService.prototype, "slicedUserName", {
        /**
         * slicedUserName - Used to scrap the username alone from okta login attribute
         */
        get: function () {
            if (this.userName) {
                return this.userName.indexOf('@') > 0 ? this.userName.substr(0, this.userName.indexOf('@')) : this.userName;
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     *  RemoveUser - removes the users associated with the specific dealership
     */
    UserDetailsService.prototype.removeUser = function () {
        var requestPayLoad = {
            resource: {
                requestedBy: this.sessionManager.profile.userName,
                requestedUserType: this.sessionManager.profile.userType
            }
        };
        var removeUserUrl = environment.ndaServerURLS.userServices + "/user/" + this.slicedUserName;
        return this.http.request('delete', removeUserUrl, { body: requestPayLoad })
            .pipe(catchError(this.handleError('removeUser', [])));
    };
    /**
     *  AddApplications - adds the requested application to the user directly in OKTA
     */
    UserDetailsService.prototype.addApplication = function (isSecondaryDealerFlow) {
        var requestPayLoad = {
            resource: {
                requestedBy: this.sessionManager.profile.userName,
                requestedUserType: this.sessionManager.profile.userType,
                isRequestedByAdmin: true,
                userName: this.slicedUserName,
                userType: this.userType
            }
        };
        if (this.isAllyRole) {
            requestPayLoad.resource['applicationDetails'] = this.prepareAddApplicationList();
            if (isSecondaryDealerFlow) {
                requestPayLoad.resource['dealership'] = {
                    pdnNumber: this.addAppsService.pdn
                };
            }
        }
        else {
            requestPayLoad.resource['pdnWithApplications'] = [{
                    pdnNumber: this.selectedDealershipPdn,
                    dealershipName: this.selectedDealershipName,
                    dealershipType: this.selectedDealershipType,
                    isSecondaryPdnAppsEmpty: this.isAllAppsSelected && !this.isPendingAppsAvailable,
                    applicationDetails: this.prepareAddApplicationList()
                }];
        }
        var addAppUrl = environment.ndaServerURLS.userServices + "/user/" + this.slicedUserName + "/apps";
        return this.http.post(addAppUrl, requestPayLoad, this.httpOptions)
            .pipe(catchError(this.handleError('addApplication', [])));
    };
    UserDetailsService.prototype.addApplicationViaWorkflow = function (isSecondaryDealerFlow, profile) {
        var requestPayload;
        if (isSecondaryDealerFlow) {
            requestPayload = {
                firstName: profile.firstName,
                lastName: profile.lastName,
                email: profile.emailId,
                mobile: this.processMobile(profile.cellPhone),
                userName: this.slicedUserName,
                oeId: this.selectedDealershipPdn,
                dealershipName: this.selectedDealershipName,
                oeIdType: 'secondary',
                applications: this.prepareWorkflowAddApplicationList(this.selectedDealershipPdn, true)
            };
        }
        else {
            requestPayload = {
                userName: this.slicedUserName,
                oeId: this.selectedDealershipPdn,
                applications: this.prepareWorkflowAddApplicationList(this.selectedDealershipPdn, false)
            };
        }
        var url = environment.ndaServerURLS.workflowServices + "/workflow/users/" + profile.userId + "/application-request";
        return this.http.post(url, requestPayload, this.httpOptions)
            .pipe(catchError(this.handleError('addApplicationWorkflow', [])));
    };
    UserDetailsService.prototype.addSecondaryDealershipViaWorkFlow = function (profile) {
        var requestPayload = {
            firstName: profile.firstName,
            lastName: profile.lastName,
            email: profile.emailId,
            mobile: this.processMobile(profile.cellPhone),
            userName: profile.userName,
            oeId: this.selectedDealershipPdn,
            dealershipName: this.selectedDealershipName,
            oeIdType: 'secondary',
            applications: this.prepareWorkflowAddApplicationList(this.selectedDealershipPdn, true)
        };
        var url = environment.ndaServerURLS.workflowServices + "/workflow/dealer-request";
        return this.http.post(url, requestPayload, this.httpOptions)
            .pipe(catchError(this.handleError('addSecondaryDealership', [])));
    };
    UserDetailsService.prototype.processMobile = function (cellPhone) {
        return cellPhone == null || cellPhone == "" ? "0" : cellPhone;
    };
    /**
     *  AddSecondaryDealership - adds the requested application to the user directly in OKTA
    */
    UserDetailsService.prototype.addSecondaryDealership = function () {
        var requestPayLoad = {
            resource: {
                applicationDetails: this.prepareAddApplicationList(),
                requestedBy: this.sessionManager.profile.userName,
                requestedUserType: this.sessionManager.profile.userType,
                isRequestedByAdmin: true,
                userName: this.slicedUserName,
                userType: this.userType,
                dealership: {
                    pdnNumber: this.addAppsService.pdn
                }
            }
        };
        requestPayLoad.resource['pdnWithApplications'] = [{
                pdnNumber: this.selectedDealershipPdn,
                dealershipName: this.selectedDealershipName,
                dealershipType: 'Secondary',
                isSecondaryPdnAppsEmpty: false,
                isAddSecondaryDealer: true,
                applicationDetails: this.prepareAddApplicationList()
            }];
        var addAppUrl = environment.ndaServerURLS.userServices + "/user/" + this.slicedUserName + "/add-secondary-dealership";
        return this.http.post(addAppUrl, requestPayLoad, this.httpOptions)
            .pipe(catchError(this.handleError('addSecondaryDealership', [])));
    };
    /**
     *  RemoveApplications - removes the requested application from the user
     */
    UserDetailsService.prototype.removeApplication = function () {
        var requestPayLoad = {
            resource: {
                requestedBy: this.sessionManager.profile.userName,
                requestedUserType: this.sessionManager.profile.userType,
                isRequestedByAdmin: true,
                userName: this.slicedUserName,
                userType: this.userType
            }
        };
        if (this.isAllyRole) {
            requestPayLoad.resource['applicationDetails'] = this.prepareRemoveApplicationList();
            requestPayLoad.resource['dealership'] = {
                pdnNumber: this.userPdn,
                dealershipName: this.dealershipName
            };
        }
        else {
            requestPayLoad.resource['pdnWithApplications'] = [{
                    pdnNumber: this.selectedDealershipPdn,
                    dealershipName: this.selectedDealershipName,
                    dealershipType: this.selectedDealershipType,
                    isSecondaryPdnAppsEmpty: this.isAllAppsSelected && !this.isPendingAppsAvailable,
                    applicationDetails: this.prepareRemoveApplicationList()
                }];
        }
        var removeAppUrl = environment.ndaServerURLS.userServices + "/user/" + this.slicedUserName + "/apps";
        return this.http.request('delete', removeAppUrl, { body: requestPayLoad })
            .pipe(catchError(this.handleError('removeApplication', [])));
    };
    /**
     *  getUserGroups - lists the groups of the requested user
    */
    UserDetailsService.prototype.getUserGroups = function (isSelf) {
        var userId = isSelf ? this.sessionManager.profile.userId : this.userId;
        var requestorRole = isSelf ? '' : "?requestedBy=" + this.sessionManager.userRole;
        return this.http.get(environment.ndaServerURLS.userServices + "/user/" + userId + "/groups" + requestorRole, this.httpOptions)
            .pipe(catchError(this.handleError('getUserGroups', [])));
    };
    /**
     *  updateProfile - updates the profile of the requested user
    */
    UserDetailsService.prototype.updateProfile = function (profile, oldEmail, isStatusChanged) {
        var request = {
            'resource': {
                'firstName': profile.firstName,
                'lastName': profile.lastName,
                'mobilePhone': profile.cellPhone,
                'email': profile.emailId,
                'oldEmail': oldEmail,
                'isRequestedByAdmin': true,
                'requestedUserType': this.sessionManager.profile.userType,
                'requestedBy': this.sessionManager.profile.userName
            }
        };
        if (isStatusChanged) {
            request.resource['userStatus'] = profile.userStatus;
        }
        var userId = this.userId;
        var url = environment.ndaServerURLS.userServices + "/user/" + userId;
        return this.http.put(url, request, this.httpOptions)
            .pipe(catchError(this.handleError('admin updateProfile', [])));
    };
    /**
     *  createTemporaryPassword - creates temporary password for the requested user
    */
    UserDetailsService.prototype.createTemporaryPassword = function (profile, password) {
        var request = {
            'resource': {
                'firstName': profile.firstName,
                'lastName': profile.lastName,
                'mobilePhone': profile.cellPhone,
                'password': password,
                'email': profile.emailId,
                'isRequestedByAdmin': true,
                'requestedUserType': this.sessionManager.profile.userType,
                'requestedBy': this.sessionManager.profile.userName
            }
        };
        var url = environment.ndaServerURLS.userServices + "/user/" + this.userName + "/create-temporary-password";
        return this.http.put(url, request, this.httpOptions)
            .pipe(catchError(this.handleError('createTemporaryPassword', [])));
    };
    UserDetailsService.prototype.prepareAddApplicationList = function () {
        return map(this.selectedApps, partialRight(pick, ['name', 'role', 'region', 'auctionAccessId']));
    };
    UserDetailsService.prototype.prepareRemoveApplicationList = function () {
        return map(this.selectedApps, partialRight(pick, ['name']));
    };
    UserDetailsService.prototype.prepareWorkflowAddApplicationList = function (pdn, isAddOeidType) {
        var processedList = [];
        var applicationToRequestList;
        for (var i = 0; i < this.selectedApps.length; i++) {
            applicationToRequestList = {
                applicationName: this.selectedApps[i].name,
                oeId: pdn,
                isChecked: false,
                roleName: this.selectedApps[i].role
            };
            if (isAddOeidType) {
                applicationToRequestList['oeIdType'] = 'secondary';
            }
            processedList.push(applicationToRequestList);
        }
        return processedList;
    };
    UserDetailsService.prototype.updateState = function (state) {
        this.drawerComponentCommunicatorService.drawerState = this.drawerComponentCommunicatorService.drawerComponentDestroyed = this.drawerComponentCommunicatorService.drawerComponentLoaded = false;
        this.drawerComponentCommunicatorService.drawerComponentOutputState = state;
    };
    Object.defineProperty(UserDetailsService.prototype, "isDealerRole", {
        get: function () {
            return !!this.dealerRole;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailsService.prototype, "isAllyRole", {
        get: function () {
            return !!this.allyRole;
        },
        enumerable: true,
        configurable: true
    });
    UserDetailsService.prototype.getSearchedUserPendingRequests = function (userNameToSearch) {
        var action = 'searchuser-pending-requests';
        var url = environment.ndaServerURLS.workflowServices + "/workflow/" + action;
        var userDetails = { userName: userNameToSearch };
        return this.http.post(url, userDetails, this.httpOptions);
    };
    return UserDetailsService;
}());
export { UserDetailsService };
