import { AfterContentInit, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { get } from 'lodash';
import 'rxjs/add/operator/distinctUntilChanged';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PopoverConfig } from 'ngx-bootstrap/popover';
import { StoreService } from './services';
import { ModalSessionTimeoutComponent } from './session/components/modal-session-timeout/modal-session-timeout.component';
import { ApiGatewayInterceptor } from './api-gateway';
import { AppIdleService } from './services/app-idle/app-idle.service';
import { AuthenticationService } from './services/authentication.service';
import { SessionManagementService } from './services/session-management.service';
import { OktaAuthService } from "@nda/app/services";
import { BookmarkingService } from './services/bookmarking.service';
export var SessionTimeoutModalOnHideReason;
(function (SessionTimeoutModalOnHideReason) {
    SessionTimeoutModalOnHideReason.esc = "esc";
    SessionTimeoutModalOnHideReason.backdropClick = "backdrop-click";
})(SessionTimeoutModalOnHideReason || (SessionTimeoutModalOnHideReason = {}));
export function PopoverConfigExtended() {
    return Object.assign(new PopoverConfig(), {
        placement: 'bottom',
        triggers: ''
    });
}
var AppComponent = /** @class */ (function () {
    function AppComponent(sessionManager, authService, activatedRoute, idleService, oktaAuthService, modalService, router, storeService, bookmarkingService, zone) {
        var _this = this;
        this.sessionManager = sessionManager;
        this.authService = authService;
        this.activatedRoute = activatedRoute;
        this.idleService = idleService;
        this.oktaAuthService = oktaAuthService;
        this.modalService = modalService;
        this.router = router;
        this.storeService = storeService;
        this.bookmarkingService = bookmarkingService;
        this.zone = zone;
        this.idleService.onTimeout.subscribe(function () { return _this.handleSessionEnd(); });
        this.idleService.onInterrupt.subscribe(function () { return _this.hideSessionIdleModal(); });
        this.idleService.onIdleStart.subscribe(function () { return _this.configureSessionIdleModal(); });
        this.idleService.onPing.subscribe(function () { return sessionManager.refreshSession().subscribe(); });
        this.idleService.onTimeoutWarning.subscribe(function (countdown) {
            _this.modalRef.content.countDownMessage(countdown);
        });
        this.idleService.watchToggle(this.sessionManager.hasSession);
        this.sessionManager.hasSession$.distinctUntilChanged()
            .subscribe(function (hasSession) { return _this.handleSessionChange(hasSession); });
        if (typeof Worker !== 'undefined') {
            var worker = new Worker('./app.worker', { type: 'module' }); //creates a webworker thread instance
            worker.onmessage = function (_a) {
                var data = _a.data;
                if (!localStorage.getItem('proxyLayerObject'))
                    return; //if no active session then return out of block
                if (data == 'token') { //listens for ping from the webworker thread for apigee refresh token
                    _this.oktaAuthService.getApigeeToken(); //makes apigee token call
                }
                else if (data == 'validate idle timer') { //listens for ping from the webworker thread for idle time validator
                    var lastActiveTimeDetected = localStorage.getItem('ng2Idle.main.expiry'); //gets expiry time based on last stored user activity
                    if (!!lastActiveTimeDetected) { //checks if active session
                        var timeElapsed = Date.now() - parseInt(lastActiveTimeDetected); //seconds left until expiry time
                        if (timeElapsed >= 0) { //once timeElapsed turns positive, the idle time limit has exceeded
                            _this.handleSessionEnd(); //calls function for ending session
                        }
                    }
                    ;
                    return;
                }
            };
        }
        else {
            // Web Workers are not supported in this environment.
            // You should add a fallback so that your program still executes correctly.
        }
    }
    // Remove Apigee details from storage when app initializes.
    AppComponent.prototype.ngOnInit = function () {
        this.oktaAuthService.clearApigeeJwt();
    };
    AppComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        var isIE11 = !!window['MSInputMethodContext'] && !!document['documentMode'];
        var authParams = window.location.search;
        if (authParams.includes('code=') && !authParams.includes('/ui/error/') && authParams.includes('state=') && authParams.includes('scope=')) {
            sessionStorage.setItem('authCode', authParams.split('&')[0].split('=')[1]);
            var sessionObject = Object.keys(this.sessionManager);
            if (sessionObject.indexOf('profile') > 0) {
                if (!this.sessionManager.profile.allyRole && this.sessionManager.profile.userType === 'B') {
                    return;
                }
            }
            else {
                this.oktaAuthService.getApigeeToken().then(function (res) {
                    if (typeof (res) === 'object')
                        localStorage.setItem('proxyLayerObject', JSON.stringify(res));
                    // Redirect to bookmarked URL, default is dashboard.
                    var redirectPath = _this.bookmarkingService.getBookmarkedUrl();
                    _this.bookmarkingService.resetBookmarkedUrl();
                    _this.zone.run(function () { _this.router.navigate([redirectPath]); });
                });
            }
        }
        if (!isIE11) {
            return;
        }
        var checkbox = document.getElementById('24CF4C7D395');
        var check = document.getElementById('24F545731EED');
        checkbox.addEventListener('click', function (event) {
            event.preventDefault();
        }, false);
        check.addEventListener('click', function (event) {
            event.preventDefault();
        }, false);
        var _loop_1 = function (i) {
            setTimeout(function () {
                check['checked'] = i % 2 === 0;
            }, i * 250);
        };
        for (var i = 0; i < 40; i++) {
            _loop_1(i);
        }
    };
    AppComponent.prototype.configureSessionIdleModal = function () {
        var _this = this;
        this.hideSessionIdleModal();
        this.idleService.interruptsPause();
        this.modalRef = this.modalService.show(ModalSessionTimeoutComponent, { class: 'modal-md' });
        this.modalRef.content.didLogout.subscribe(function () { return _this.handleSessionEnd(); });
        this.modalRef.content.didRefresh.subscribe(function () { return _this.handleSessionContinue(); });
        this.modalService.onHide.subscribe(function (reason) {
            switch (reason) {
                case SessionTimeoutModalOnHideReason.esc:
                case SessionTimeoutModalOnHideReason.backdropClick:
                    _this.handleSessionContinue();
                    break;
            }
        });
    };
    AppComponent.prototype.hideSessionIdleModal = function () {
        this.modalRef && this.modalRef.hide();
    };
    AppComponent.prototype.handleSessionChange = function (hasSession) {
        this.idleService.watchToggle(hasSession);
        var atRegistration = window.location.href.includes('register/confirmation');
        if (!hasSession) {
            ApiGatewayInterceptor.clearToken();
            this.storeService.clear();
            this.storeService.write('suppressDeactivateWarning', true);
            if (!this.authService.allyUserLoggingOut && !atRegistration) {
                this.redirectToLogin();
            }
        }
        else {
            this.storeService.delete('suppressDeactivateWarning');
        }
    };
    AppComponent.prototype.handleSessionContinue = function () {
        this.hideSessionIdleModal();
        this.idleService.watchStart();
    };
    AppComponent.prototype.handleSessionEnd = function () {
        this.hideSessionIdleModal();
        this.authService.logout();
    };
    AppComponent.prototype.redirectToLogin = function () {
        var url = get(this.router, 'url', '');
        var isLogin = url.indexOf('login') > -1;
        if (!isLogin) {
            this.router.navigate(['login']);
        }
    };
    return AppComponent;
}());
export { AppComponent };
