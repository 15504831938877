import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';

import { PasswordComplexityModule } from '../password-complexity/password-complexity.module';

import { PreLoginUserDetailsService } from './services/pre-login-user-details.service';
import { UserDetailsGuard } from './guards/user-details.guard';
import { ChangePasswordPreLoginComponent } from './components/change-password-pre-login/change-password-pre-login.component';
import { ModalPasswordChangedComponent } from './components/modal-password-changed/modal-password-changed.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordComplexityModule,
    ModalModule.forRoot()
  ],
  providers: [
    PreLoginUserDetailsService,
    UserDetailsGuard
  ],
  declarations: [
    ModalPasswordChangedComponent,
    ChangePasswordPreLoginComponent
  ]
})
export class ChangePasswordPreLoginModule { }
