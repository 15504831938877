import { Component, ViewChild, Input } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { cloneDeep, filter, template, get } from 'lodash';

import { EditProfileService } from '../../../profile/services/edit-profile.service';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { IUserDetails } from '../../models/user-details.interface';

import { DrawerOutputState, DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { AppConstantsService } from '../../../services/app-constants.service';
import { IProfile } from '../../../models/profile.interface';
import { debug } from 'util';
import { IFlowMap } from '../../../models/flow-map.interface';
import { NdaModalLeaveWithoutSaveComponent } from '../nda-modal-leave-without-save/nda-modal-leave-without-save.component';
import { UserDetailsService } from '../../services/user-details/user-details.service';
import { SessionManagementService } from '../../../services/session-management.service';

export type UserStatus = keyof {
  'ACTIVE',
  'SUSPENDED',
  'LOCKED_OUT',
  'DEPROVISIONED'
}
export namespace UserStatus {
  export const ACTIVE: UserStatus = 'ACTIVE';
  export const SUSPENDED: UserStatus = 'SUSPENDED';
  export const LOCKED_OUT: UserStatus = 'LOCKED_OUT';
  export const DEPROVISIONED: UserStatus = 'DEPROVISIONED';
}

export type UserStatusAction = keyof {
  'UNLOCKED',
  'UNSUSPENDED'
}
export namespace UserStatusAction {
  export const UNLOCKED: UserStatusAction = 'UNLOCKED';
  export const UNSUSPENDED: UserStatusAction = 'UNSUSPENDED';
}

@Component({
  selector: 'app-nda-modal-content-update-personal-information',
  templateUrl: './nda-modal-content-update-personal-information.component.html',
  styleUrls: ['./nda-modal-content-update-personal-information.component.scss']
})
export class NdaModalContentUpdatePersonalInformationComponent {

  readonly CONSTANTS = {
    ...AppStrings['personalInformation'],
    ...AppStrings['common']
  };

  public readonly ACTIVE: UserStatus = UserStatus.ACTIVE;
  public readonly SUSPENDED: UserStatus = UserStatus.SUSPENDED;
  public readonly LOCKED_OUT: UserStatus = UserStatus.LOCKED_OUT;
  public readonly DEPROVISIONED: UserStatus = UserStatus.DEPROVISIONED;

  private hasPendingRequest: boolean = false;

  public drawerState: boolean = false;
  public profile : IUserDetails;
  public profileFromStore: IProfile;
  public editPersonalSubmit : boolean = false;
  public cellPhoneErrFlag : boolean = false;
  public showReEnterEmail : boolean = false;
  public currentEmailId : string;
  private fullNamePattern: string = "^[a-zA-Z][a-zA-Z ]*$";
  public flowMap: IFlowMap;

  @ViewChild('editpersonalInfo', { static: true }) public ngForm;
  @ViewChild('leaveWithoutSave', { static: false  }) leaveWithoutSave : NdaModalLeaveWithoutSaveComponent;
  @Input('data')
  data: any;

  public currentProfile: IUserDetails;
  public userId: string;

  constructor( private editProfileService: EditProfileService,
               private appConstantsService : AppConstantsService,
               private sessionManagementService: SessionManagementService,
               private userDetailsService: UserDetailsService,
               private drawerComponentCommunicatorService: DrawerComponentCommunicatorService
               )
  {
    this.profile = {
      userId: null,
      userType: null,
      pdnWithApplications : null,
      firstName: null,
      lastName: null,
      cellPhone: null,
      emailId: null,
      primaryDealer: null,
      reEnterEmailId: '',
      userName: '',
      dealershipName: '',
      userStatus: '',
      dealerRole: null,
      allyRole: null,
      shouldActivateUser: false
    }
    this.flowMap = this.appConstantsService.edit_profile_flow;
  }

  ngOnInit() {
    this.drawerComponentCommunicatorService.drawerComponentOutputState = null;
    this.currentProfile = this.data.profile;
    this.userId = this.data.userId;
    this.profile = cloneDeep(this.currentProfile);
    this.profile.shouldActivateUser = false;
    if(this.isPhoneNumberWithPlus) {
        this.profile.cellPhone = this.profile.cellPhone.slice(2);
    }
    this.currentEmailId  = this.currentProfile.emailId;
    return this.drawerState = true;
  }

  get isPhoneNumberWithPlus(): boolean {
    return this.profile.cellPhone !== null && this.profile.cellPhone !== undefined && this.profile.cellPhone.startsWith('+');
  }

  public get isUserApprovalPending(): boolean {
    return !this.isAllyRole && !this.profile.primaryDealer;
  }

  public get canBeActivatedByAllyAdmin(): boolean{
    return this.isSuspendedUser || this.isLockedOutUser || this.isDeactivatedUser;
  }

  public get canBeActivatedByDealerAdmin(): boolean{
    return this.isSuspendedUser || this.isLockedOutUser;
  }

  public get isDealerAdminForPrimarPDN(): boolean{
    return this.sessionManagementService.isDealershipUser && this.data.isRequestorAnAdmin;
  }

  get isActiveUser(): boolean{
    return this.profile.userStatus === this.ACTIVE;
  }

  get isSuspendedUser(): boolean{
    return this.profile.userStatus === this.SUSPENDED;
  }

  get isLockedOutUser(): boolean{
    return this.profile.userStatus === this.LOCKED_OUT;
  }

  get isDeactivatedUser(): boolean{
    return this.profile.userStatus === this.DEPROVISIONED;
  }

  get isAllyRole(): boolean {
    return !!this.userDetailsService.allyRole || this.profile.userType === 'B';
  }

  public updateFirstNameErrorMessage(required, pattern, minLength){
    if (!!this.editPersonalSubmit && !!required){
      return this.CONSTANTS.firstNameError;
    }
    if(!!this.editPersonalSubmit && !!pattern){
      return this.CONSTANTS.firstNameSpecialCharErr;
    }
    if (!!this.editPersonalSubmit && !!minLength && !pattern){
      return this.CONSTANTS.firstNameMin2LengthErr;
    }else{return "";}
  }

  public updateLastNameErrorMessage(required, pattern, minLength){
    if (!!this.editPersonalSubmit && !!required){
      return this.CONSTANTS.lastNameError;
    }
    if(!!this.editPersonalSubmit && !!pattern){
      return this.CONSTANTS.lastNameSpecialCharErr;
    }
    if (!!this.editPersonalSubmit && !!minLength && !pattern){
      return this.CONSTANTS.lastNameMin2LengthErr;
    }else{return "";}
  }

  public updateCellPhoneErrorMessage(pattern){
    if (!!this.editPersonalSubmit && pattern){
      return this.CONSTANTS.cellPhoneError;
    }else{return "";}
  }

  public updateEmailErrorMessage(required, email){
    if (!!this.editPersonalSubmit && !!required){
      return this.CONSTANTS.emailEmptyError;
    }
    if(!!this.editPersonalSubmit && !required && !!email ){
      return this.CONSTANTS.emailInvalidError;
    }else{return "";}
  }

  public reEnterEmailErrorMessage(required, emailValue, reEnterValue){
    if (!!this.editPersonalSubmit && ( !required && emailValue != reEnterValue )){
      return this.CONSTANTS.reEnterEmailError;
    }
    if(!!this.editPersonalSubmit && !!required){
      return this.CONSTANTS.reEnterEmailEmptyError;
    }else{return "";}
  }

  public showConfirmationModal() {
    this.userDetailsService.callOnloadService = true;
    this.drawerComponentCommunicatorService.updateState( DrawerOutputState.processSuccess );
  }

  public showWarningModal() {
    this.drawerComponentCommunicatorService.updateState( DrawerOutputState.processWarning );
  }

  public showUnexpectedErrorModal() {
    this.drawerComponentCommunicatorService.updateState( DrawerOutputState.processError );
  }

  public handleUpdatePersonalInfo() {
    this.editPersonalSubmit = true;
    if (this.isStatusChanged) {
      this.processUserStatus();
    }
    if(this.checkEmail) {
      if (this.hasPendingRequest) {
        return;
      }

      this.hasPendingRequest = true;

      this.profile.cellPhone = this.profile.cellPhone === '' ? null : this.profile.cellPhone;
      let oldEmail =  this.currentEmailId != this.profile.emailId ? this.currentEmailId : null;
      this.userDetailsService.updateProfile(this.profile, oldEmail, this.isStatusChanged)
        .finally(() => this.hasPendingRequest = false)
        .subscribe(response => this.processEditProfileSubmitReponse(response));
    }
  }

  public processUserStatus(): void {
    if(this.currentProfile.userStatus === UserStatus.LOCKED_OUT && this.profile.shouldActivateUser) {
      this.profile.userStatus = UserStatusAction.UNLOCKED;
    } else if (this.currentProfile.userStatus === UserStatus.SUSPENDED && this.profile.shouldActivateUser) {
      this.profile.userStatus = UserStatusAction.UNSUSPENDED;
    } else {
      this.profile.userStatus = UserStatus.ACTIVE;
    }
  }

  get checkEmail(): boolean {
    let ngForm = this.ngForm;
    return !ngForm.invalid && ( this.checkEmailReEnterEmailId || ( !this.checkEmailReEnterEmailId && !ngForm.control.controls.reEnterEmailId ))
  }

  public processEditProfileSubmitReponse(response): void {
    if (get(response, 'error.exceptions')) {
      this.showUnexpectedErrorModal();
    } else {
      this.drawerState = false;
      let serviceResponse = response['resource'];
      this.currentProfile.firstName = serviceResponse.firstName;
      this.currentProfile.lastName = serviceResponse.lastName;
      this.currentProfile.cellPhone = serviceResponse.mobilePhone;
      this.currentProfile.emailId = serviceResponse.email;
      this.showConfirmationModal();
    }
  }

  get isMobileNoAddedOrChanged(): boolean {
    return this.currentProfile.cellPhone != this.profile.cellPhone
  }

  get checkEmailReEnterEmailId(){
    return this.profile.emailId === this.profile.reEnterEmailId;
  }

  get isFormChanged() {
    this.drawerComponentCommunicatorService.isFormChanged = this.ngForm.dirty;
    return true;
  }

  public get totalNumberErrors(): number {
    let controls = this.ngForm.controls;
    return filter(controls, (control) => control['invalid']).length;
  }

  public get displayFormErrorsMessage(): string {
    let isPlural = this.totalNumberErrors > 1,
      tmplName = isPlural ? 'formInvalidPlural' : 'formInvalidSingular';
    return template(this.CONSTANTS[tmplName])({ n: this.totalNumberErrors });
  }

  public restrictFullName( event : any ) {
    var regex = /[a-zA-Z]/;
    let inputChar = String.fromCharCode(event.charCode);
    if ([0, 8].indexOf(event.charCode) !== -1) return;

    if (event.charCode != 32 && !regex.test(inputChar)) {
      event.preventDefault();
    }
  }

  public restrictCellPhone( event : any ) {
    var regex = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if ([0, 8].indexOf(event.charCode) !== -1) return;

    if (!regex.test(inputChar)) {
      event.preventDefault();
    }
  }

  get isStatusChanged(): boolean {
    return this.profile.shouldActivateUser;
  }

  get isBranch (): boolean {
    return this.sessionManagementService.isBranchUser;
  }

  get isDealer (): boolean {
    return this.sessionManagementService.isDealershipUser;
  }

  get disabled(): boolean {
    return this.profile.userType !== 'D';
  }
}
