import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import {Observable} from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';
import { pick, partialRight, map } from 'lodash';

import { HttpErrorHandlerService, HandleError } from '../../../services/http-error-handler.service';

import { IUserDetails } from '../../models/user-details.interface';
import { IApplicationsReportList } from "../../../profile/models/applications-report-list.interface";
import { IUserInfo } from "../../../profile/models/user-info.interface";
import { IApplicationToRequestByAdmin } from '../../models/application-to-request-by-admin.interface';
import { IUserGroupsList } from '../../models/user-groups-list.interface';
import { DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service'
import { SpinnerService } from '../../../loading-spinner/services/spinner/spinner.service';
import { ProfileDealerRole, ProfileAllyRole } from '../../../models/profile.interface';
import { AddAppsService } from '../../../profile/services/add-apps.service';
import { ProfileUserType } from '../../../models/profile.interface';
import 'rxjs/add/operator/finally';
import { SessionManagementService } from '../../../services/session-management.service';
import { IPendingRequest } from '../../models';

export type ProfileDealershipRole = keyof {
  'Dealership User',
  'Dealership Admin'
}
export namespace ProfileDealershipRole {
  export const user: ProfileDealershipRole = 'Dealership User';
  export const admin: ProfileDealershipRole = 'Dealership Admin';
}

export type AppDrawerOutputState = keyof {
  SingleAddAppSuccess,
  MultipleAddAppSuccess,
  AppError,
}
export namespace AppDrawerOutputState {
  export const processError: AppDrawerOutputState = 'AppError';
  export const processSingleAddAppSuccess: AppDrawerOutputState = 'SingleAddAppSuccess';
  export const processMultipleAddAppSuccess: AppDrawerOutputState = 'MultipleAddAppSuccess';
}

export type StorePdnKey = keyof {
  pdn,
  primary,
  secondary
}
export namespace StorePdnKey {
  export const pdn: StorePdnKey = "pdn";
  export const primary: StorePdnKey = "primary";
  export const secondary: StorePdnKey = "secondary";
}

@Injectable()
export class UserDetailsService {

  private handleError;
  public userName: string;
  public userType: string;
  public userPdn: string;
  public userId: string;
  public dealershipName: string;
  public dealerRole: ProfileDealershipRole;
  public allyRole: ProfileAllyRole;

  public displayRemoveAppModal : boolean = false;
  public callOnloadService : boolean = false;
  public callOnloadServiceWithPasswordSuccess : boolean = false;

  public selectedApps: IApplicationToRequestByAdmin[];

  // Varibles for Remove primary and secondary apps
  public selectedDealershipName: string;
  public selectedDealershipPdn: string;
  public selectedDealershipType: string;
  public isPendingAppsAvailable: boolean;
  public isAllAppsSelected: boolean;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    public httpErrorHandler: HttpErrorHandlerService,
    private sessionManager: SessionManagementService,
    private drawerComponentCommunicatorService : DrawerComponentCommunicatorService,
    private spinnerService: SpinnerService,
    private addAppsService : AddAppsService
  ) {
    this.handleError = httpErrorHandler.createHandleError('getUserProfile');
   }


  resolve(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<any> {
    this.spinnerService.show();
    let includeParamValue = 'factors';
    if (this.userType != ProfileUserType.branch) {
      includeParamValue = `${includeParamValue},PDN_WITH_APPS&requestedBy=${this.sessionManager.userRole}`;
    }
    let getUserDataUrl = `${environment.ndaServerURLS.userServices}/user/${this.slicedUserName}?include=${includeParamValue}`;

    return this.http.get<IUserInfo[]>(getUserDataUrl,this.httpOptions)
    .finally(() => this.spinnerService.hide())
    .pipe(
      catchError(this.handleError('getUserProfile', []))
    );

  }

  /**
   * GetAllApplications - Used to fetch all applications correspnds to a specific user
   */
  public getAllApplications() {
    let getUserAppsUrl = `${environment.ndaServerURLS.userServices}/user/${this.userId}/all-apps?userName=${this.slicedUserName}`;

    return this.http.get<IUserInfo>(getUserAppsUrl, this.httpOptions)
      .pipe(
        catchError(this.handleError('getUserApplications', []))
      );
   }

   /**
    * slicedUserName - Used to scrap the username alone from okta login attribute
    */
   get slicedUserName() {
     if(this.userName) {
      return this.userName.indexOf('@') > 0 ? this.userName.substr(0, this.userName.indexOf('@')) : this.userName;
     } else{
      return null;
     }
  }

  /**
   *  RemoveUser - removes the users associated with the specific dealership
   */
  public removeUser() {

    let requestPayLoad = {
      resource: {
        requestedBy :  this.sessionManager.profile.userName,
        requestedUserType :this.sessionManager.profile.userType
      }
    };

    let removeUserUrl = `${environment.ndaServerURLS.userServices}/user/${this.slicedUserName}`;

    return this.http.request('delete', removeUserUrl, { body: requestPayLoad })
    .pipe(
      catchError(this.handleError('removeUser', []))
    );
  }

  /**
   *  AddApplications - adds the requested application to the user directly in OKTA
   */
  public addApplication(isSecondaryDealerFlow ?: boolean) {
    let requestPayLoad = {
      resource: {
        requestedBy :  this.sessionManager.profile.userName,
        requestedUserType :this.sessionManager.profile.userType,
        isRequestedByAdmin : true,
        userName : this.slicedUserName,
        userType : this.userType
      }
    };

    if(this.isAllyRole) {
      requestPayLoad.resource['applicationDetails'] = this.prepareAddApplicationList();
      if (isSecondaryDealerFlow) {
        requestPayLoad.resource['dealership'] = {
          pdnNumber : this.addAppsService.pdn
        };
      }
    } else {
      requestPayLoad.resource['pdnWithApplications'] = [{
      pdnNumber : this.selectedDealershipPdn,
      dealershipName : this.selectedDealershipName,
      dealershipType : this.selectedDealershipType,
      isSecondaryPdnAppsEmpty : this.isAllAppsSelected && !this.isPendingAppsAvailable,
      applicationDetails : this.prepareAddApplicationList() }];
    }
    let addAppUrl = `${environment.ndaServerURLS.userServices}/user/${this.slicedUserName}/apps`;
    return this.http.post(addAppUrl, requestPayLoad, this.httpOptions, )
    .pipe(
      catchError(this.handleError('addApplication', []))
    );
  }

  public addApplicationViaWorkflow(isSecondaryDealerFlow : boolean, profile :IUserDetails) {
    let requestPayload;

    if (isSecondaryDealerFlow) {
      requestPayload = {
        firstName : profile.firstName,
        lastName : profile.lastName,
        email : profile.emailId,
        mobile : this.processMobile(profile.cellPhone),
        userName : this.slicedUserName,
        oeId : this.selectedDealershipPdn,
        dealershipName : this.selectedDealershipName,
        oeIdType : 'secondary',
        applications : this.prepareWorkflowAddApplicationList(this.selectedDealershipPdn, true)
      }
    } else {
      requestPayload = {
        userName : this.slicedUserName,
        oeId : this.selectedDealershipPdn,
        applications : this.prepareWorkflowAddApplicationList(this.selectedDealershipPdn, false)
      }
    }

    const url = `${environment.ndaServerURLS.workflowServices}/workflow/users/${profile.userId}/application-request`;
    return this.http.post<IApplicationToRequestByAdmin>( url, requestPayload, this.httpOptions)
      .pipe(
        catchError(this.handleError('addApplicationWorkflow', []))
      );

  }

  public addSecondaryDealershipViaWorkFlow(profile :IUserDetails ) {

    const requestPayload = {
      firstName : profile.firstName,
      lastName : profile.lastName,
      email : profile.emailId,
      mobile : this.processMobile(profile.cellPhone),
      userName : profile.userName,
      oeId : this.selectedDealershipPdn,
      dealershipName : this.selectedDealershipName,
      oeIdType : 'secondary',
      applications : this.prepareWorkflowAddApplicationList(this.selectedDealershipPdn, true)
    }

    const url = `${environment.ndaServerURLS.workflowServices}/workflow/dealer-request`;
     return this.http.post( url, requestPayload, this.httpOptions)
      .pipe(
        catchError(this.handleError('addSecondaryDealership', []))
      );
   }

  public processMobile(cellPhone : string) {
    return cellPhone == null || cellPhone == "" ? "0" : cellPhone;
  }

  /**
   *  AddSecondaryDealership - adds the requested application to the user directly in OKTA
  */
  public addSecondaryDealership() {
    let requestPayLoad = {
      resource: {
        applicationDetails : this.prepareAddApplicationList(),
        requestedBy :  this.sessionManager.profile.userName,
        requestedUserType :this.sessionManager.profile.userType,
        isRequestedByAdmin : true,
        userName : this.slicedUserName,
        userType : this.userType,
        dealership : {
          pdnNumber : this.addAppsService.pdn
        }
      }
    };

    requestPayLoad.resource['pdnWithApplications'] = [{
      pdnNumber : this.selectedDealershipPdn,
      dealershipName : this.selectedDealershipName,
      dealershipType : 'Secondary',
      isSecondaryPdnAppsEmpty : false,
      isAddSecondaryDealer : true,
      applicationDetails : this.prepareAddApplicationList() }];

    let addAppUrl = `${environment.ndaServerURLS.userServices}/user/${this.slicedUserName}/add-secondary-dealership`;
    return this.http.post(addAppUrl, requestPayLoad, this.httpOptions, )
    .pipe(
      catchError(this.handleError('addSecondaryDealership', []))
    );
  }

  /**
   *  RemoveApplications - removes the requested application from the user
   */
  public removeApplication() {

    let requestPayLoad = {
      resource: {
        requestedBy :  this.sessionManager.profile.userName,
        requestedUserType :this.sessionManager.profile.userType,
        isRequestedByAdmin : true,
        userName : this.slicedUserName,
        userType : this.userType
      }
    };

    if(this.isAllyRole) {
      requestPayLoad.resource['applicationDetails'] = this.prepareRemoveApplicationList();
        requestPayLoad.resource['dealership'] = {
          pdnNumber : this.userPdn,
          dealershipName : this.dealershipName
        };
    } else {
      requestPayLoad.resource['pdnWithApplications'] = [{
      pdnNumber : this.selectedDealershipPdn,
      dealershipName : this.selectedDealershipName,
      dealershipType : this.selectedDealershipType,
      isSecondaryPdnAppsEmpty : this.isAllAppsSelected && !this.isPendingAppsAvailable,
      applicationDetails : this.prepareRemoveApplicationList() }];
    }
    let removeAppUrl = `${environment.ndaServerURLS.userServices}/user/${this.slicedUserName}/apps`;

    return this.http.request('delete', removeAppUrl, { body: requestPayLoad })
    .pipe(
      catchError(this.handleError('removeApplication', []))
    );
  }

  /**
   *  getUserGroups - lists the groups of the requested user
  */
  public getUserGroups(isSelf ?: boolean) {

    let userId = isSelf ? this.sessionManager.profile.userId : this.userId;
    let requestorRole = isSelf ? '' : `?requestedBy=${this.sessionManager.userRole}`;

    return this.http.get<IUserGroupsList[]>(`${environment.ndaServerURLS.userServices}/user/${userId}/groups${requestorRole}`, this.httpOptions)
      .pipe(
        catchError(this.handleError('getUserGroups', []))
      );
  }

  /**
   *  updateProfile - updates the profile of the requested user
  */
  public updateProfile(profile: IUserDetails, oldEmail: String, isStatusChanged: boolean) {

    let request = {
      'resource': {
        'firstName' : profile.firstName,
        'lastName': profile.lastName,
        'mobilePhone': profile.cellPhone,
        'email': profile.emailId,
        'oldEmail': oldEmail,
        'isRequestedByAdmin': true,
        'requestedUserType': this.sessionManager.profile.userType,
        'requestedBy': this.sessionManager.profile.userName
      }
    };

    if (isStatusChanged) {
      request.resource['userStatus'] = profile.userStatus;
    }

    let userId = this.userId;
    const url = `${environment.ndaServerURLS.userServices}/user/${userId}`;

    return this.http.put<IUserDetails[]>(url, request , this.httpOptions)
      .pipe(
        catchError(this.handleError('admin updateProfile', []))
      );
   }

  /**
   *  createTemporaryPassword - creates temporary password for the requested user
  */
  public createTemporaryPassword(profile: IUserDetails, password: string) {

    let request = {
      'resource': {
        'firstName' : profile.firstName,
        'lastName': profile.lastName,
        'mobilePhone': profile.cellPhone,
        'password': password,
        'email': profile.emailId,
        'isRequestedByAdmin': true,
        'requestedUserType': this.sessionManager.profile.userType,
        'requestedBy': this.sessionManager.profile.userName
      }
    };

    const url = `${environment.ndaServerURLS.userServices}/user/${this.userName}/create-temporary-password`;

    return this.http.put(url, request , this.httpOptions)
      .pipe(
        catchError(this.handleError('createTemporaryPassword', []))
      );
   }

  public prepareAddApplicationList() {
    return <string[]> map(this.selectedApps, partialRight(pick, ['name', 'role', 'region', 'auctionAccessId']));
  }

  public prepareRemoveApplicationList() {
    return <string[]> map(this.selectedApps, partialRight(pick, ['name']));
  }

  public prepareWorkflowAddApplicationList(pdn : string, isAddOeidType: boolean) {
    let processedList = [];
    let applicationToRequestList;

    for( let i = 0; i < this.selectedApps.length; i++ ) {
      applicationToRequestList = {
        applicationName : this.selectedApps[i].name,
        oeId: pdn,
        isChecked : false,
        roleName : this.selectedApps[i].role
      };

      if (isAddOeidType) {
        applicationToRequestList['oeIdType'] = 'secondary';
      }
      processedList.push(applicationToRequestList);
    }
    return processedList;
  }

  public updateState(state: AppDrawerOutputState): void {
    this.drawerComponentCommunicatorService.drawerState = this.drawerComponentCommunicatorService.drawerComponentDestroyed = this.drawerComponentCommunicatorService.drawerComponentLoaded = false;
    this.drawerComponentCommunicatorService.drawerComponentOutputState = state;
  }

  get isDealerRole(): boolean {
    return !!this.dealerRole;
  }

  get isAllyRole(): boolean {
    return !!this.allyRole;
  }

  getSearchedUserPendingRequests(userNameToSearch: string) {
      let action = 'searchuser-pending-requests' ;
      const url = `${environment.ndaServerURLS.workflowServices}/workflow/${action}`;
      const userDetails = { userName: userNameToSearch};
      return this.http.post<IPendingRequest[]>(url, userDetails, this.httpOptions);
  }
}
