import { Component, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { filter, cloneDeep, startsWith, includes, map, pick, partialRight, get, sortBy } from 'lodash';
import 'rxjs/add/operator/finally';

import { IApplicationToRequest } from '../../models/application-to-request.interface';
import { AppConstantsService } from '../../../services/app-constants.service';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { AddApplicationReportsService } from '../../services/add-application-reports.service';
import { DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { AddAppsService } from '../../services/add-apps.service';
import { IUserDetails } from '../../../manage-users/models/user-details.interface';
import { SmartAuctionDialog } from '../smartauction-dialog/smartauction-dialog.component'

import { ViewAddApplicationsService } from '../../services/view-add-applications.service';
import { IUserGroupsList } from '../../models/user-groups-list.interface';
import { IPdnAdminEmailsResource } from '../../models/pdn-admin-emails-resource.interface';
import { Observable } from 'rxjs/Observable';
import { SessionManagementService } from '../../../services/session-management.service';
import { IAppRoles } from '../../models/app-roles.interface';
import { isTemplateExpression } from 'typescript';

export type PdnTypePrefix = keyof {
  'ADM_',
  'DLR_'
}
export namespace PdnTypePrefix {
  export const admin: PdnTypePrefix = 'ADM_';
  export const dealer: PdnTypePrefix = 'DLR_';
}

@Component({
  selector: 'nda-add-application-reports',
  templateUrl: './nda-add-application-reports.component.html',
  styleUrls: ['./nda-add-application-reports.component.scss']
})
export class NdaAddApplicationReportsComponent implements OnInit {
  checkTheBox: boolean = false;
  drawerState: boolean = false;
  shouldShowSmartAuctionRoles: boolean = false;
  shouldShowSmartCashRoles: boolean = false;
  shouldShowAllyRideReturnRoles: boolean = false;
  shouldShowAuctionAccessButton: boolean = false;
  shouldShowAuctionAccessInfo: boolean = false;
  smartCashRoleName: string = '';
  smartCashRegionName: string = '';
  allyRideReturnRoleName: string = '';
  public updateSCRole: string;
  readonly CONSTANTS = AppStrings['applicationAndReports'];
  readonly OPTIONS = AppStrings['registration']['dealership']['smartAuctionSecondaryRoles'];
  public applicationToRequest: IApplicationToRequest[];
  public isAddSecondaryApps: boolean;
  public profileObj: IUserDetails;
  public secondaryDealershipName: string;
  public groupsList: IUserGroupsList[];
  public admGroups: IUserGroupsList[];
  public adminEmailsResource: IPdnAdminEmailsResource;
  public adminEmails: string[];
  public adminPdns: IUserGroupsList[];
  public isSingleAdmin: boolean;
  public smartAuctionAppIndex: number = 0;
  public innerWidth: number = 0;

  private hasPendingRequest: boolean = false;

  //Set first two initially to "null" to indicate they are not used.
  public secondaryRoleValue: string = null;
  public auctionAccessIDValue: number = null;

  @Input('data') data: IApplicationToRequest[];
  @ViewChild('selectRequestAccessForm', { static: true }) public ngForm;
  @ViewChild("smartAuctionDialog", { static: true }) smartAuctionDialog: SmartAuctionDialog;

  constructor(
    private sessionManagementService: SessionManagementService,
    private appConstantsService: AppConstantsService,
    private addApplicationReportsService: AddApplicationReportsService,
    private drawerCommunicatorService: DrawerComponentCommunicatorService,
    private addAppsService: AddAppsService,
    private viewAddApplicationsService: ViewAddApplicationsService
  ) { this.groupsList = this.viewAddApplicationsService.groupsList }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.isAddSecondaryApps = this.data['isAddSecondaryApps'];
    this.profileObj = this.data['profileObj'];
    this.secondaryDealershipName = this.data['secondaryDealershipName'];
    this.shouldShowSmartAuctionRoles = false;
    this.shouldShowSmartCashRoles = false;
    this.shouldShowAllyRideReturnRoles = false;
    this.shouldShowAuctionAccessButton = false;
    this.shouldShowAuctionAccessInfo = false;
    this.applicationToRequest = cloneDeep(sortBy(this.data['applicationToRequest'], 'applicationName'));

    // Add 'secondaryRole' and 'auctionAccessID' fields to the form locally if SmartAuction is an available app.
    for (let i = 0; i < this.applicationToRequest.length; i++) {
      if (this.applicationToRequest[i].applicationName === 'SmartAuction') {
        this.smartAuctionAppIndex = i;
        this.applicationToRequest[i]['secondaryRole'] = null;
        this.applicationToRequest[i]['auctionAccessId'] = null;
      };
    };
  }


  handlerSubmitForm() {
    if (this.selfAdminCheck()) {
      this.addApplicationReportsService.getPdnAdminEmails(this.data['pdn'])
        .subscribe(response => {
          if (response['resource']) {
            this.adminEmails = response['resource']['adminMails'];
            if (this.adminEmails && this.adminEmails.length === 1 && this.adminEmails[0] === this.sessionManagementService.profile.email) {
              this.callAddApplicationsOktaService();
            } else {
              this.callWorkflowAddAppsService();
            }
          }
        });

    } else {
      this.callWorkflowAddAppsService();
    }

  }

  /**
   * callWorkflowAddAppsService - To call workflow add apps service
   */
  public callWorkflowAddAppsService() {
    let request;

    if (this.isAddSecondaryApps) {
      request = this.addApplicationReportsService.addSecondaryApps(this.profileObj, this.processSecondaryApps(this.selectedApplications, this.data['pdn']), this.data['pdn'], this.secondaryDealershipName);
    }
    else {
      request = this.addApplicationReportsService.postApplicationRequest(this.selectedApplications, this.data['pdn']);
    }

    if (this.hasPendingRequest) {
      return;
    }

    this.hasPendingRequest = true;

    request
      .finally(() => this.hasPendingRequest = false)
      .subscribe(response => this.processAddAppsResponse(response));
  }

  /**
   * callAddApplicationsOktaService - to call okta add apps service
   */
  public callAddApplicationsOktaService() {
    if (this.hasPendingRequest) {
      return;
    }

    this.hasPendingRequest = true;

    this.addApplicationReportsService.addApplication(this.data['pdn'], this.isAddSecondaryApps, this.secondaryDealershipName, this.selectedApplications)
      .finally(() => this.hasPendingRequest = false)
      .subscribe(response => this.processAddAppsResponse(response));
  }

  /**
   * processAddAppsResponse - Process service response
   * @param response
   */
  public processAddAppsResponse(response) {
    if (response == null || !get(response, 'error.exceptions')) {
      this.addAppsService.isAppAccessRequest = 'Success';
    } else {
      this.addAppsService.isAppAccessRequest = 'Error';
    }
  }

  public processSecondaryApps(apps: IApplicationToRequest[], pdn: string) {
    let processedList = [];
    let applicationToRequestList: IApplicationToRequest;

    for (let i = 0; i < apps.length; i++) {
      applicationToRequestList = {
        applicationName: apps[i].applicationName,
        oeId: pdn,
        oeIdType: 'secondary',
        isChecked: apps[i].isChecked,
        roleName: apps[i].roleName,
      };
      processedList.push(applicationToRequestList);
    }
    return processedList;
  }

  get isApplicationSelected(): boolean {
    return !!this.selectedApplications.length;
  }

  get selectedApplications(): IApplicationToRequest[] {
    return this.applicationToRequest.filter((item) => item['isChecked']);
  }

  showRegionSection(item: IApplicationToRequest): boolean {
    return item.isChecked && item.selectedRole['hasRegion'] == 'Y'
  }

  get isRoleWithoutRegion(): boolean {
    return this.smartCashRoleName == "EO" || this.smartCashRoleName == "EO Basic" || this.smartCashRoleName == "Support" || this.smartCashRoleName == "";
  }

  setWarningModalFlag() {
    this.drawerCommunicatorService.isFormChanged = this.isApplicationSelected;
  }

  updateRolename(item: IApplicationToRequest) {
    item.roleName = item.roles ? item.selectedRole['roleName'] : '';
  }

  // Changes secondaryRole based on chosen option in select list.
  updateSecondaryRolename(event): void {
    this.applicationToRequest[this.smartAuctionAppIndex]['secondaryRole'] = event.target.value;
    this.secondaryRoleValue = event.target.value;
  }

  // Changes auctionAccessID numeric value based on current input (only allows numbers).
  updateAuctionAccessID(event): void {
    this.applicationToRequest[this.smartAuctionAppIndex]['auctionAccessId'] = event.target.value;
    this.auctionAccessIDValue = event.target.value;
  }

  isSmartAuction(applicationName: string): boolean {
    return applicationName === this.appConstantsService.dealerAppNames['SA'];
  }

  // Show roles in dropdown menu if given app has roles.
  showRolesSection(app: IApplicationToRequest): boolean {
    return app.isChecked && app.roles && app.roles.length > 0
  }

  selectSmartAuctionRole(event): boolean {
    return !!event.target.value;
  }

  selectSmartAuctionSecondaryRole(event): boolean {
    return !!event.target.value;
  }

  isSmartCash(applicationName: string): boolean {
    return applicationName === this.appConstantsService.dealerAppNames['SC'];
  }

  isAllyRideReturn(applicationName: string): boolean {
    return applicationName === this.appConstantsService.dealerAppNames['SG'];
  }

  selectSmartCashRole(event): boolean {
    this.updateSCRole = event.target.value;
    return !!event.target.value;
  }

  selectAllyRideReturnRole(event): boolean {
    return !!event.target.value;
  }

  // Show secondary roles if given app has roles AND has selected name 'Dealer'.
  showSecondaryRolesSection(app: IApplicationToRequest): boolean {
    return this.showRolesSection(app) && app.roleName == 'Dealer';
  }

  // Show 100 M number (AuctionACCESS ID) input field if given app has roles AND has selected name 'Buyer' and/or 'Buyer and Seller'.
  showAuctionAccessIDField(app: IApplicationToRequest): boolean {
    return this.showSecondaryRolesSection(app) && this.shouldShowAuctionAccessID();
  }

  // Helper function to determine if AuctionAccess ID field should be shown based on which option is selected.
  shouldShowAuctionAccessID(): boolean {
    if (this.secondaryRoleValue == 'Buyer' || this.secondaryRoleValue == 'Buyer and Seller') {
      return true;
    } else {
      // If field is not shown, purge values so they are not submitted.
      this.applicationToRequest[this.smartAuctionAppIndex]['auctionAccessId'] = null;
      this.auctionAccessIDValue = null;
      return false;
    };
  }

  determineTooltipMargin(): void {
    var element = document.getElementById("tooltipHolder");

      // If page size shrinks to mobile view, purge massive margins to make the view less silly.
      if (this.innerWidth < 768) {
        element.style.removeProperty("margin-top");
      };

      // If page size grows past mobile view, re-add massive margins to make the view correct again.
      if (this.innerWidth >= 768) {
        element.style.marginTop = '14.5625rem';
      };
  }

  // Dynamically grab window size as window size is changed.
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;

    if (this.shouldShowAuctionAccessID()) {
      this.determineTooltipMargin();
    };
  }

  // Show AuctionACCESS ID Info Tooltip
  showAuctionAccessInfo(): void {
    this.shouldShowAuctionAccessInfo = true;
  }

  // Receive from child component to hide AuctionACCESS ID Info Tooltip
  hideAuctionAccessInfo(val: boolean): void {
    this.shouldShowAuctionAccessInfo = val;
  }

  get smartAuctionRoles() {
    if (this.sessionManagementService.isBranchUser) {
      return this.appConstantsService.smartAuctionAllyUserRoles;
    };
    return this.appConstantsService.smartAuctionDealershipRoles;
  }

  // Note: SmartAuction secondary roles will only appear for dealers.
  get smartAuctionSecondaryRoles() {
    if (this.sessionManagementService.isDealershipUser) {
      return this.appConstantsService.smartAuctionDealershipSecondaryRoles;
    };
    return null;
  }

  get smartCashRoles() {
    if (this.sessionManagementService.isBranchUser) {
      return this.appConstantsService.smartCashAllyUserRoles;
    };
    return null;
  }

  get smartCashAllyUserRegion() {
    if (this.sessionManagementService.isBranchUser) {
      return this.appConstantsService.smartCashAllyUserRegion;
    };
    return null;
  }

  get allyRideReturnRoles() {
    if (this.sessionManagementService.isDealershipUser) {
      return this.appConstantsService.allyRideReturnDealershipRoles;
    };
    return null;
  }

  get hasUnassignedApplications(): boolean {
    return this.applicationToRequest ? this.applicationToRequest.length > 0 : false;
  }

  public getAdminPdns(): void {
    this.admGroups = filter(this.groupsList, function (groups) {
      return startsWith(groups.name, PdnTypePrefix.admin);
    });
  }

  //this function will check whether the loogged in user is admin for the requested dealership
  public selfAdminCheck(): boolean {

    this.getAdminPdns();
    let userPdn = this.data['pdn'];
    this.adminPdns = this.admGroups.filter(group => includes(group.name, userPdn));
    return this.adminPdns && this.adminPdns.length > 0;
  }

  // Problem-child Firefox requires this function to restrict input to only numbers and backspace + direction arrows.
  filterInput(event: any) {
    let numberEntered = false;
    // Input number entered or one of the 4 directtion up, down, left and right.
    if ((event.which >= 48 && event.which <= 57) || (event.which >= 37 && event.which <= 40)) {
      numberEntered = true;
    }
    else {
      // Input entered that is *not* of direction arrows, delete, or backspace.
      if (!((event.keyCode >= 37 && event.keyCode <= 40) || event.keyCode == 46 || event.which == 8)) {
        event.preventDefault();
      }
    }
  }

  public addAppsErrorMessage(submitted, invalid){
    if(!!submitted && !!invalid){
      return this.CONSTANTS.selectARole;
    }else{return ""}
  }

  

  public secondaryRoleErrorMessage(submitted, invalid){
    if(!!submitted && !!invalid){
      return this.CONSTANTS.selectARole;
    }else{return ""}
  }

  public selectAccessErrorMessage(submitted, invalid, minLength){
    if (!!submitted && !!invalid && !minLength){
      return this.CONSTANTS.invalidAuctionAccessIDError;
    }
    if(!!submitted && !!minLength){
      return this.CONSTANTS.minlengthAuctionAccessIDError;
    }else{return "";}
  }

  public formErrorMessage(submitted){
    if(!!submitted && !this.isApplicationSelected){
      return this.CONSTANTS.selectARole;
    }else{return ""}
  }
}
