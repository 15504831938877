import { EventTargetInterruptSource, EventTargetInterruptOptions } from "@ng-idle/core";
import { ElementRef } from "@angular/core";

/*
 * Custom interrupt source that uses events on the document element (html tag).
 *
 * This has been modeled after DocumentInterruptSource that hardcode `document.documentElement`
 * as the first argument. We may need to create a custom interrupt from the document of another frame
 * other than the document on window.
 *
 * Original source for DocumentInterruptSource: https://github.com/HackedByChinese/ng2-idle/blob/master/modules/core/src/documentinterruptsource.ts
 */
class CustomDocumentInterruptSource extends EventTargetInterruptSource {
  constructor(el: ElementRef, events: string = DEFAULT_EVENTS, options?: number | EventTargetInterruptOptions) {
    super(el, events, options);
  }

  /*
   * Checks to see if the event should be filtered.
   * @param event - The original event object.
   * @return True if the event should be filtered (don't cause an interrupt); otherwise, false.
   */
  filterEvent(event: any): boolean {
    // some browser bad input hacks
    if (event.type === 'mousemove'
      // fix for Chrome destop notifications
      && ((event.originalEvent && event.originalEvent.movementX === 0 &&
        event.originalEvent.movementY === 0)
        // fix for webkit fake mousemove
        || (event.movementX !== void 0 && !event.movementX || !event.movementY))) {
      return true;
    }

    return false;
  }
}

const DEFAULT_EVENTS: string = 'mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll';
export default CustomDocumentInterruptSource;
