import * as tslib_1 from "tslib";
import { EventEmitter, ElementRef, AfterViewInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie';
import { OtpService } from '../../services/otp/otp.service';
import { StoreService } from '../../../services/store.service';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { AppConstantsService } from '../../../services/app-constants.service';
import { MaskEmailPipe } from '../../pipes/mask-email.pipe';
import { AuthenticationService } from '../../../services/authentication.service';
import { BookmarkingService } from '../../../services/bookmarking.service';
import { environment } from '../../../../environments/environment';
import { extend, find, first, filter, pick, reject, template, cloneDeep, get } from 'lodash';
import { SpinnerService } from '../../../loading-spinner/services/spinner/spinner.service';
import { PasswordWarnComponent } from '../../../components/password-warn/password-warn.component';
import { PasswordStatus, PasswordExpiryService } from '../../../services/password-expiry.service';
import { PreLoginUserDetailsService } from '../../../change-password-pre-login/services/pre-login-user-details.service';
import { SessionManagementService } from '../../../services/session-management.service';
var FactorStatus = ['ACTIVE', null,];
export var CookieNameOtp;
(function (CookieNameOtp) {
    CookieNameOtp.deviceToken = "deviceToken";
})(CookieNameOtp || (CookieNameOtp = {}));
var ModalOtpComponent = /** @class */ (function () {
    function ModalOtpComponent(maskEmail, otpService, storeService, router, appConstantsService, authenticationService, bookmarkingService, cookieService, sessionManager, spinnerService, passwordService, preLoginUserDetailsService, cdRef, zone) {
        this.maskEmail = maskEmail;
        this.otpService = otpService;
        this.storeService = storeService;
        this.router = router;
        this.appConstantsService = appConstantsService;
        this.authenticationService = authenticationService;
        this.bookmarkingService = bookmarkingService;
        this.cookieService = cookieService;
        this.sessionManager = sessionManager;
        this.spinnerService = spinnerService;
        this.passwordService = passwordService;
        this.preLoginUserDetailsService = preLoginUserDetailsService;
        this.cdRef = cdRef;
        this.zone = zone;
        this.EMAIL = 'email';
        this.SMS = 'sms';
        this.PENDING_ACTIVATION = 'PENDING_ACTIVATION';
        this.NOT_SELECTED = 'NOT_SELECTED';
        this.hasPendingRequest = false;
        this.appStrings = AppStrings;
        this.isModalLoaded = false;
        this.type = '';
        this.path = '';
        this.headers = '';
        this.otpPattern = '^[0-9]*$';
        this.isSubmitClicked = false;
        this.isSendClicked = false;
        this.responseQry = [];
        this.sendOtpErr = [];
        this.factors = [];
        this._factorChoice = this.NOT_SELECTED;
        this.isEditProfileActive = false;
        this.timedOut = false;
        this.CONSTANTS = tslib_1.__assign({}, AppStrings['common'], { 'authErrors': AppStrings['authErrors'] });
        this.isComplete = new EventEmitter();
        this.onHidden = new EventEmitter();
        this.userModel = {
            resource: null,
            exceptions: null
        };
        this.otpModel = {
            otpNumber: null,
        };
    }
    Object.defineProperty(ModalOtpComponent.prototype, "userModel", {
        get: function () {
            return this._userModel.resource;
        },
        set: function (model) {
            this._userModel = model;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "userId", {
        get: function () {
            return this.userModel.sid || this.userModel.userId || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "userName", {
        get: function () {
            if (this.userModel.profile === undefined) {
                return this.sessionManager.userName;
            }
            return this.userModel.profile.login;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "stateToken", {
        get: function () {
            return this.userModel.stateToken || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "isRegistration", {
        get: function () {
            return this.flowMap.trigger == this.appConstantsService.otp_registration_flow.trigger;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "isLogin", {
        get: function () {
            return this.flowMap.trigger == this.appConstantsService.otp_login_flow.trigger;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "isForgotPassword", {
        get: function () {
            return this.flowMap.trigger == this.appConstantsService.otp_forgotPwd_flow.trigger;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "isEditProfile", {
        get: function () {
            if (!!this.flowMap) {
                return this.flowMap.trigger == this.appConstantsService.edit_profile_flow.trigger;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "hasDefaultSelection", {
        get: function () {
            return this.isRegistration || this.isEditProfile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "isDefaultSelectionSMS", {
        get: function () {
            return this.isRegistration && this.showSMSFactor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "isDefaultSelectionEmail", {
        get: function () {
            return this.isRegistration && !this.showSMSFactor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "factorChoice", {
        get: function () {
            if (this.isRegistration) {
                return this.isDefaultSelectionSMS ? this.smsFactorId : this.emailFactorId;
            }
            else if (this.isEditProfile) {
                return this.smsFactorId;
            }
            return this._factorChoice;
        },
        set: function (factorChoice) {
            this._factorChoice = factorChoice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "hasMultipleFactors", {
        get: function () {
            return this.factors.length > 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "shouldDisplaySendAlternateCode", {
        get: function () {
            var hasAlertnateFactors = this.hasMultipleFactors || !!this.userMobileNumber && this.canResendOTP;
            return this.isEditProfile ? false : (!this.isRegistration && hasAlertnateFactors);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "factorSelected", {
        get: function () {
            if (this.factorChoice == this.NOT_SELECTED) { // ಠ_ಠ this is gross
                return find(this.factors, ['factorType', this.EMAIL]);
            }
            else if (this.isEditProfile) {
                return this.factors[0];
            }
            return first(filter(this.factors, ['id', this.factorChoice])) || this.factorAdded; // ಠ_ಠ this is gross
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "factorAlternative", {
        get: function () {
            return first(reject(this.factors, ['id', this.factorChoice])) || this.factorAdded; // ಠ_ಠ this is gross;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "isFactorSelectedEmail", {
        get: function () {
            return this.factorSelected.factorType === this.EMAIL;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "isFactorSelectedSMS", {
        get: function () {
            return this.factorSelected.factorType === this.SMS;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "displayOtpSendAlternateCode", {
        get: function () {
            var STRINGS = this.appStrings.modalOtp;
            var method = this.isFactorSelectedEmail ? STRINGS.smsFactor : STRINGS.emailFactor;
            method = method.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
            return template(STRINGS.otpSendAlternateCode)({ method: method });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "showSMSFactor", {
        get: function () {
            return this.userMobileNumber && this.userMobileNumber !== '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "showSendError", {
        get: function () {
            return this.isSendClicked && this.factorChoice === this.NOT_SELECTED;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "emailFactorId", {
        get: function () {
            var _this = this;
            return this.factors.filter(function (factor) { return factor.factorType === _this.EMAIL; })[0].id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "hasOtpSubmissionException", {
        get: function () {
            return this.responseQry.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalOtpComponent.prototype, "smsFactorId", {
        get: function () {
            var _this = this;
            if (this.factors.find(function (factor) { return factor.factorType === _this.SMS; })) {
                return this.factors.find(function (factor) { return factor.factorType === _this.SMS; }).id;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    ModalOtpComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.ndaModal.onHidden.subscribe(function () { return _this.onHidden.emit(); });
    };
    ModalOtpComponent.prototype.redirectToDownstreamLogin = function () {
        if (this.downstreamAppIdentifier) {
            window.location.assign(environment.downstreamAppURL[this.downstreamAppIdentifier]['loginPageURL']);
        }
    };
    Object.defineProperty(ModalOtpComponent.prototype, "canResendOTP", {
        get: function () {
            return !!get(this, 'credentials.pwd') || !this.timedOut;
        },
        enumerable: true,
        configurable: true
    });
    ModalOtpComponent.prototype.hide = function () {
        this.sendOtpErr = [];
        this.otpModel.otpNumber = null;
        if (this.downstreamAppIdentifier) {
            this.redirectToDownstreamLogin();
        }
        else {
            this.ndaModal.hide();
        }
        if (this.spinnerService.isVisible) {
            this.spinnerService.hide();
        }
    };
    ModalOtpComponent.prototype.sendOtp = function (flow) {
        var _this = this;
        this.isSendClicked = true;
        if (this.showSMSFactor && this.factorChoice === this.NOT_SELECTED) {
            return;
        }
        if (!this.shouldEnrollFactor()) {
            return this.sendOTPToEnrolledFactor();
        }
        this.enrollFactorInOTP().subscribe(function (res) {
            _this.factorChoice = res.id;
            res['profile'] = { phoneNumber: _this.userMobileNumber };
            _this.setFactorInformation([res]);
            _this.isSendOtpScreen = false;
            _this.cdRef.detectChanges();
        });
    };
    ModalOtpComponent.prototype.sendOtpAlternate = function () {
        var _this = this;
        if (this.factorAlternative) {
            this.factorChoice = this.factorAlternative.id;
            this.sendOtp();
        }
        else {
            this.enrollFactorInOTP().subscribe(function () {
                _this.factorChoice = _this.factorAlternative.id;
            });
        }
    };
    ModalOtpComponent.prototype.submitOtp = function () {
        var _this = this;
        this.spinnerService.show();
        this.otpService.submitOtp(this.stateToken, this.userId, this.userName, this.factorSelected['id'], this.otpModel.otpNumber)
            .subscribe(function (response) { return _this.handleSubmitOtpSuccess(response); }, function (err) { return _this.handleSubmitOtpFailure(err); });
    };
    ModalOtpComponent.prototype.handleSubmitOtpSuccess = function (response) {
        var _this = this;
        if (response['resource']) {
            window.clearTimeout(this.timedOutTimeout);
            this.storeService.write('isEditProfileOtpVerified', true);
            var date = new Date();
            date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
            // app-guard.canActivate will call spinnerService.hide()
            if (this.isEditProfileFlow) {
                this.storeService.write("isEditProfileOtpVerified", true);
            }
            if (this.flowMap && (this.flowMap.trigger === 'login' || this.flowMap.trigger === 'resitration')) {
                if (response['resource']['sessionToken'] && response['resource']['sessionId']) {
                    this.sessionManager.update('sessionToken', response['resource']['sessionToken']);
                    this.sessionManager.update('sessionId', response['resource']['sessionId']);
                }
            }
            if (this.flowMap && this.flowMap.trigger === 'forgotPwd') {
                this.sessionManager.update('profile', { userId: this.userId });
            }
            this.ndaModal.hide();
            this.isComplete.emit();
            // Storing App identifier in service for Password Expire and Password warn downstream navigation
            if (this.isUserLoggedInFromDownStream) {
                this.passwordService.downstreamAppIdentifier = this.downstreamAppIdentifier;
            }
            // Storing status and password changed date if present to show ABOUT TO EXPIRE modal in case of PASSWORD_WARN status
            this.passwordService.status = response['resource']['status'];
            this.passwordService.passwordExpiryDate = get(response, 'resource.passwordExpiredDate');
            // Condition to check for PASSWORD_EXPIRED status and force navigate to Change Password screen
            if (response['resource']['status'] === PasswordStatus.PasswordExpired) {
                var userProfile = cloneDeep(response['resource'].profile);
                userProfile.userId = response['resource'].sid;
                this.preLoginUserDetailsService.profile = userProfile;
                this.spinnerService.hide();
                this.zone.run(function () {
                    _this.router.navigate([_this.appConstantsService.pre_login_change_password_path]);
                });
                this.spinnerService.hide();
            } // condition to split the navigation flow for downstream application and NDA
            else if (this.isUserLoggedInFromDownStream) {
                this.spinnerService.hide();
                if (response['resource']['status'] && response['resource']['status'] != PasswordStatus.PasswordWarn) {
                    var sessionToken = this.sessionManager.sessionToken;
                    window.location.assign(environment.downstreamAppURL[this.downstreamAppIdentifier]['landingPageURL'] + sessionToken);
                }
            }
            else if (this.flowMap && this.flowMap['routeTo'] && this.flowMap.trigger === 'login') {
                this.redirectPath = this.bookmarkingService.getBookmarkedUrl();
                this.bookmarkingService.resetBookmarkedUrl();
                this.zone.run(function () {
                    _this.router.navigate([_this.redirectPath]);
                });
            }
            else if (this.flowMap && this.flowMap['routeTo'] && this.flowMap.trigger !== 'resitration') {
                this.zone.run(function () {
                    _this.router.navigate([_this.flowMap['routeTo']]);
                });
            }
            else {
                this.spinnerService.hide();
            }
        }
    };
    ModalOtpComponent.prototype.handleSubmitOtpFailure = function (response) {
        var _this = this;
        var errorMessage;
        // The server returns a 400 on submission failure for SMS enrollment during registration.
        if (get(response, 'status') === 400) {
            errorMessage = get(this.CONSTANTS['authErrors'], this.timedOut ? 'ERR_000003' : 'ERR_000008');
        }
        else if (get(response, 'status') === 403) {
            errorMessage = get(this.CONSTANTS, 'authErrors.ERR_000008');
        }
        else if (get(response, 'error.exceptions')) {
            var exception = response['error']['exceptions'];
            errorMessage = get(this.CONSTANTS['authErrors'], exception.code) || exception.message;
        }
        setTimeout(function () { _this.cdRef.detectChanges(); }, 500);
        this.responseQry = [errorMessage];
        this.spinnerService.hide();
    };
    ModalOtpComponent.prototype.sendOTPToEnrolledFactor = function () {
        var _this = this;
        var factorId = this.findFactorId();
        window.clearTimeout(this.timedOutTimeout);
        this.timedOutTimeout = window.setTimeout(function () {
            _this.timedOut = true;
            _this.redirectToDownstreamLogin();
        }, 5 * 60 * 1000);
        this.otpService.sendOtp(this.stateToken, this.userId, factorId)
            .subscribe(function (response) {
            if (response === null) {
                _this.showEnterOtpCodeView();
                _this.isModalLoaded = true;
                _this.isSendOtpScreen = true;
                _this.isSendOtpScreen = false;
                _this.cdRef.detectChanges();
                // $$el.context.factorChoice = $$el.context.factors[0].id
            }
            else if (get(response, 'error.exceptions')) {
                _this.sendOtpErr = [response['error']['exceptions'].message];
            }
        }, function (error) {
            if (error.status === 401) {
                _this.authenticationService.getUserDetails(_this.credentials.userId, _this.credentials.pwd)
                    .do(function (response) {
                    _this.userModel.stateToken = response['resource'].stateToken;
                    _this.isSubmitClicked = false;
                    _this.responseQry = [];
                    _this.sendOtpErr = [];
                    _this.otpModel.otpNumber = null;
                    _this.otpValSecCode.form.markAsPristine();
                })
                    .subscribe(function () { return _this.sendOTPToEnrolledFactor(); }, function (err) {
                    if (err.status === 422) {
                        _this.hide();
                    }
                });
            }
        });
    };
    ModalOtpComponent.prototype.findFactorId = function () {
        if (this.factors.length === 1 && (this.userMobileNumber === undefined || this.userMobileNumber === '')) {
            return this.factors[0].id;
        }
        return this.factorChoice;
    };
    ModalOtpComponent.prototype.enrollFactorInOTP = function () {
        var _this = this;
        this.isEditProfileActive = false;
        window.clearTimeout(this.timedOutTimeout);
        this.timedOutTimeout = window.setTimeout(function () {
            _this.timedOut = true;
        }, 5 * 60 * 1000);
        return this.authenticationService.createSMSFactor(this.userId, this.userMobileNumber).map(function (factor) {
            _this.showEnterOtpCodeView();
            _this.isModalLoaded = true;
            var profile = { phoneNumber: factor['phoneNumber'] };
            var factorOption = extend({}, pick(factor, 'id', 'factorType'), { profile: profile });
            _this.factorAdded = factorOption;
            return factor;
        });
    };
    ModalOtpComponent.prototype.enterOtpNo = function (event) {
        this.responseQry = [];
        var otpPatternKeyStroke = /^[0-9]*$/;
        var inputChar = String.fromCharCode(event.charCode);
        if ([0, 8].indexOf(event.charCode) !== -1)
            return;
        if (event.charCode !== 118 && !otpPatternKeyStroke.test(inputChar)) {
            event.preventDefault();
        }
    };
    ModalOtpComponent.prototype.otpSubmit = function () {
        this.isSubmitClicked = true;
    };
    ModalOtpComponent.prototype.show = function (content, flow) {
        var _this = this;
        this.credentials = content.credentials;
        this.userModel = content.userModel;
        if (flow) {
            this.flowMap = flow;
        }
        this.setFactorInformation(this.userModel.factors);
        if (this.userModel.profile.mobilePhone) {
            this.userMobileNumber = this.userModel.profile.mobilePhone;
            this.SMSFactorType = this.SMS;
        }
        this.isSendOtpScreen = true;
        this.isModalLoaded = true;
        setTimeout(function () {
            _this.cdRef.detectChanges();
            _this.ndaModal.show();
        }, 500);
    };
    ModalOtpComponent.prototype.showOTP = function (content) {
        var _this = this;
        this.credentials = content.credentials;
        this.userModel = content.userModel;
        this.isModalLoaded = true;
        setTimeout(function () {
            _this.cdRef.detectChanges();
            _this.ndaModal.show();
        }, 900);
    };
    ModalOtpComponent.prototype.setFactorInformation = function (factors) {
        var _this = this;
        this.factors = factors;
        factors.map(function (factor) {
            _this.setEmailFactorInformation(factor);
            _this.setSMSFactorInformation(factor);
        });
    };
    ModalOtpComponent.prototype.setEmailFactorInformation = function (factor) {
        if (factor.factorType === this.EMAIL && factor.profile.email) {
            this.showEmailId = factor.profile.email;
            this.showEmailFactorType = factor.factorType;
        }
    };
    ModalOtpComponent.prototype.setSMSFactorInformation = function (factor) {
        if (factor.factorType === this.SMS && factor.profile.phoneNumber) {
            this.userMobileNumber = factor.profile.phoneNumber;
            this.SMSFactorType = factor.factorType;
        }
    };
    ModalOtpComponent.prototype.showMobileOtp = function (mobileNumber, userId, user, userOldInfo) {
        var _this = this;
        this.userMobileNumber = !!mobileNumber ? mobileNumber : null;
        this.userModel = {
            resource: user,
            exceptions: null
        };
        this.userModel['stateToken'] = this.sessionManager.sessionToken;
        this.userModel['userId'] = userId;
        this.userModel['login'] = this.sessionManager.userName;
        this.isEditProfileActive = false;
        this.storeService.write("isEditProfileOtpVerified", false);
        this.isEditProfileActive = true;
        if ((!!this.userMobileNumber && !!userOldInfo.cellPhone)) {
            this.createSMSFactor(this.userMobileNumber);
        }
        else if ((!!this.userMobileNumber && !!!userOldInfo.cellPhone)) {
            this.createSMSFactor(this.userMobileNumber);
        }
        else if ((!!!this.userMobileNumber && !!userOldInfo.cellPhone)) {
            this.createSMSFactor(this.userMobileNumber);
        }
        else {
            this.storeService.write("isEditProfileOtpVerified", true);
            setTimeout(function () {
                _this.isComplete.emit();
                _this.cdRef.detectChanges();
            }, 500);
        }
    };
    ModalOtpComponent.prototype.showEnterOtpCodeView = function () {
        var _this = this;
        setTimeout(function () {
            _this.isSendOtpScreen = false;
            _this.ndaModal.show();
            _this.isModalLoaded = true;
            _this.otpCode && _this.otpCode.nativeElement.focus();
            _this.cdRef.detectChanges();
        }, 900);
    };
    ModalOtpComponent.prototype.shouldEnrollFactor = function () {
        if (this.factors.length === 1 && (this.userMobileNumber === undefined || this.userMobileNumber === '')) {
            return false;
        }
        var factor = this.findFactorById(this.factorChoice);
        if (this.factorChoice === undefined || this.factorChoice === '' || (factor && factor.status && factor.status === this.PENDING_ACTIVATION)) {
            return true;
        }
        return false;
    };
    ModalOtpComponent.prototype.findFactorById = function (factorId) {
        return this.factors.find(function (factor) { return factor.id === factorId; });
    };
    Object.defineProperty(ModalOtpComponent.prototype, "isUserLoggedInFromDownStream", {
        get: function () {
            return this.downstreamAppIdentifier && this.downstreamAppIdentifier !== this.appConstantsService.storefrontAppIdentifier;
        },
        enumerable: true,
        configurable: true
    });
    ModalOtpComponent.prototype.createSMSFactor = function (mobileNumber) {
        var _this = this;
        this.authenticationService.createSMSFactor(this.userId, this.userMobileNumber).subscribe(function (factor) {
            _this.factorChoice = factor.id;
            factor['profile'] = { phoneNumber: mobileNumber != null ? mobileNumber : '0000' };
            _this.setFactorInformation([factor]);
            _this.flowMap = _this.appConstantsService.edit_profile_flow;
            _this.flowMap.trigger = _this.appConstantsService.edit_profile_flow.trigger;
            _this.isModalLoaded = true;
            var profile = { phoneNumber: factor['phoneNumber'] };
            var factorOption = extend({}, pick(factor, 'id', 'factorType'), { profile: profile });
            _this.factorAdded = factorOption;
            if (factor.status === 'ACTIVE') {
                _this.storeService.write('isEditProfileOtpVerified', true);
                _this.isComplete.emit();
                return;
            }
            else if (mobileNumber != null) {
                _this.sendOTPToEnrolledFactor();
                _this.showEnterOtpCodeView();
            }
            else {
                _this.storeService.write('isEditProfileOtpVerified', true);
                _this.isComplete.emit();
            }
            _this.cdRef.detectChanges();
        }, function (error) {
            console.log(error);
            if (error.error.code == 'E0000001' && _this.userMobileNumber === null) {
                _this.storeService.write('isEditProfileOtpVerified', true);
                _this.isComplete.emit();
            }
        });
    };
    return ModalOtpComponent;
}());
export { ModalOtpComponent };
