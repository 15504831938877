import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskEmail'
})
export class MaskEmailPipe implements PipeTransform {

  public transform(value: string): string {
    let name = value.substring(0, value.lastIndexOf("@"));
    let maskedName = name.replace(name.slice(1, name.length - 1), "...");
    let domain = value.substring(value.lastIndexOf("@"));    
    return maskedName + domain;
  }
}
