import {Component, AfterContentInit, OnInit, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { get } from 'lodash';
import 'rxjs/add/operator/distinctUntilChanged';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverConfig } from 'ngx-bootstrap/popover';
import { StoreService } from './services';
import { ModalSessionTimeoutComponent } from './session/components/modal-session-timeout/modal-session-timeout.component';
import { ApiGatewayInterceptor } from './api-gateway';
import { AppIdleService } from './services/app-idle/app-idle.service';
import { AuthenticationService } from './services/authentication.service';
import { SessionManagementService } from './services/session-management.service';
import {OktaAuthService} from "@nda/app/services";
import { BookmarkingService } from './services/bookmarking.service';
import { interval} from "rxjs/index";
import {environment} from "@nda/environments/environment";

export type SessionTimeoutModalOnHideReason = keyof {
  esc, "backdrop-click"
}
export namespace SessionTimeoutModalOnHideReason {
  export const esc: SessionTimeoutModalOnHideReason = "esc";
  export const backdropClick: SessionTimeoutModalOnHideReason = "backdrop-click";
}

export function PopoverConfigExtended(): PopoverConfig {
  return Object.assign(new PopoverConfig(), {
    placement: 'bottom',
    triggers: ''
  });
}



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: PopoverConfig, useFactory: PopoverConfigExtended }]
})

export class AppComponent implements OnInit, AfterContentInit {

  private modalRef: BsModalRef;

  constructor(
    private sessionManager: SessionManagementService,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private idleService: AppIdleService,
    private oktaAuthService: OktaAuthService,
    private modalService: BsModalService,
    private router: Router,
    private storeService: StoreService,
    private bookmarkingService: BookmarkingService,
    private zone: NgZone,
  ) {
    this.idleService.onTimeout.subscribe(()   => this.handleSessionEnd());
    this.idleService.onInterrupt.subscribe(() => this.hideSessionIdleModal());
    this.idleService.onIdleStart.subscribe(() => this.configureSessionIdleModal());
    this.idleService.onPing.subscribe(()      => sessionManager.refreshSession().subscribe());
    this.idleService.onTimeoutWarning.subscribe((countdown: number) => {
      this.modalRef.content.countDownMessage(countdown);
    });

    this.idleService.watchToggle(this.sessionManager.hasSession);

    this.sessionManager.hasSession$.distinctUntilChanged()
      .subscribe(hasSession => this.handleSessionChange(hasSession));


    if (typeof Worker !== 'undefined') {
      const worker = new Worker('./app.worker', { type: 'module' }); //creates a webworker thread instance
      worker.onmessage = ({ data }) => {
        if(!localStorage.getItem('proxyLayerObject')) return; //if no active session then return out of block
        if(data == 'token'){ //listens for ping from the webworker thread for apigee refresh token
          this.oktaAuthService.getApigeeToken(); //makes apigee token call
        }
        else if(data == 'validate idle timer'){ //listens for ping from the webworker thread for idle time validator
          let lastActiveTimeDetected = localStorage.getItem('ng2Idle.main.expiry');//gets expiry time based on last stored user activity
          if(!!lastActiveTimeDetected){//checks if active session
            let timeElapsed = Date.now() - parseInt(lastActiveTimeDetected) //seconds left until expiry time
            if(timeElapsed >= 0){ //once timeElapsed turns positive, the idle time limit has exceeded
              this.handleSessionEnd(); //calls function for ending session
            }
          };
          return;
        }
      };
    } else {
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }

  }

  // Remove Apigee details from storage when app initializes.
  ngOnInit() {
    this.oktaAuthService.clearApigeeJwt();
  }

  ngAfterContentInit() {
    const isIE11 = !!window['MSInputMethodContext'] && !!document['documentMode'];

    const authParams = window.location.search;
    if (authParams.includes('code=') && !authParams.includes('/ui/error/') && authParams.includes('state=') && authParams.includes('scope=')) {
      sessionStorage.setItem('authCode', authParams.split('&')[0].split('=')[1]);
      let sessionObject = Object.keys(this.sessionManager);
      if(sessionObject.indexOf('profile') > 0 ){
        if(!this.sessionManager.profile.allyRole && this.sessionManager.profile.userType==='B'){
          return
        }
      }
      else {
        this.oktaAuthService.getApigeeToken().then(res => {
          if(typeof(res) === 'object') localStorage.setItem('proxyLayerObject', JSON.stringify(res));
          // Redirect to bookmarked URL, default is dashboard.
          let redirectPath = this.bookmarkingService.getBookmarkedUrl();
          this.bookmarkingService.resetBookmarkedUrl();
          this.zone.run(()=>{this.router.navigate([redirectPath]);})
        });
      }


    }
    if (!isIE11) {
      return;
    }

    const checkbox = document.getElementById('24CF4C7D395');
    const check = document.getElementById('24F545731EED');
    checkbox.addEventListener('click', event => {
      event.preventDefault();
    }, false);
    check.addEventListener('click', event => {
      event.preventDefault();
    }, false);

    for (let i = 0; i < 40; i++) {
      setTimeout(() => {
        check['checked'] = i % 2 === 0;
      }, i * 250);
    }


  }

  private configureSessionIdleModal(): void {
    this.hideSessionIdleModal();
    this.idleService.interruptsPause();

    this.modalRef = this.modalService.show(ModalSessionTimeoutComponent, { class: 'modal-md' });
    this.modalRef.content.didLogout.subscribe(() => this.handleSessionEnd());
    this.modalRef.content.didRefresh.subscribe(() => this.handleSessionContinue());

    this.modalService.onHide.subscribe((reason: string) => {
      switch (reason) {
        case SessionTimeoutModalOnHideReason.esc:
        case SessionTimeoutModalOnHideReason.backdropClick:
          this.handleSessionContinue();
          break;
      }
    });
  }

  private hideSessionIdleModal(): void {
    this.modalRef && this.modalRef.hide();
  }

  handleSessionChange(hasSession: boolean): void {
    this.idleService.watchToggle(hasSession);
    let atRegistration: boolean = window.location.href.includes('register/confirmation');
    if (!hasSession) {
      ApiGatewayInterceptor.clearToken();
      this.storeService.clear();
      this.storeService.write('suppressDeactivateWarning', true);
      if(!this.authService.allyUserLoggingOut && !atRegistration){this.redirectToLogin();}
    }
    else {
      this.storeService.delete('suppressDeactivateWarning');
    }
  }

  handleSessionContinue(): void {
    this.hideSessionIdleModal();
    this.idleService.watchStart();
  }

  handleSessionEnd(): void {
    this.hideSessionIdleModal();
    this.authService.logout();
  }

  redirectToLogin(): void {
    const url = get(this.router, 'url', '');
    const isLogin = url.indexOf('login') > -1;

    if (!isLogin) {
      this.router.navigate(['login']);
    }
  }
}
