import * as tslib_1 from "tslib";
import { StoreService } from '../../../services/store.service';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { IUserDealership } from '../../../models/profile.interface';
import { get } from 'lodash';
import { Router } from '@angular/router';
import { SessionManagementService } from '../../../services/session-management.service';
import { UserService } from '../../../services/user/user.service';
var SessionHeaderComponent = /** @class */ (function () {
    function SessionHeaderComponent(router, storeService, sessionManager, userService) {
        this.router = router;
        this.storeService = storeService;
        this.sessionManager = sessionManager;
        this.userService = userService;
        this.CONSTANTS = tslib_1.__assign({}, get(AppStrings, 'common'), get(AppStrings, 'oktaAllyRoles'));
    }
    Object.defineProperty(SessionHeaderComponent.prototype, "canManage", {
        get: function () {
            return this.sessionManager.canManage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHeaderComponent.prototype, "isDealershipUser", {
        get: function () {
            return this.sessionManager.isDealershipUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHeaderComponent.prototype, "isBranchUser", {
        get: function () {
            return this.sessionManager.isBranchUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHeaderComponent.prototype, "dealership", {
        get: function () {
            return get(this.sessionManager, 'profile.dealership');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHeaderComponent.prototype, "fullName", {
        get: function () {
            if (!this.sessionManager.profile) {
                return '';
            }
            var _a = this.sessionManager.profile, firstName = _a.firstName, lastName = _a.lastName;
            return firstName + " " + lastName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHeaderComponent.prototype, "dealershipDetails", {
        get: function () {
            if (!this.sessionManager.profile) {
                return '';
            }
            var name = get(this.dealership, 'name'), pdn = get(this.dealership, 'pdn');
            return (name ? name + " - " : '') + (this.CONSTANTS.pdn + ": " + pdn);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHeaderComponent.prototype, "allyRole", {
        get: function () {
            if (!this.sessionManager.profile) {
                return '';
            }
            return this.CONSTANTS[this.sessionManager.profile.allyRole];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHeaderComponent.prototype, "showManage", {
        get: function () {
            return this.sessionManager.showManage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHeaderComponent.prototype, "userRole", {
        get: function () {
            return this.sessionManager.userRole;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHeaderComponent.prototype, "adminTest", {
        get: function () {
            return this.sessionManager.allyAdminSearchUserBoolean;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHeaderComponent.prototype, "helpTest", {
        get: function () {
            return this.sessionManager.helpDeskSearchUserBoolean;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHeaderComponent.prototype, "userTest", {
        get: function () {
            return this.sessionManager.allyUserSearchUserBoolean;
        },
        enumerable: true,
        configurable: true
    });
    SessionHeaderComponent.prototype.handleSubmit = function (query) {
        var truncatedQuery = query.trim();
        this.router.navigate(['/dashboard/search'], { queryParams: { query: truncatedQuery } });
    };
    Object.defineProperty(SessionHeaderComponent.prototype, "term", {
        get: function () {
            return get(this, 'query', '').replace(/ /g, '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHeaderComponent.prototype, "canSubmit", {
        get: function () {
            var hasErrors = get(this.ngForm, 'controls.query.errors.required', false);
            return this.ngForm.submitted ? (hasErrors ? false : this.term.length > 2) : true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionHeaderComponent.prototype, "isValidQuery", {
        get: function () {
            var hasSubmitted = this.ngForm.submitted;
            var isValid = this.term.length == 0 || this.term.length > 2;
            return !hasSubmitted || (hasSubmitted && isValid);
        },
        enumerable: true,
        configurable: true
    });
    return SessionHeaderComponent;
}());
export { SessionHeaderComponent };
