/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ally-cancel-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./ally-cancel-modal.component";
var styles_AllyCancelModalComponent = [i0.styles];
var RenderType_AllyCancelModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AllyCancelModalComponent, data: {} });
export { RenderType_AllyCancelModalComponent as RenderType_AllyCancelModalComponent };
export function View_AllyCancelModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "article", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Don't Save Changes?"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If you leave without submitting the edits, your changes will be lost."])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-primary font-weight-bold"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-link accessibility-button-group"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Go Back"]))], null, null); }
export function View_AllyCancelModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ally-cancel-modal", [], null, null, null, View_AllyCancelModalComponent_0, RenderType_AllyCancelModalComponent)), i1.ɵdid(1, 49152, null, 0, i2.AllyCancelModalComponent, [], null, null)], null, null); }
var AllyCancelModalComponentNgFactory = i1.ɵccf("app-ally-cancel-modal", i2.AllyCancelModalComponent, View_AllyCancelModalComponent_Host_0, {}, {}, []);
export { AllyCancelModalComponentNgFactory as AllyCancelModalComponentNgFactory };
