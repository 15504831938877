import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { UsStatesService } from '../../../services';
import { DealershipService } from '../../../registration/services/dealership/dealership.service';
import 'rxjs/add/operator/finally';
import * as _ from 'lodash';
import { NdaModalChooseAppAndReportsComponent } from '../nda-modal-choose-app-and-reports/nda-modal-choose-app-and-reports.component';
import { AddAppsService } from '../../services/add-apps.service';
import { DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { ProfileAllyRole } from '../../../models/profile.interface';
import { SessionManagementService } from '../../../services/session-management.service';
export var SearchCriteriaViewSegment;
(function (SearchCriteriaViewSegment) {
    SearchCriteriaViewSegment.pdn = "pdn";
    SearchCriteriaViewSegment.dealershipName = "dealershipName";
})(SearchCriteriaViewSegment || (SearchCriteriaViewSegment = {}));
var NdaAddDealershipComponent = /** @class */ (function () {
    function NdaAddDealershipComponent(statesService, sessionService, ref, dealershipService, addAppsService, drawerComponentCommunicatorService) {
        this.statesService = statesService;
        this.sessionService = sessionService;
        this.ref = ref;
        this.dealershipService = dealershipService;
        this.addAppsService = addAppsService;
        this.drawerComponentCommunicatorService = drawerComponentCommunicatorService;
        this.pdnListVisible = new EventEmitter();
        this.hasPendingRequest = false;
        this.searchByPdn = SearchCriteriaViewSegment.pdn;
        this.searchByDealershipName = SearchCriteriaViewSegment.dealershipName;
        this.drawerState = false;
        this.itemsPerPage = 5;
        this.maxSize = 5;
        this.spinner = '<img class="nda-spinner" src="assets/images/Spinner_White.svg" />';
        this.totalPages = 1;
        this.patternPdn = '^[0-9]*$';
        this.patternDealershipName = "^[a-zA-Z0-9 ']*$";
        this.CONSTANTS = tslib_1.__assign({}, AppStrings['common'], AppStrings['searchUsers'], AppStrings['registration'], AppStrings['registration']['dealership']['step2'], AppStrings['associatedDealership']);
    }
    NdaAddDealershipComponent.prototype.ngOnInit = function () {
        this.handleStartOver();
        this.isAddDealerByAdmin = this.data.isAddDealerByAdmin;
        this.profileObj = this.data['profileObj'];
        this.associatedDealers = this.data['associatedDealers'];
        this.profile = this.data.profile;
        this.selfGroups = this.data.selfGroups;
        this.statesList = this.statesService.getStates();
    };
    Object.defineProperty(NdaAddDealershipComponent.prototype, "isFormChanged", {
        get: function () {
            this.drawerComponentCommunicatorService.isFormChanged = this.hasDealerships;
            return true;
        },
        enumerable: true,
        configurable: true
    });
    NdaAddDealershipComponent.prototype.handleStartOver = function () {
        this.dealerships = [];
        this.isNoSelection = false;
        this.didAttemptContinue = false;
        this.didPromptDealershipSelection = false;
        this.isSearchByExistingPdnError = false;
        this.currentPage = 1;
        this.buildForm();
        this.ngForm.submitted = false;
        this.ref.detectChanges();
    };
    NdaAddDealershipComponent.prototype.handleStateStartOver = function () {
        this.dealerships = [];
        this.isNoSelection = false;
        this.currentPage = 1;
        this.isSearchByExistingPdnError = false;
        this.ngForm.submitted = false;
        this.didAttemptContinue = false;
    };
    NdaAddDealershipComponent.prototype.openDrawer = function () {
        this.handleStartOver();
        this.searchBy.setValue(SearchCriteriaViewSegment.pdn);
        return this.drawerState = true;
    };
    NdaAddDealershipComponent.prototype.showWarningModal = function () {
        this.pdnListVisible.emit('Warning');
    };
    NdaAddDealershipComponent.prototype.closeDrawer = function () {
        if (this.hasDealerships) {
            this.showWarningModal();
            this.drawerState = true;
        }
        else {
            this.ngForm.reset();
            this.drawerState = false;
        }
        return this.drawerState;
    };
    Object.defineProperty(NdaAddDealershipComponent.prototype, "dealershipsTotal", {
        get: function () {
            return this.dealerships.length;
        },
        enumerable: true,
        configurable: true
    });
    NdaAddDealershipComponent.prototype.handlePageChange = function (event) {
        document.getElementsByClassName('pagination-page page-item active')[0].setAttribute('aria-current', 'page');
        var node = document.getElementsByClassName("result-progress")[0];
        node.focus({ preventScrolling: false });
    };
    Object.defineProperty(NdaAddDealershipComponent.prototype, "addDealerByPdnErrorMessage", {
        get: function () {
            if (!!this.didAttemptContinue && !this.hasDealerships && !this.isPdnInvalid) {
                return this.CONSTANTS.errorMessages.pdn.notFound;
            }
            if (!!this.isPdnInvalid && !!this.pdn.errors.required) {
                return this.CONSTANTS.errorMessages.pdn.required;
            }
            if (!!this.isPdnInvalid && (!!this.pdn.errors.pattern || !!this.pdn.errors.minlength)) {
                return this.CONSTANTS.errorMessages.pdn.minlength;
            }
            else {
                return "";
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddDealershipComponent.prototype, "addDealerByNameErrorMessage", {
        get: function () {
            if (!!this.didAttemptContinue && !this.hasDealerships && !this.isDealershipNameInvalid) {
                return this.CONSTANTS.errorMessages.dealershipName.notFound;
            }
            if (!!this.isDealershipNameInvalid && !!this.dealershipName.errors.required) {
                return this.CONSTANTS.errorMessages.dealershipName.required;
            }
            if (!!this.isDealershipNameInvalid && !!this.dealershipName.errors.pattern) {
                return this.CONSTANTS.errorMessages.dealershipName.pattern;
            }
            if (!!this.isDealershipNameInvalid && !!this.dealershipName.errors.minlength) {
                return this.CONSTANTS.errorMessages.dealershipName.minlength;
            }
            else {
                return "";
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddDealershipComponent.prototype, "selectStateErrorMessage", {
        get: function () {
            if (!!this.isSelectedStateInvalid && !!this.selectedState.errors.required) {
                return this.CONSTANTS.errorMessages.selectedState.required;
            }
            else {
                return "";
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddDealershipComponent.prototype, "hasDealerships", {
        get: function () {
            return this.dealershipsTotal > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddDealershipComponent.prototype, "hasSingleDealership", {
        get: function () {
            return this.dealershipsTotal == 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddDealershipComponent.prototype, "isSearchByPdn", {
        get: function () {
            return this.searchBy.value === SearchCriteriaViewSegment.pdn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddDealershipComponent.prototype, "isSearchByDealershipName", {
        get: function () {
            return this.searchBy.value === SearchCriteriaViewSegment.dealershipName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddDealershipComponent.prototype, "isStepValid", {
        get: function () {
            if (this.isSearchByPdn) {
                return this.pdn.valid;
            }
            else if (this.isSearchByDealershipName) {
                return this.dealershipName.valid && this.selectedState.valid;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    NdaAddDealershipComponent.prototype.buildForm = function () {
        this.didAttemptContinue = false;
        this.searchBy = this.searchBy || new FormControl(this.searchByPdn);
        this.selectedState = new FormControl('', Validators.required);
        this.dealershipPdn = new FormControl('', Validators.required);
        this.pdn = new FormControl('', Validators.compose([
            Validators.required,
            Validators.pattern(this.patternPdn),
            Validators.minLength(5)
        ]));
        this.dealershipName = new FormControl('', Validators.compose([
            Validators.required,
            Validators.pattern(this.patternDealershipName),
            Validators.minLength(3),
            Validators.maxLength(50)
        ]));
        this.addDealershipForm = new FormGroup({
            searchBy: this.searchBy,
            dealershipName: this.dealershipName,
            pdn: this.pdn,
            selectedState: this.selectedState,
            dealershipPdn: this.dealershipPdn,
        });
    };
    NdaAddDealershipComponent.prototype.handleSearch = function () {
        var _this = this;
        this.isSearchByExistingPdnError = false;
        if (this.isStepValid && !this.didAttemptContinue) {
            if (this.hasPendingRequest) {
                return;
            }
            else if (this.isSearchByPdn && _.includes(this.associatedDealers, this.pdn.value)) {
                this.isSearchByExistingPdnError = true;
                return;
            }
            this.hasPendingRequest = true;
            this.searchDealerships()
                .finally(function () { return _this.hasPendingRequest = false; })
                .subscribe(function (dealerships) {
                _this.dealerships = dealerships;
                _this.didAttemptContinue = true;
                if (_this.hasSingleDealership) {
                    if (_.includes(_this.associatedDealers, _.first(_this.dealerships).pdnNumber)) {
                        _this.isSearchByExistingPdnError = true;
                        return;
                    }
                    _this.dealershipPdn.setValue(_.first(_this.dealerships).pdnNumber);
                    _this.getDealersInfo(dealerships[0]);
                }
            });
        }
        else if (this.isStepValid && this.didAttemptContinue) {
            this.didPromptDealershipSelection = true;
            this.isNoSelection = false;
            this.addAppsService.pdn = this.dealershipPdn.value;
            this.isDealershipSelected ? this.showChooseAppsAndReportsModal() : (this.isNoSelection = true);
        }
    };
    NdaAddDealershipComponent.prototype.resetIsSearchByExistingPdn = function () {
        this.dealerships = [];
        this.isNoSelection = false;
        this.currentPage = 1;
        this.ngForm.submitted = false;
        this.didAttemptContinue = false;
        this.isSearchByExistingPdnError = false;
    };
    NdaAddDealershipComponent.prototype.isPdnChosen = function (pdn) {
        if (this.isSearchByDealershipName && _.includes(this.associatedDealers, pdn)) {
            return "disabled";
        }
        else {
            return null;
        }
    };
    Object.defineProperty(NdaAddDealershipComponent.prototype, "isDealershipSelected", {
        get: function () {
            return this.dealershipPdn.value && (this.dealershipPdn.value != (null || ''));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddDealershipComponent.prototype, "resultsDisplayText", {
        get: function () {
            var isPlural = this.dealerships.length > 1 || !this.dealerships.length, tmplName = isPlural ? 'resultPlural' : 'resultSingular';
            var tmpl = _.template(this.CONSTANTS[tmplName])({ n: this.dealerships.length });
            if (this.isSearchByPdn) {
                tmpl += ' ';
                tmpl += _.template(this.CONSTANTS['resultForPdn'])({ pdn: this.pdn.value });
            }
            return tmpl;
        },
        enumerable: true,
        configurable: true
    });
    NdaAddDealershipComponent.prototype.showChooseAppsAndReportsModal = function () {
        if (this.dealershipsTotal) {
            this.addAppsService.profileObj = this.profileObj || this.profile;
            this.addAppsService.isChooseAppsAndReports = true;
            this.addAppsService.isRequestorAnAdmin = this.isAllyAdmin || this.checkIsLoggedInUserAdminForThisPdn(this.addAppsService.pdn);
            this.addAppsService.selectedDealersInfo = this.selectedDealershipInfo;
        }
    };
    Object.defineProperty(NdaAddDealershipComponent.prototype, "isAllyAdmin", {
        get: function () {
            return _.get(this.sessionService, 'profile.allyRole') === ProfileAllyRole.admin;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * checkIsLoggedInUserAdminForThisPdn - function to check is logged in user admin for the given PDN
     *
     * @param pdn
     */
    NdaAddDealershipComponent.prototype.checkIsLoggedInUserAdminForThisPdn = function (pdn) {
        if (!this.selfGroups) {
            return false;
        }
        var adminPdns = this.selfGroups.filter(function (group) { return _.includes(group.name, pdn); });
        return adminPdns && adminPdns.length > 0;
    };
    NdaAddDealershipComponent.prototype.getDealersInfo = function (selectedDealerInfo) {
        this.addAppsService.dealershipName = selectedDealerInfo.dealershipName;
        return this.selectedDealershipInfo = selectedDealerInfo;
    };
    NdaAddDealershipComponent.prototype.searchDealerships = function () {
        var query;
        var _a = tslib_1.__read([this.pdn.value, this.dealershipName.value, this.selectedState.value], 3), pdn = _a[0], dealershipName = _a[1], selectedState = _a[2];
        if (this.isSearchByPdn) {
            query = this.dealershipService.findByPdn(pdn);
        }
        else if (this.isSearchByDealershipName) {
            query = this.dealershipService.findByDealershipName(dealershipName, selectedState);
        }
        return query;
    };
    Object.defineProperty(NdaAddDealershipComponent.prototype, "isPdnInvalid", {
        get: function () {
            return this.ngForm.submitted && this.pdn.invalid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddDealershipComponent.prototype, "isDealershipNameInvalid", {
        get: function () {
            return this.ngForm.submitted && this.dealershipName.invalid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddDealershipComponent.prototype, "isSelectedStateInvalid", {
        get: function () {
            return this.ngForm.submitted && this.selectedState.invalid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddDealershipComponent.prototype, "isDealershipPdnInvalid", {
        get: function () {
            return this.didPromptDealershipSelection && this.dealershipPdn.invalid;
        },
        enumerable: true,
        configurable: true
    });
    NdaAddDealershipComponent.prototype.handleSubmit = function () {
        this.addDealershipFormSubmit.nativeElement.click();
    };
    NdaAddDealershipComponent.prototype.findDealershipName = function (pdn) {
        return this.dealerships.find(function (dealership) { return dealership.pdnNumber === pdn; }).dealershipName;
    };
    Object.defineProperty(NdaAddDealershipComponent.prototype, "paginatedDealerships", {
        get: function () {
            var begin = (this.currentPage - 1) * this.itemsPerPage;
            var end = begin + this.itemsPerPage;
            return this.dealerships.slice(begin, end);
        },
        enumerable: true,
        configurable: true
    });
    NdaAddDealershipComponent.prototype.closeModalDrawer = function () {
        this.ngForm.reset();
        this.drawerState = false;
    };
    NdaAddDealershipComponent.prototype.updateTotalPages = function ($event) {
        this.totalPages = $event;
    };
    Object.defineProperty(NdaAddDealershipComponent.prototype, "showPagination", {
        get: function () {
            if (this.dealershipsTotal > this.maxSize) {
                return true;
            }
            else {
                this.totalPages = 1;
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    return NdaAddDealershipComponent;
}());
export { NdaAddDealershipComponent };
