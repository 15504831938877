/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nda-nav.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../nda-chat/nda-chat.component.ngfactory";
import * as i3 from "../nda-chat/nda-chat.component";
import * as i4 from "../../services/chat/nda-chat.service";
import * as i5 from "@angular/router";
import * as i6 from "../../services/session-management.service";
import * as i7 from "@angular/common";
import * as i8 from "./nda-nav.component";
var styles_NdaNavComponent = [i0.styles];
var RenderType_NdaNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NdaNavComponent, data: {} });
export { RenderType_NdaNavComponent as RenderType_NdaNavComponent };
function View_NdaNavComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["role", "presentation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-nda-chat", [["class", "btn btn-link"]], null, null, null, i2.View_NdaChatComponent_0, i2.RenderType_NdaChatComponent)), i1.ɵdid(2, 114688, null, 0, i3.NdaChatComponent, [i4.NdaChatService, i5.Router, i6.SessionManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_NdaNavComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["role", "presentation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-link font-weight-bold"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.contacts() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.CONSTANTS.contacts; _ck(_v, 2, 0, currVal_0); }); }
function View_NdaNavComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["role", "presentation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "btn btn-link font-weight-bold"], ["tabindex", "0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.CONSTANTS.logout; _ck(_v, 2, 0, currVal_0); }); }
export function View_NdaNavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ul", [["class", "nav nav-pills pull-right nda-top-nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NdaNavComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NdaNavComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NdaNavComponent_3)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldDisplayChat; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isApprovedUser; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isApprovedUser; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_NdaNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nda-nav", [], null, null, null, View_NdaNavComponent_0, RenderType_NdaNavComponent)), i1.ɵdid(1, 114688, null, 0, i8.NdaNavComponent, [i5.Router, i6.SessionManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NdaNavComponentNgFactory = i1.ɵccf("nda-nav", i8.NdaNavComponent, View_NdaNavComponent_Host_0, { isFlyoutShowing: "isFlyoutShowing" }, { showFlyout: "showFlyout" }, []);
export { NdaNavComponentNgFactory as NdaNavComponentNgFactory };
