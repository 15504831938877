import { Component, OnInit } from '@angular/core';
import { AppStrings } from '../../../assets/app-strings/app-strings';

@Component({
  selector: 'nda-operation-hours',
  templateUrl: './nda-operation-hours.component.html',
  styleUrls: ['./nda-operation-hours.component.scss']
})
export class NdaOperationHoursComponent implements OnInit {

  public readonly CONSTANTS = {
    ...AppStrings['ndaOperationHours'],
  };

  constructor() { }

  ngOnInit() {
  }

}
