import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'app-dealership-cancel-modal',
  templateUrl: './dealership-cancel-modal.component.html',
  styleUrls: ['./dealership-cancel-modal.component.scss']
})
export class DealershipCancelModalComponent {

  public didConfirm$: Subject<boolean> = new Subject();
  public didDecline$: Subject<boolean> = new Subject();

  private modalRef: BsModalRef;

  public confirm() {
    this.didConfirm$.next(true);
    this.didConfirm$.complete();
  }

  public decline() {
    this.didDecline$.next(false);
    this.didDecline$.complete();
  }
}
