import * as tslib_1 from "tslib";
import { Subject } from 'rxjs';
import { template } from 'lodash';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
var ModalSessionTimeoutComponent = /** @class */ (function () {
    function ModalSessionTimeoutComponent() {
        this.CONSTANTS = tslib_1.__assign({}, AppStrings['common'], AppStrings['modalSessionTimeOut']);
        this.didRefresh = new Subject();
        this.didLogout = new Subject();
    }
    ModalSessionTimeoutComponent.prototype.refreshSession = function () {
        this.didRefresh.next(true);
    };
    ModalSessionTimeoutComponent.prototype.logOut = function () {
        this.didLogout.next(true);
    };
    ModalSessionTimeoutComponent.prototype.countDownMessage = function (seconds) {
        var now = new Date().getTime(), then = new Date().getTime() + (seconds * 1000), diff = then - now, mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)), secs = Math.floor((diff % (1000 * 60)) / 1000), displaySecs = secs >= 10 ? secs : "0" + secs;
        var unitMins = mins > 1 ? 'minutes' : 'minute';
        var unitSecs = secs == 1 ? 'second' : 'seconds';
        var countdown = (mins ? mins + " " + unitMins + " " : '') + (displaySecs + " " + unitSecs);
        this.message = template(this.CONSTANTS.message)({ countdown: countdown });
    };
    return ModalSessionTimeoutComponent;
}());
export { ModalSessionTimeoutComponent };
