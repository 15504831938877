import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerService } from './services/spinner/spinner.service';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    LoadingSpinnerComponent,
  ],
  entryComponents: [
    LoadingSpinnerComponent,
  ],
  exports: [
    LoadingSpinnerComponent,
  ]
})
export class LoadingSpinnerModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LoadingSpinnerModule,
      providers: [
        SpinnerService
      ]
    }
  }
}
