import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { SpinnerService } from '../../../loading-spinner/services/spinner/spinner.service';
import { MatchOtherValidator } from '../../../registration/validators/match-other';
import { DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { UserDetailsService } from '../../services/user-details/user-details.service';
import { get } from 'lodash';
import { Observable } from 'rxjs/Observable';
var NdaModalContentCreatePasswordComponent = /** @class */ (function () {
    function NdaModalContentCreatePasswordComponent(fb, spinnerService, drawerComponentCommunicatorService, userDetailsService, router) {
        this.fb = fb;
        this.spinnerService = spinnerService;
        this.drawerComponentCommunicatorService = drawerComponentCommunicatorService;
        this.userDetailsService = userDetailsService;
        this.router = router;
        this.CONSTANTS = tslib_1.__assign({}, AppStrings['createPassword'], AppStrings['common']);
        this.pwdPattern = "^[^&<>%=?]*$";
    }
    NdaModalContentCreatePasswordComponent.prototype.resetComponent = function () {
        this.createForm();
        this.isSubmitClicked = false;
        this.showConfirmation = false;
        this.isPasswordMatchesWithOldPasswordHistory = false;
    };
    NdaModalContentCreatePasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.profile = {
            userId: null,
            userType: null,
            pdnWithApplications: null,
            firstName: null,
            lastName: null,
            cellPhone: null,
            emailId: null,
            primaryDealer: null,
            reEnterEmailId: '',
            userName: '',
            dealershipName: '',
            userStatus: '',
            dealerRole: null,
            allyRole: null
        };
        this.profile = this.data.profile;
        if (this.isPhoneNumberWithPlus) {
            this.profile.cellPhone = this.profile.cellPhone.slice(2);
        }
        this.resetComponent();
        this.router.events.subscribe(function (e) {
            if (e instanceof NavigationEnd) {
                _this.resetComponent();
            }
        });
    };
    NdaModalContentCreatePasswordComponent.prototype.createPasswordFormErrorMessage = function (passMatches) {
        if (!!this.showEmptyNewPasswordError) {
            return this.CONSTANTS.errors.newPasswordRequired;
        }
        if (!!this.showGenericNewPasswordError) {
            return this.CONSTANTS.errors.newPasswordGeneric;
        }
        if (!!passMatches && !!this.isSubmitClicked) {
            return this.CONSTANTS.errors.passwordMatchesUsername;
        }
        if (!!this.showPasswordMatchesWithOldPasswordHistory) {
            return this.CONSTANTS.errors.passwordMatchesPreviousPassword;
        }
        if (!!this.isPasswordHasRestrictedCharactersInNewPassword) {
            return this.CONSTANTS.errors.invalidPwd;
        }
        else {
            return "";
        }
    };
    Object.defineProperty(NdaModalContentCreatePasswordComponent.prototype, "reEnterPasswordFormErrorMessage", {
        get: function () {
            if (!!this.isPasswordHasRestrictedCharactersInNewPassword) {
                return this.CONSTANTS.errors.invalidPwd;
            }
            if (!!this.showReEnterPasswordError) {
                return this.CONSTANTS.errors.reEnterPassword;
            }
            else {
                return "";
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentCreatePasswordComponent.prototype, "isPhoneNumberWithPlus", {
        get: function () {
            return this.profile.cellPhone !== null && this.profile.cellPhone !== undefined && this.profile.cellPhone.startsWith('+');
        },
        enumerable: true,
        configurable: true
    });
    NdaModalContentCreatePasswordComponent.prototype.createForm = function () {
        this.passwordForm = this.fb.group({
            username: [this.profile.userName],
            newPassword: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(20),
                    this.validatePasswordComplexity,
                    this.validatePasswordNotMatchUsername
                ])],
            passwordConfirmation: ['', [
                    Validators.required,
                    MatchOtherValidator('newPassword')
                ]]
        });
    };
    NdaModalContentCreatePasswordComponent.prototype.validatePasswordComplexity = function (c) {
        var PASSWORD_COMPLEXITY = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
        return PASSWORD_COMPLEXITY.test(c.value) ? null : {
            validatePassword: {
                valid: false
            }
        };
    };
    NdaModalContentCreatePasswordComponent.prototype.validatePasswordNotMatchUsername = function (control) {
        var password = control.value;
        var usernameObject = control.root.get('username');
        var username = usernameObject ? usernameObject.value : '';
        return !(password.toLowerCase() === username.toLowerCase())
            ? null
            : { passwordMatchesUsername: true };
    };
    Object.defineProperty(NdaModalContentCreatePasswordComponent.prototype, "showEmptyNewPasswordError", {
        get: function () {
            return this.passwordForm.controls.newPassword.value.length === 0 && this.isSubmitClicked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentCreatePasswordComponent.prototype, "showGenericNewPasswordError", {
        get: function () {
            return !this.passwordForm.controls.newPassword.valid
                && this.isSubmitClicked
                && this.passwordForm.controls.newPassword.value.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentCreatePasswordComponent.prototype, "showPasswordMatchesWithOldPasswordHistory", {
        get: function () {
            return this.isSubmitClicked && this.isPasswordMatchesWithOldPasswordHistory;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentCreatePasswordComponent.prototype, "showReEnterPasswordError", {
        get: function () {
            return !this.passwordForm.controls.passwordConfirmation.valid
                && this.isSubmitClicked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentCreatePasswordComponent.prototype, "isFormChanged", {
        get: function () {
            this.drawerComponentCommunicatorService.isFormChanged = this.passwordForm.controls.newPassword.value.length != 0 || this.passwordForm.controls.passwordConfirmation.value.length != 0;
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaModalContentCreatePasswordComponent.prototype, "isPasswordHasRestrictedCharactersInNewPassword", {
        get: function () {
            return this.isSubmitClicked && (get(this.passwordForm.controls.newPassword, 'errors.pattern') || get(this.passwordForm.controls.passwordConfirmation, 'errors.pattern'));
        },
        enumerable: true,
        configurable: true
    });
    NdaModalContentCreatePasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        this.isSubmitClicked = true;
        this.isPasswordMatchesWithOldPasswordHistory = false;
        if (this.passwordForm.valid) {
            this.spinnerService.show();
            this.userDetailsService.createTemporaryPassword(this.profile, this.passwordForm.controls.newPassword.value)
                .finally(function () { return _this.spinnerService.hide(); })
                .subscribe(function (response) { return _this.processCreateTemporaryPasswordReponse(response); }, function (err) { return _this.handleError(err); });
        }
    };
    NdaModalContentCreatePasswordComponent.prototype.processCreateTemporaryPasswordReponse = function (response) {
        this.passwordForm.controls.newPassword.setValue('');
        this.passwordForm.controls.passwordConfirmation.setValue('');
        this.drawerComponentCommunicatorService.isFormChanged = false;
        if (response == null) {
            this.drawerComponentCommunicatorService.callOnloadServiceOnClose = true;
            this.showConfirmation = true;
        }
        else {
            this.showError = true;
        }
    };
    NdaModalContentCreatePasswordComponent.prototype.handleError = function (err) {
        var errorResponse = get(err, "error.exceptions");
        if (errorResponse && errorResponse['code'] == "ERR_000027") {
            this.isPasswordMatchesWithOldPasswordHistory = true;
        }
        else {
            return Observable.throw(err);
        }
    };
    return NdaModalContentCreatePasswordComponent;
}());
export { NdaModalContentCreatePasswordComponent };
