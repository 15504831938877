/*  This function generates the PKCE code challenge  */
export const challenge_from_verifier = async(v) => {
    let hashed = await sha256(v);
    let base64encoded = base64urlencode(hashed);
    return base64encoded;
};

/*  This function generates the PKCE code verifier  */
export const generateCodeVerifier = () => {
    let array = new Uint32Array(56 / 2);
    let crypto = window.crypto;
    crypto.getRandomValues(array);
    return Array.from(array, dec2hex).join('');
};

/*  This function generates both the PKCE code challenge */
export const generatePKCECodes = () => {
    let verifier = generateCodeVerifier();
    sessionStorage.setItem('verifier', verifier);
    let challenge = challenge_from_verifier(verifier);
    return challenge;
};

//############ Helper functions for PKCE code generation #################
export const  base64urlencode = (a) => {
    var str = "";
    var bytes = new Uint8Array(a);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i]);
    }
    return btoa(str)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
};

export const generateRandomString = () => {
    var array = new Uint32Array(56/2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec2hex).join('');
};

export const dec2hex = (dec) => {
    return ("0" + dec.toString(16)).substring(-2);
};

export const sha256 = (plain) => { // returns promise ArrayBuffer
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
};