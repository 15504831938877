import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {Observable} from 'rxjs/Observable';
import { AppConstantsService } from '../services/app-constants.service';
import { SessionManagementService } from '../services/session-management.service';

@Injectable()
export class ManageUsersGuard implements CanActivate {
  currentUrl: string;
  constructor(
    private router: Router,
    private sessionManager: SessionManagementService,
    private appConstantsService: AppConstantsService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      this.currentUrl = state.url;

      // Below Condition is to check user_details page access
      if (this.currentUrl == this.appConstantsService.user_Details_path) {
        this.appConstantsService.isUserNameLinkClicked ? this.appConstantsService.isUserNameLinkClicked = false : this.router.navigate(['/manage/users/search-users']);
      }

      const hasApprovalRequests = JSON.parse(next.queryParams.hasApprovalRequests || false);

      const isAllyManager = !this.sessionManager.profile.allyRole && !this.sessionManager.profile.dealerRole && this.sessionManager.profile.userType === 'B';
      
      // Will return true if is an Ally Role from the database or an Ally Manager from Okta
      const allyUserManageUser = isAllyManager || this.sessionManager.allyUserManageUser;
      if (this.sessionManager.canManage || hasApprovalRequests || allyUserManageUser) {
        return true;
      }

      this.router.navigate(['/login']);
      return false;
  }
}
