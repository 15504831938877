import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { Injectable } from '@angular/core';

    @Injectable()
    export class EmptyResponseBodyErrorInterceptor implements HttpInterceptor {
      intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).catch((err: HttpErrorResponse) => {
          if (err.status == 200) {
            const error = err.error;
            const res = new HttpResponse({
              body: error.text || null,
              headers: err.headers,
              status: err.status,
              statusText: err.statusText,
              url: err.url
            });
            return Observable.of(res);
          } else {
            return Observable.throw(err);
          }
        });
      }
    }
