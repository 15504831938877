import { Component, ViewChild } from '@angular/core';
import { PasswordWarnComponent } from '../password-warn/password-warn.component';
import { PasswordStatus, PasswordExpiryService } from '../../services/password-expiry.service';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { get } from 'lodash';

@Component({
  selector: 'app-standard-page-layout',
  templateUrl: './standard-page-layout.component.html',
  styleUrls: ['./standard-page-layout.component.scss']
})
export class StandardPageLayoutComponent {

  @ViewChild('pwdWarnModel', { static: true  }) pwdWarnModel:PasswordWarnComponent;

  constructor(
    private passwordService: PasswordExpiryService,
    public activatedRoute: ActivatedRoute
  ) { }

  get isNavEnabled(): boolean {
    return get(this.activatedRoute, 'snapshot.data.isNavEnabled');
  }

  get displayPasswordWarn() {
    if(this.passwordService.status === PasswordStatus.PasswordWarn ){
      this.pwdWarnModel.show(this.passwordService.passwordExpiryDate);
      this.passwordService.status = this.passwordService.passwordExpiryDate = null;
    }
    return true;
  }

}
