import { Routes } from '@angular/router';
import { ErrorsComponent } from './components/errors/errors.component';
import { StandardPageLayoutComponent } from '../components/standard-page-layout/standard-page-layout.component';
import { DealerAppsComponent } from "./components/dealer-apps/dealer-apps.component";
var ɵ0 = { isNavEnabled: false };
var routes = [
    { path: 'error/dealer-apps/:appId', component: DealerAppsComponent },
    {
        path: 'error',
        component: StandardPageLayoutComponent,
        data: ɵ0,
        children: [
            { path: '', component: ErrorsComponent }
        ]
    }
];
var ErrorsRoutingModule = /** @class */ (function () {
    function ErrorsRoutingModule() {
    }
    return ErrorsRoutingModule;
}());
export { ErrorsRoutingModule };
export { ɵ0 };
