import { OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import * as AppStrings from "../../../../assets/app-strings/appStrings.json";
import { ErrorType } from "../../handlers/errors-handler";
import { get } from "lodash";
var DealerAppsComponent = /** @class */ (function () {
    function DealerAppsComponent(activatedRoute) {
        this.activatedRoute = activatedRoute;
        this.CONSTANTS = {
            errors: get(AppStrings['default'], 'errorApps')
        };
    }
    Object.defineProperty(DealerAppsComponent.prototype, "paramAppId", {
        get: function () {
            return get(this.activatedRoute, 'snapshot.params.appId', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DealerAppsComponent.prototype, "error", {
        get: function () {
            var appId = this.paramAppId.toLowerCase();
            return get(this.CONSTANTS.errors, appId, this.CONSTANTS.errors[ErrorType.Default]);
        },
        enumerable: true,
        configurable: true
    });
    DealerAppsComponent.prototype.ngOnInit = function () {
    };
    return DealerAppsComponent;
}());
export { DealerAppsComponent };
