import { map } from "lodash";
import { filter } from 'lodash';
var AppConstantsService = /** @class */ (function () {
    function AppConstantsService() {
        this._apps = [];
        this._appCodes = ['VPC', 'VPC2', 'SC', 'SA', 'SG', 'ARRA', 'RO', 'CBAS', 'SDT', 'CCL', 'WBS', 'DTAEFRRR'];
        this.appRegions = [
            { regionCode: "C", regionName: "Central" },
            { regionCode: "NE", regionName: "Northeast" },
            { regionCode: "PW", regionName: "Pacific West" },
            { regionCode: "R", regionName: "Remarketing/CPC" },
            { regionCode: "SE", regionName: "Southeast" },
            { regionCode: "W", regionName: "West" }
        ];
        //common constants
        this.allyAdminApps = [
            {
                name: "Manage Users",
                applicationCode: "MU"
            }
        ];
        this.not_permitted = "Sorry... you are not permitted... Taking you to Login Page";
        this.yes = "YES";
        this.no = "NO";
        this.isActive = "isUserActive";
        this.auth_Required = "MFA_REQUIRED";
        this.session_id = "sessionId";
        this.isDealerAppErrorToBeShown = [false];
        this.pendingRequest = 'Pending request';
        this.accessRequired = 'Access required';
        this.unExpectedError = 'Unexpected error';
        this.vpcText = 'VPC';
        this.vpc2Text = 'VPC2';
        this.dev = 'DEV';
        this.sit = 'SIT';
        this.pdn = 'pdn';
        this.sessionDetails = 'sessionDetails';
        this.sessionToken = 'sessionToken';
        this.profile = 'profile';
        this.isPdnVerified = 'isPdnVerified';
        this.pending = 'Pending';
        this.rejected = 'Rejected';
        this.approved = 'APPROVED';
        this.deleted = 'DELETED';
        this.dealerAppNames = {
            CBAS: "Credit Balance Agreement Statements",
            CCL: "Commercial Credit Line Report",
            RO: "RouteOne",
            SA: "SmartAuction",
            SC: "SmartCash",
            SDT: "Statement of Dealer Transactions",
            SG: "Ally Ride Return",
            ARRA: "Ally Ride Return Admin",
            VPC: "Vehicle Protection Center",
            VPC2: "Vehicle Protection Center",
            WBS: "Wholesale Billing Statement",
        };
        this.storefrontAppIdentifier = 'SFW';
        this.smartAuctionDealershipRoles = ['Dealer', 'Salesperson'];
        this.smartAuctionDealershipSecondaryRoles = ['Seller', 'Buyer', 'Buyer and Seller'];
        this.smartAuctionAllyUserRoles = ['Branch', 'EO Admin'];
        this.smartCashAllyUserRoles = [
            'EO',
            'EO Basic',
            'Key Entry Clerk',
            'Supervisor 1',
            'Supervisor 2',
            'Supervisor 3',
            'Supervisor 4',
            'Supervisor 5',
            'Manager',
            'Support',
            'SPB'
        ];
        this.smartCashAllyUserRegion = [
            'Central',
            'Northeast',
            'Pacific West',
            'Remarketing/CPC',
            'Southeast',
            'West',
        ];
        this.allyRideReturnDealershipRoles = ['primaryAccess', 'secondaryAccess', "fullAccess"];
        this.allyRideReturnAdminDealershipRoles = ['primaryAccess'];
        this.isUserNameLinkClicked = false;
        this.adminSearchFlag = false;
        this.template = {
            spinner: '<img class="nda-spinner" src="assets/images/Spinner_White.svg" />'
        };
        this.dealerAppListRequireAccess = ['VPC', 'VPC2', 'SC', 'SA', 'SG', 'ARRA', 'RO', 'CBAS', 'SDT', 'CCL', 'WBS', 'DTAEFRRR'];
        this.dealerAppListToBeOpenedInWindow = ['SA', 'ARRA'];
        this.smartAuction = 'SA';
        this.keepAliveTime = 1380; // 23 mins - Refresh Session call
        this.idleTime = 1440; // 24 mins - Modal popup
        this.idleTimeOut = 300; // 05 mins - Auto logout after modal popup
        this.fullWidthIframe = ['RO', 'SC'];
        //router paths
        this.login_path = "/login";
        this.splash_path = "/coming-soon";
        this.dashboard_path = "/dashboard";
        this.user_Details_path = "/manage/users/search-users/user-details";
        this.pre_login_change_password_path = "/change-password";
        this.post_login_change_password = "/manage/profile/change-password";
        this.otp_login_flow = { trigger: "login", routeTo: "/dashboard" };
        this.otp_registration_flow = { trigger: "resitration", routeTo: "/register/confirmation" };
        this.otp_forgotPwd_flow = { trigger: "forgotPwd", routeTo: "/gethelp/reset-password" };
        this.pdn_verified_register_flow = { trigger: "pdnVerifiedRegister", routeTo: "/register" };
        this.pdn_non_verified_register_flow = { trigger: "pdnNonVerifiedRegister", routeTo: "/pdn-verification" };
        this.edit_profile_flow = { trigger: "editProfile", routeTo: "/manage/profile" };
    }
    Object.defineProperty(AppConstantsService.prototype, "appList", {
        get: function () {
            return this._apps;
        },
        set: function (apps) {
            var _this = this;
            this._apps = apps;
            this.dealerAppListToBeOpenedInWindow = [];
            this.dealerAppListRequireAccess = [];
            this.appCodesList = map(apps, function (app) {
                _this.dealerAppNames[app.applicationCode] = app.name;
                if (app.properties['displayMode'] == 'WINDOW')
                    _this.dealerAppListToBeOpenedInWindow.push(app.applicationCode);
                if (app.properties['isRequestableApp'] == 'Y')
                    _this.dealerAppListRequireAccess.push(app.applicationCode);
                return app['applicationCode'];
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstantsService.prototype, "appCodesList", {
        get: function () {
            return this._appCodes;
        },
        set: function (apps) {
            this._appCodes = apps;
        },
        enumerable: true,
        configurable: true
    });
    AppConstantsService.prototype.getAppData = function (appId) {
        return filter(this.appList, function (app) {
            return app.applicationCode == appId;
        });
    };
    return AppConstantsService;
}());
export { AppConstantsService };
