import { ViewContainerRef, ComponentRef, ComponentFactory, ComponentFactoryResolver, OnDestroy, ElementRef, NgZone, TemplateRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { cloneDeep, get, compact } from 'lodash';
import { AppStrings } from '../../assets/app-strings/app-strings';
import { AuthenticationService } from '../services/authentication.service';
import { AppConstantsService } from '../services/app-constants.service';
import { StoreService } from '../services/store.service';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../environments/environment';
import { ModalOtpComponent } from '../otp/components/modal-otp/modal-otp.component';
import { BookmarkingService } from '../services/bookmarking.service';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/takeUntil';
import { SpinnerService } from '../loading-spinner/services/spinner/spinner.service';
import { PasswordWarnComponent } from '../components/password-warn/password-warn.component';
import { PasswordStatus, PasswordExpiryService } from '../services/password-expiry.service';
import { PreLoginUserDetailsService } from '../change-password-pre-login/services/pre-login-user-details.service';
import { AnalyticsService } from '../services';
import { NgForm } from '@angular/forms';
import { SessionManagementService } from '../services/session-management.service';
import { ModalDirective } from "ngx-bootstrap/modal";
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from '../services/user/user.service';
import { OktaAuthService } from "@nda/app/services/okta-auth/okta-auth.service";
var isExternal = location.origin.indexOf('int') < 0;
var NdaLoginComponent = /** @class */ (function () {
    function NdaLoginComponent(modalService, authenticationService, storeService, router, appConstantsService, sessionManager, cookieService, oktaAuthService, bookmarkingService, activatedRoute, spinnerService, passwordService, preLoginUserDetailsService, analytics, resolver, zone, cdRef, userService) {
        var _this = this;
        this.modalService = modalService;
        this.authenticationService = authenticationService;
        this.storeService = storeService;
        this.router = router;
        this.appConstantsService = appConstantsService;
        this.sessionManager = sessionManager;
        this.cookieService = cookieService;
        this.oktaAuthService = oktaAuthService;
        this.bookmarkingService = bookmarkingService;
        this.activatedRoute = activatedRoute;
        this.spinnerService = spinnerService;
        this.passwordService = passwordService;
        this.preLoginUserDetailsService = preLoginUserDetailsService;
        this.analytics = analytics;
        this.resolver = resolver;
        this.zone = zone;
        this.cdRef = cdRef;
        this.userService = userService;
        this.AppStrings = AppStrings;
        this.downstreamAppURL = environment.downstreamAppURL;
        this.saLoginLink = isExternal ? this.downstreamAppURL.SAWE.loginPageURL : this.downstreamAppURL.SAWI.loginPageURL;
        this.allyAcademyURL = environment.allyAcademyURL;
        this.COMMON_CONSTANTS = AppStrings['common'];
        this.isSubmitClicked = false;
        this.customErrorShow = false;
        this.passOtp = false;
        this.uidPattern = "^[a-zA-Z0-9]*$";
        this.pwdPattern = "^[^&<>%=?]*$";
        this.type = "post";
        this.path = "";
        this.headers = "";
        this.errorMessage = [];
        this.isSendOtpScreen = true;
        this.isUserAuthenticated = false;
        this.MINUMUM_NUMBER_OF_GROUPS = 3;
        this.isGroupSearchComplete = false;
        this.isExternalAuth = false;
        this.downstreamAppIdentifier = null;
        this.passwordExpiredStatus = 'PASSWORD EXPIRED';
        this.spinner$ = new Subject();
        this.isPendingApproval = false;
        this.model = {
            userId: '',
            pwd: ''
        };
        this.flowMap = this.appConstantsService.otp_login_flow;
        this.activatedRoute.queryParams.subscribe(function (isPendingApproval) {
            if (_this.activatedRoute.snapshot.queryParams['isPendingApproval'] === 'true') {
                _this.isPendingApproval = true;
                _this.cdRef.detectChanges();
            }
        });
        this.spinner$.subscribe(function (isLoading) {
            _this.spinnerService[isLoading ? 'show' : 'hide']();
        });
    }
    NdaLoginComponent.prototype.ngOnDestroy = function () {
        this.spinner$.unsubscribe();
    };
    NdaLoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.passwordService.downstreamAppIdentifier = null;
            var userModel = _this.cookieService.get('ext_auth');
            var appIdentifier = _this.cookieService.get('ext_app_name');
            if (userModel === undefined || userModel === null) {
                _this.analytics.trackPage({ title: 'ADOS:Login' });
                return;
            }
            userModel = _this.preParseAuthCookie(userModel);
            _this.downstreamAppIdentifier = _this.preParseAuthCookie(appIdentifier);
            _this.userModel = JSON.parse(userModel);
            _this.isExternalAuth = true;
            _this.submitClicked();
            if (_this.userModel['resource']) {
                var loginName = _this.userModel['resource'].profile.login;
                _this.username = loginName.substr(0, loginName.indexOf('@'));
            }
            else if (get(_this.userModel, 'exceptions')) {
                _this.userModel['error'] = _this.userModel;
            }
            _this.cookieService.remove('ext_auth');
            _this.cookieService.remove('ext_app_name');
            _this.processAuthenticationResponse();
        }, 0);
        // Wait for cookies to populate after the page loads, then clear them.
        setTimeout(function () {
            _this.sessionManager.clearCookies();
        }, 3000);
    };
    NdaLoginComponent.prototype.preParseAuthCookie = function (userModel) {
        userModel = userModel.replace('"', '');
        userModel = userModel.replace(/\\/g, '');
        userModel = userModel.replace(/.$/, '');
        return userModel;
    };
    NdaLoginComponent.prototype.authenticateUser = function () {
        this.spinner$.next(true);
        this.username = this.model.userId;
        this.isUserAuthenticated = false;
        this.isExternalAuth = false;
        this.findUserDetails();
    };
    NdaLoginComponent.prototype.showOtpModal = function (content) {
        var _this = this;
        if (this.ndaModalComponentRef) {
            this.otpModalContainer.clear();
        }
        var factory = this.resolver.resolveComponentFactory(ModalOtpComponent);
        this.ndaModalComponentRef = this.otpModalContainer.createComponent(factory);
        var instance = this.ndaModalComponentRef.instance;
        instance.onHidden.subscribe(function () { return _this.ndaModalComponentRef.destroy(); });
        instance.isComplete.subscribe(function () {
            setTimeout(function () {
                _this.redirectPath = _this.bookmarkingService.getBookmarkedUrl();
                _this.bookmarkingService.resetBookmarkedUrl();
                if (_this.activatedRoute.snapshot.queryParams['isPendingApproval'] === 'true') {
                    _this.isPendingApproval = true;
                }
                if (_this.spinnerService.isVisible)
                    _this.spinnerService.hide();
                if (!window.location.href.includes('/change-password')) {
                    _this.zone.run(function () {
                        _this.sessionManager.authorize().then(function (res) {
                            _this.oktaAuthService.ApigeeRedirect();
                        }).catch(function (error) {
                            if (!!_this.sessionManager.profile) {
                                _this.sessionManager.deleteSessionOktaSesion().subscribe(function () {
                                    _this.redirectToLogin({ isPendingApproval: true });
                                });
                            }
                        });
                    });
                }
            }, 3000);
        });
        instance.downstreamAppIdentifier = this.downstreamAppIdentifier;
        this.cdRef.detectChanges();
        instance.show(content, this.flowMap);
    };
    NdaLoginComponent.prototype.checkQueryParams = function () {
        //check isPendingApproval flag
        if (this.activatedRoute.snapshot.queryParams['isPendingApproval'] === 'true') {
            this.isPendingApproval = true;
        }
    };
    NdaLoginComponent.prototype.responseProcess = function () {
        var _this = this;
        var model = this.userModel['resource'];
        var modalData = {
            userModel: this.userModel,
            credentials: this.model
        };
        this.storeService.write(this.appConstantsService.isActive, this.appConstantsService.yes);
        this.storeService.write("pdn", model.primaryDealer);
        // Storing App identifier in service for Password Expire and Password warn downstream navigation
        if (this.isUserLoggedInFromDownStream) {
            this.passwordService.downstreamAppIdentifier = this.downstreamAppIdentifier;
        }
        // MFA_REQUIRED check - To show OTP Dialog
        if (model.status && model.status === this.appConstantsService.auth_Required) {
            this.isSendOtpScreen = true;
            this.spinner$.next(false);
            this.showOtpModal(modalData);
        } // EXPIRED PASSWORD check - to decide password expired flow
        else if (model.status && model.status === PasswordStatus.PasswordExpired) {
            var userProfile = cloneDeep(model.profile);
            this.spinner$.next(false);
            userProfile.userId = model.sid;
            this.preLoginUserDetailsService.profile = userProfile;
            this.zone.run(function () { _this.router.navigate([_this.appConstantsService.pre_login_change_password_path]); });
        }
        else {
            this.sessionManager.update('sessionToken', model.sessionToken);
            this.sessionManager.update("profile", model.profile);
            // Session - Id used to maintain user session
            this.sessionManager.update('sessionId', model.sessionId);
            // Storing status and password changed date if present to show ABOUT TO EXPIRE modal in case of PASSWORD_WARN status
            this.passwordService.status = model['status'];
            this.passwordService.passwordExpiryDate = get(model, 'passwordExpiredDate');
            // Condition to divide downstream application flow and NDA flow
            if (this.isUserLoggedInFromDownStream) {
                this.spinner$.next(false);
                setTimeout(function () {
                    if (_this.activatedRoute.snapshot.queryParams['isPendingApproval'] === 'true') {
                        _this.isPendingApproval = true;
                    }
                    if (_this.spinnerService.isVisible)
                        _this.spinnerService.hide();
                    _this.cdRef.detectChanges();
                    if (model.status && model.status != PasswordStatus.PasswordWarn) {
                        var sessionToken = _this.sessionManager.sessionToken;
                        window.location.assign(environment.downstreamAppURL[_this.downstreamAppIdentifier]['landingPageURL'] + sessionToken);
                    }
                }, 1250);
            }
            else {
                setTimeout(function () {
                    if (_this.activatedRoute.snapshot.queryParams['isPendingApproval'] === 'true') {
                        _this.isPendingApproval = true;
                    }
                    if (_this.spinnerService.isVisible)
                        _this.spinnerService.hide();
                    _this.cdRef.detectChanges();
                }, 1250);
                this.redirectPath = this.bookmarkingService.getBookmarkedUrl();
                this.bookmarkingService.resetBookmarkedUrl();
                this.sessionManager.authorize().then(function (res) {
                    if (!_this.sessionManager.profile.allyRole
                        && _this.sessionManager.profile.userType === 'B') {
                        // this.redirectPath ='/register/ally';
                        _this.router.navigate(['/register/ally']);
                        return;
                    }
                    _this.oktaAuthService.ApigeeRedirect();
                }).catch(function (error) {
                    var IS_NDA = !_this.cookieService.get('ext_app_name');
                    var NEEDS_APPROVAL = !_this.sessionManager.hasBirthright;
                    if (IS_NDA && NEEDS_APPROVAL && !!_this.sessionManager.profile) {
                        _this.sessionManager.deleteSessionOktaSesion().subscribe(function () {
                            _this.redirectToLogin({ isPendingApproval: true });
                        });
                    }
                });
            }
        }
    };
    NdaLoginComponent.prototype.redirectToLogin = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        this.zone.run(function () {
            _this.router.navigate(['/login'], { queryParams: params });
        });
    };
    NdaLoginComponent.prototype.hideForgotUsernameModal = function () {
        this.modalRef.hide();
    };
    NdaLoginComponent.prototype.showForgotUsernameModal = function (template) {
        this.modalRef = this.modalService.show(template);
        this.modalRef.setClass('login-modal-sm');
        var node = document.getElementsByClassName("modal-title")[0];
        node.focus({ preventScrolling: false });
    };
    NdaLoginComponent.prototype.submitClicked = function () {
        this.isSubmitClicked = true;
        this.customErrorShow = true;
        this.checkQueryParams();
        this.trackEndPoint({ errors: this.preAuthFormErrors });
    };
    NdaLoginComponent.prototype.credentialsChanged = function () {
        this.isUserAuthenticated = false;
        this.isGroupSearchComplete = false;
    };
    NdaLoginComponent.prototype.loginFormErrorMessage = function () {
        if (this.preAuthFormErrors.length != 0) {
            return this.preAuthFormErrors[0];
        }
        else if (this.errorMessage.length != 0) {
            return this.errorMessage[0];
        }
        else {
            return "";
        }
    };
    NdaLoginComponent.prototype._restrictUserid = function (event) {
        this.customErrorShow = false;
        var uidPatternForKeystroke = /^[a-zA-Z0-9]*$/;
        var inputChar = String.fromCharCode(event.charCode);
        if ([0, 8].indexOf(event.charCode) !== -1)
            return;
        if (!uidPatternForKeystroke.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    };
    Object.defineProperty(NdaLoginComponent.prototype, "baseUrl", {
        get: function () {
            var base = document.querySelector('base');
            return (base && base.getAttribute('href') || '').replace(/\/$/, '');
        },
        enumerable: true,
        configurable: true
    });
    NdaLoginComponent.prototype.findUserDetails = function () {
        var _this = this;
        this.errorMessage = [];
        this.authenticationService.getUserDetails(this.model.userId, this.model.pwd)
            .finally(function () {
            _this.spinner$.next(false);
            if (_this.userModel['error'] && _this.userModel['error']['exceptions'] === undefined) {
                window.location.href = _this.baseUrl + "/error";
            }
            else {
                _this.spinner$.next(false);
                _this.processAuthenticationResponse();
            }
        })
            .subscribe(function (model) { _this.userModel = model; }, function (err) { return _this.userModel = err; });
    };
    NdaLoginComponent.prototype.processAuthenticationResponse = function () {
        this.processUserModelErrors();
        if (this.errorMessage.length === 0) {
            this.isUserAuthenticated = true;
            if (this.userModel['resource']) {
                this.responseProcess();
            }
            else {
                this.spinner$.next(false);
            }
        }
        else {
            this.spinner$.next(false);
            this.trackEndPoint({ errors: this.errorMessage });
        }
    };
    NdaLoginComponent.prototype.transformErrorMessages = function () {
        if (this.isPasswordExpiredOrWarn(this.userModel['error']['exceptions'].message)) {
            this.errorMessage = [];
            this.errorMessage.push(AppStrings['authErrors']['ERR_000002']);
        }
    };
    NdaLoginComponent.prototype.transformDownStreamErrorMessages = function () {
        if (this.isUserLoggedInFromDownStream && this.userModel['error'] && this.userModel['error']['exceptions'] && (this.isPasswordExpiredOrWarn(this.userModel['error']['exceptions'].message) || this.isInvalidPasswordOrUserId(this.userModel['error']['exceptions'].code))) {
            return window.location.assign(environment.downstreamAppURL[this.downstreamAppIdentifier]['invalidLoginURL']);
        }
    };
    NdaLoginComponent.prototype.isPasswordExpiredOrWarn = function (message) {
        return message && (message.toUpperCase() === this.passwordExpiredStatus.toUpperCase() || message.toUpperCase() === PasswordStatus.PasswordWarn);
    };
    NdaLoginComponent.prototype.isInvalidPasswordOrUserId = function (code) {
        return code && code === "ERR_000002";
    };
    NdaLoginComponent.prototype.processUserModelErrors = function () {
        this.transformDownStreamErrorMessages();
        if (this.userModel['error']) {
            if (this.userModel['error']['exceptions'] && this.isExternalAuth === false) {
                this.errorMessage = [];
                var message = AppStrings['authErrors'][this.userModel['error']['exceptions'].code];
                message && this.errorMessage.push(message);
                this.transformErrorMessages();
            }
            else if (this.userModel['error']['exceptions'] && AppStrings['authErrors'][this.userModel['error']['exceptions'].code]) {
                this.errorMessage.push(AppStrings['authErrors'][this.userModel['error']['exceptions'].code]);
            }
        }
    };
    NdaLoginComponent.prototype.trackEndPoint = function (_a) {
        var errors = _a.errors;
        if (errors.length > 0) {
            this.analytics.trackEndPoint({ errors: errors });
        }
    };
    Object.defineProperty(NdaLoginComponent.prototype, "isUserLoggedInFromDownStream", {
        get: function () {
            return this.downstreamAppIdentifier && this.downstreamAppIdentifier !== this.appConstantsService.storefrontAppIdentifier;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaLoginComponent.prototype, "controls", {
        get: function () {
            return this.loginForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaLoginComponent.prototype, "isErrorUidRequired1", {
        get: function () {
            return get(this.controls, 'password.valid') && get(this.controls, 'user.errors.required') && this.isSubmitClicked && !this.isExternalAuth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaLoginComponent.prototype, "isErrorUidRequired2", {
        get: function () {
            return get(this.controls, 'user.errors.pattern') && !this.isExternalAuth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaLoginComponent.prototype, "isErrorPasswordRequired", {
        get: function () {
            return get(this.controls, 'user.valid') && get(this.controls, 'password.errors.required') && this.isSubmitClicked && !this.isExternalAuth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaLoginComponent.prototype, "isPasswordMinimumLength", {
        get: function () {
            return get(this.controls, 'password.errors.minlength') && this.isSubmitClicked && !this.isExternalAuth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaLoginComponent.prototype, "isErrorBothRequired", {
        get: function () {
            return get(this.controls, 'user.errors.required') && get(this.controls, 'password.errors.required') && this.isSubmitClicked && !this.isExternalAuth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaLoginComponent.prototype, "isPasswordPattern", {
        get: function () {
            return get(this.controls, 'password.errors.pattern') && !this.isExternalAuth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaLoginComponent.prototype, "preAuthFormErrors", {
        get: function () {
            if (this.loginForm.form.valid && !this.errorMessage.length && !this.isPendingApproval) {
                return [];
            }
            if (this.activatedRoute.snapshot.queryParams['isPendingApproval'] === 'true') {
                this.isPendingApproval = true;
            }
            if (this.spinnerService.isVisible) {
                this.spinner$.next(false);
            }
            return compact([
                this.isErrorUidRequired1 ? get(AppStrings, 'errors.errUidRequired') : '',
                this.isErrorUidRequired2 ? get(AppStrings, 'errors.errUidPattern') : '',
                this.isErrorPasswordRequired ? get(AppStrings, 'errors.errPwdRequired') : '',
                this.isPasswordMinimumLength ? get(AppStrings, 'errors.pwdMinlength') : '',
                this.isErrorBothRequired ? get(AppStrings, 'errors.errBothRequired') : '',
                this.isPendingApproval ? get(AppStrings, 'login.errors.pendingApprovalError') : '',
                this.isPasswordPattern ? get(AppStrings, 'errors.inValidPwdChars') : ''
            ]);
        },
        enumerable: true,
        configurable: true
    });
    return NdaLoginComponent;
}());
export { NdaLoginComponent };
