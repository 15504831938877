<router-outlet></router-outlet>

<nda-loading-spinner></nda-loading-spinner>

<div id="24CF4C7D395" class="form-group ie-checkbox">
    <div class="control-wrapper">
        <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="24F545731EED">
            <label class="custom-control-label" for="24F545731EED"></label>
        </div>
    </div>
</div>