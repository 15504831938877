import { Component, ViewChild, Input } from '@angular/core';
import {  Router } from '@angular/router';
import { ContactsFlyoutComponent } from '../../contacts/components/contacts-flyout/contacts-flyout.component';
import { SessionManagementService } from '../../services/session-management.service';
import { OktaAuthService } from '../../services/okta-auth/okta-auth.service';

@Component({
  selector: 'nda-header',
  templateUrl: './nda-header.component.html',
  styleUrls: ['./nda-header.component.scss']
})
export class NdaHeaderComponent {

  @Input('isNavEnabled') isNavEnabled: boolean;

  @ViewChild(ContactsFlyoutComponent, { static: true }) private flyout: ContactsFlyoutComponent;

  isFlyoutShowing: boolean = false;

  constructor(
    private sessionManager: SessionManagementService,
    private oktaAuthService: OktaAuthService,
    private router: Router
  ) { }

  get isLoggedIn(): boolean {
    return this.sessionManager.hasSession;
  }

  get shouldDisplayNav(): boolean {
    return this.isNavEnabled != false && this.isLoggedIn;
  }

  dashboardRefresh(): void {
    if (this.isLoggedIn) {
      // this.sessionManager.refreshSession().subscribe();
      this.oktaAuthService.getApigeeToken().then(() => {
        this.router.navigate(['/dashboard']);
      },()=>{this.router.navigate(['/dashboard']);})
    }
  }

  displayFlyout(isVisible: boolean): void {
    this.isFlyoutShowing = isVisible;
    this.flyout.show(isVisible);
  }
}
