/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nda-no-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./nda-no-page.component";
import * as i3 from "../../services/analytics/analytics.service";
var styles_NdaNoPageComponent = [i0.styles];
var RenderType_NdaNoPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NdaNoPageComponent, data: {} });
export { RenderType_NdaNoPageComponent as RenderType_NdaNoPageComponent };
export function View_NdaNoPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "h4 text-ally"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "allycon-error-fill text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "h2", [["style", "color:rgb(206, 0, 0);"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_NdaNoPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nda-no-page", [], null, null, null, View_NdaNoPageComponent_0, RenderType_NdaNoPageComponent)), i1.ɵdid(1, 114688, null, 0, i2.NdaNoPageComponent, [i3.AnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NdaNoPageComponentNgFactory = i1.ɵccf("nda-no-page", i2.NdaNoPageComponent, View_NdaNoPageComponent_Host_0, {}, {}, []);
export { NdaNoPageComponentNgFactory as NdaNoPageComponentNgFactory };
