import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { AppConstantsService } from '../../../services/app-constants.service';
import { PasswordExpiryService } from '../../../services/password-expiry.service';
import { environment } from '../../../../environments/environment';
var ModalPasswordChangedComponent = /** @class */ (function () {
    function ModalPasswordChangedComponent(router, appConstantsService, passwordExpiryService) {
        this.router = router;
        this.appConstantsService = appConstantsService;
        this.passwordExpiryService = passwordExpiryService;
        this.CONSTANTS = AppStrings['confirmPassword'];
    }
    ModalPasswordChangedComponent.prototype.show = function () {
        this.passwordChanged.show();
    };
    ModalPasswordChangedComponent.prototype.hide = function () {
        this.passwordChanged.hide();
        if (this.isDownStreamFlow) {
            return window.location.assign(environment.downstreamAppURL[this.passwordExpiryService.downstreamAppIdentifier]['loginPageURL']);
        }
        this.router.navigate([this.appConstantsService.login_path]);
    };
    Object.defineProperty(ModalPasswordChangedComponent.prototype, "isDownStreamFlow", {
        get: function () {
            return !!this.passwordExpiryService.downstreamAppIdentifier;
        },
        enumerable: true,
        configurable: true
    });
    return ModalPasswordChangedComponent;
}());
export { ModalPasswordChangedComponent };
