/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nda-operation-hours.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./nda-operation-hours.component";
var styles_NdaOperationHoursComponent = [i0.styles];
var RenderType_NdaOperationHoursComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NdaOperationHoursComponent, data: {} });
export { RenderType_NdaOperationHoursComponent as RenderType_NdaOperationHoursComponent };
export function View_NdaOperationHoursComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "contactWidget"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "normalText"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 10, "table", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.CONSTANTS.contactUs; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.CONSTANTS.helpDesk; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.CONSTANTS.helpDeskPhone; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.CONSTANTS.weekdays; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.CONSTANTS.weekdayHours; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.CONSTANTS.saturday; _ck(_v, 15, 0, currVal_5); var currVal_6 = _co.CONSTANTS.saturdayHours; _ck(_v, 17, 0, currVal_6); }); }
export function View_NdaOperationHoursComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nda-operation-hours", [], null, null, null, View_NdaOperationHoursComponent_0, RenderType_NdaOperationHoursComponent)), i1.ɵdid(1, 114688, null, 0, i2.NdaOperationHoursComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NdaOperationHoursComponentNgFactory = i1.ɵccf("nda-operation-hours", i2.NdaOperationHoursComponent, View_NdaOperationHoursComponent_Host_0, {}, {}, []);
export { NdaOperationHoursComponentNgFactory as NdaOperationHoursComponentNgFactory };
