import { OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AppStrings } from '../../../assets/app-strings/app-strings';
import { AppConstantsService } from '../../services/app-constants.service';
import { PasswordExpiryService } from '../../services/password-expiry.service';
import { environment } from '../../../environments/environment';
import { SessionManagementService } from '../../services/session-management.service';
var PasswordWarnComponent = /** @class */ (function () {
    function PasswordWarnComponent(router, appConstantsService, passwordExpiryService, sessionManager) {
        this.router = router;
        this.appConstantsService = appConstantsService;
        this.passwordExpiryService = passwordExpiryService;
        this.sessionManager = sessionManager;
        this.CONSTANTS = AppStrings['passwordExpiry'];
        this.dateFormat = "MM/dd/yy";
    }
    PasswordWarnComponent.prototype.ngOnInit = function () {
    };
    PasswordWarnComponent.prototype.show = function (passwordChangedDate) {
        this.getDate = this.convertObjectToDate(passwordChangedDate);
        this.passwordWarn.show();
    };
    PasswordWarnComponent.prototype.hide = function (isOnlyHide) {
        // If Smart Auction Flow then navigate them to SmartAuction landing page
        if (this.isDownStreamFlow && !isOnlyHide) {
            var sessionToken = this.sessionManager.sessionToken;
            window.location.assign(environment.downstreamAppURL[this.passwordExpiryService.downstreamAppIdentifier]['landingPageURL'] + sessionToken);
        }
        else {
            this.passwordWarn.hide();
        }
    };
    PasswordWarnComponent.prototype.changePassword = function () {
        this.hide(true);
        this.router.navigate([this.appConstantsService.post_login_change_password]);
    };
    PasswordWarnComponent.prototype.convertObjectToDate = function (pwdExpiryDate) {
        return new Date(pwdExpiryDate.year, pwdExpiryDate.monthValue - 1, pwdExpiryDate.dayOfMonth);
    };
    Object.defineProperty(PasswordWarnComponent.prototype, "isDownStreamFlow", {
        get: function () {
            return !!this.passwordExpiryService.downstreamAppIdentifier;
        },
        enumerable: true,
        configurable: true
    });
    return PasswordWarnComponent;
}());
export { PasswordWarnComponent };
