import { Component, Input } from '@angular/core';

import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { DynamicContact } from '../../models/dynamic-contact';

@Component({
  selector: 'app-contact-detail-flyout',
  templateUrl: './contact-detail-flyout.component.html',
  styleUrls: ['./contact-detail-flyout.component.scss']
})
export class ContactDetailFlyoutComponent {

  readonly CONSTANTS = {
    ...AppStrings['myContacts']['flyoutRoles'],
    'classifications': AppStrings['myContacts']['classifications'],
    'roles': AppStrings['myContacts']['roles']
  };

  @Input() public contact: DynamicContact;

  constructor() { }

}
