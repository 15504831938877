import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { AppStrings } from '../../../assets/app-strings/app-strings';
import { Router } from '@angular/router';
import { SessionManagementService } from '../../services/session-management.service';
var NdaNavComponent = /** @class */ (function () {
    function NdaNavComponent(router, sessionManager) {
        this.router = router;
        this.sessionManager = sessionManager;
        this.CONSTANTS = tslib_1.__assign({}, AppStrings['common'], AppStrings['header']);
        this.showFlyout = new EventEmitter();
    }
    NdaNavComponent.prototype.ngOnInit = function () { };
    NdaNavComponent.prototype.contacts = function () {
        this.isFlyoutShowing = !this.isFlyoutShowing;
        if (this.sessionManager.isBranchUser) {
            this.router.navigate(['/contacts']);
        }
        else {
            this.showFlyout.emit(this.isFlyoutShowing);
        }
    };
    NdaNavComponent.prototype.logout = function () {
        this.router.navigate(['logout']);
    };
    Object.defineProperty(NdaNavComponent.prototype, "isApprovedUser", {
        get: function () {
            return this.sessionManager.isBranchUser || this.sessionManager.hasBirthright;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaNavComponent.prototype, "shouldDisplayChat", {
        get: function () {
            return this.sessionManager.hasSession && !!this.sessionManager.profile;
        },
        enumerable: true,
        configurable: true
    });
    return NdaNavComponent;
}());
export { NdaNavComponent };
